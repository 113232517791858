import { atom } from "recoil";
import { defaultTenantType, TenantType } from "../types/TenantType";

export const GlobalLoadingState = atom<{
  isLoading: boolean;
  message: string;
}>({
  key: "GlobalLoadingState",
  default: {
    isLoading: false,
    message: "",
  },
});

export const AddFactoryModalState = atom<{
  show: boolean;
}>({
  key: "AddFactoryModalState",
  default: {
    show: false,
  },
});

export const GoogleSheetMappingModalState = atom<{
  show: boolean;
  factory_id: number;
  factory_name: string;
  factory_url: string;
  year: number;
  month: number;
}>({
  key: "GoogleSheetMappingModalState",
  default: {
    show: false,
    factory_id: 0,
    factory_name: "",
    factory_url: "",
    year: 0,
    month: 0,
  },
});

export const ModifyChungGuModalState = atom<{
  show: boolean;
  factory_id: number;
  factory_name: string;
  lookup_month: number;
  lookup_year: number;
  total_fee: number | undefined;
  rental_fee: number | undefined;
  management_fee: number | undefined;
  report_url: string;
  request_url: string;
}>({
  key: "ModifyChungGuModalState",
  default: {
    show: false,
    factory_id: 0,
    factory_name: "",
    lookup_month: 0,
    lookup_year: 0,
    total_fee: undefined,
    rental_fee: undefined,
    management_fee: undefined,
    report_url: "",
    request_url: "",
  },
});

export const AddNewTenantModalState = atom<{
  show: boolean;
  factory_id: number;
  lookup_month: number;
  lookup_year: number;
  tenant_name: string;
  tenant_company_name: string;
  tenant_phone_number: string;
}>({
  key: "AddNewTenantModalState",
  default: {
    show: false,
    factory_id: 0,
    lookup_year: 0,
    lookup_month: 0,
    tenant_name: "",
    tenant_company_name: "",
    tenant_phone_number: "",
  },
});

export const ModifyTenantModalState = atom<{
  show: boolean;
  factory_id: number;
  lookup_month: number;
  lookup_year: number;
  tenant_name: string;
  tenant_company_name: string;
  tenant_phone_number: string;
  is_overdue: boolean;
  requested_total_fee: number;
  requested_rental_fee: number;
  requested_manage_fee: number;
  paid_total_fee: number;
  paid_rental_fee: number;
  paid_manage_fee: number;
  rental_report_url: string;
  contract_paper_url: string;
  account_info: string;
  requested_date: string;
  deadline_date: string;
}>({
  key: "ModifyTenantModalState",
  default: {
    show: false,
    factory_id: 0,
    lookup_year: 0,
    lookup_month: 0,
    tenant_name: "",
    tenant_company_name: "",
    tenant_phone_number: "",
    is_overdue: false,
    requested_total_fee: 0,
    requested_rental_fee: 0,
    requested_manage_fee: 0,
    paid_total_fee: 0,
    paid_rental_fee: 0,
    paid_manage_fee: 0,
    rental_report_url: "",
    contract_paper_url: "",
    account_info: "",
    requested_date: "",
    deadline_date: "",
  },
});

export const TenantContractModalState = atom<{
  show: boolean;
  tenant_info?: TenantType;
  onPress: Function;
}>({
  key: "TenantContractModalState",
  default: {
    show: false,
    tenant_info: defaultTenantType,
    onPress: () => {},
  },
});

export const ConsultingRequestModalState = atom<{
  show: boolean;
  onPress: Function;
}>({
  key: "ConsultingRequestModalState",
  default: {
    show: false,
    onPress: () => {},
  },
});

export const ElecInfoSettingModalState = atom<{
  show: boolean;
  phone_number: string;
  elec_register_number: string;
  customer_type: string;
  birthday_number: string;
  company_register_number_head: string;
  company_register_number_tail: string;
  is_phone_number_editable: boolean;
  agreement: boolean;
  from_customer: boolean;
}>({
  key: "ElecInfoSettingModalState",
  default: {
    show: false,
    phone_number: "",
    elec_register_number: "",
    customer_type: "개인사업자",
    company_register_number_head: "",
    company_register_number_tail: "",
    birthday_number: "",
    is_phone_number_editable: false,
    agreement: false,
    from_customer: false,
  },
});

export const ModelingRequestModalState = atom<{
  show: boolean;
}>({
  key: "ModelingRequestModalState",
  default: {
    show: false,
  },
});

export const LawRequestModalState = atom<{
  show: boolean;
}>({
  key: "LawRequestModalState",
  default: {
    show: false,
  },
});

export const FacilityDetailModalState = atom<{
  show: boolean;
  factory_id: number;
  facility_type: string;
  facility_company_name: string;
  contract_type: string;
  contract_deposit: number;
  facility_manage_fee: number;
  facility_super_tax_fee: number;
  facility_total_fee: number;
  contract_start_date: string;
  contract_end_date: string;
  contract_paper_url: string;
  additional_info: string;
  year: number;
  month: number;
}>({
  key: "FacilityDetailModalState",
  default: {
    show: false,
    factory_id: 0,
    facility_type: "",
    facility_company_name: "",
    contract_type: "",
    contract_deposit: 0,
    facility_manage_fee: 0,
    facility_super_tax_fee: 0,
    facility_total_fee: 0,
    contract_start_date: "",
    contract_end_date: "",
    contract_paper_url: "",
    additional_info: "",
    month: 0,
    year: 0,
  },
});

export const ContractDetailModalState = atom<{
  show: boolean;
  factory_id: number;
  lookup_month: number;
  lookup_year: number;
  tenant_name: string;
  tenant_company_name: string;
  rental_type: string;
  rental_deposit: string;
  rental_fee_due_date: string;
  tenant_rental_fee: string;
  tenant_manage_fee: string;
  contract_start_date: string;
  contract_end_date: string;
  contract_paper_url: string;
  tenant_phone_number: string;
  agent_phone_number: string;
}>({
  key: "ContractDetailModalState",
  default: {
    show: false,
    factory_id: 0,
    lookup_month: 0,
    lookup_year: 0,
    tenant_name: "",
    tenant_company_name: "",
    rental_type: "",
    rental_deposit: "",
    rental_fee_due_date: "",
    tenant_rental_fee: "",
    tenant_manage_fee: "",
    contract_start_date: "",
    contract_end_date: "",
    contract_paper_url: "",
    tenant_phone_number: "",
    agent_phone_number: "",
  },
});

export const ModifyContractDetailModalState = atom<{
  show: boolean;
  factory_id: number;
  lookup_month: number;
  lookup_year: number;
  tenant_name: string;
  tenant_company_name: string;
  rental_type: string;
  rental_deposit: string;
  rental_fee_due_date: string;
  tenant_rental_fee: string;
  tenant_manage_fee: string;
  contract_start_date: string;
  contract_end_date: string;
  contract_paper_url: string;
  tenant_phone_number: string;
  agent_phone_number: string;
}>({
  key: "ModifyContractDetailModalState",
  default: {
    show: false,
    factory_id: 0,
    lookup_month: 0,
    lookup_year: 0,
    tenant_name: "",
    tenant_company_name: "",
    rental_type: "",
    rental_deposit: "",
    rental_fee_due_date: "",
    tenant_rental_fee: "",
    tenant_manage_fee: "",
    contract_start_date: "",
    contract_end_date: "",
    contract_paper_url: "",
    tenant_phone_number: "",
    agent_phone_number: "",
  },
});

export const ModifyFacilityModalState = atom<{
  show: boolean;
  factory_id: number;
  facility_type: string;
  facility_company_name: string;
  contract_type: string;
  contract_deposit: string;
  facility_manage_fee: string;
  facility_super_tax_fee: string;
  facility_total_fee: string;
  contract_start_date: string;
  contract_end_date: string;
  contract_paper_url: string;
  additional_info: string;
  year: number;
  month: number;
}>({
  key: "ModifyFacilityModalState",
  default: {
    show: false,
    factory_id: 0,
    facility_type: "",
    facility_company_name: "",
    contract_type: "",
    contract_deposit: "",
    facility_manage_fee: "",
    facility_super_tax_fee: "",
    facility_total_fee: "",
    contract_start_date: "",
    contract_end_date: "",
    contract_paper_url: "",
    additional_info: "",
    year: 0,
    month: 0,
  },
});

export const SendNoticeModalState = atom<{
  show: boolean;
  sendTargetName: string;
  sendTargetPhoneNumber: string;
  sendTargetType: string;
  sendFileUrl: string;
  defaultText: string;
  onPress: Function;
}>({
  key: "SendNoticeModalState",
  default: {
    show: false,
    sendTargetName: "",
    sendTargetPhoneNumber: "",
    sendTargetType: "임대인",
    sendFileUrl: "",
    defaultText: "안녕하세요, 공장 관리 사무소입니다.",
    onPress: () => {},
  },
});
export const SendTenantKakaoModalState = atom<{
  show: boolean;
  tenantCompanyName: string;
  year: string;
  month: string;
  tenantName: string;
  totalFee: string;
  rentalFee: string;
  mgmtFee: string;
  requestedDate: string;
  deadlineDate: string;
  accountInfo: string;
  url: string;
  templateCode: string;
  contentText: string;
  phoneNumber: string;
}>({
  key: "SendTenantKakaoModalState",
  default: {
    show: false,
    tenantCompanyName: "",
    year: "",
    month: "",
    tenantName: "",
    totalFee: "",
    rentalFee: "",
    mgmtFee: "",
    requestedDate: "",
    deadlineDate: "",
    accountInfo: "",
    url: "",
    templateCode: "",
    contentText: "",
    phoneNumber: "",
  },
});

export const SendKakaoWelcomeModalState = atom<{
  show: boolean;
}>({
  key: "SendKakaoWelcomeModalState",
  default: {
    show: false,
  },
});

export const SendKakaoModalState = atom<{
  show: boolean;
  sendTargetName: string;
  sendFactoryName: string;
  sendTargetPhoneNumber: string;
  sendPurposeType: string;
  sendFileUrl: string;
  year: string;
  month: string;
  templateCode: string;
  defaultText: string;
  onPress: Function;
}>({
  key: "SendKakaoModalState",
  default: {
    show: false,
    sendTargetName: "",
    sendFactoryName: "",
    sendPurposeType: "",
    sendTargetPhoneNumber: "",
    sendFileUrl: "",
    year: "",
    month: "",
    templateCode: "",
    defaultText: "",
    onPress: () => {},
  },
});

export const LoginModalState = atom<{
  show: boolean;
  onPress: Function;
}>({
  key: "LoginModalState",
  default: {
    show: false,
    onPress: () => {},
  },
});

export const LoadingModalState = atom<{
  show: boolean;
  message: string;
}>({
  key: "LoadingModalState",
  default: {
    show: false,
    message: "",
  },
});

// let monthlyReportData: Array<string> = [
//   "2023_01_01_AM09_임대차리포트_v1.pdf",
//   "2023_01_02_AM09_임대차리포트_v2.pdf",
// ];
// let monthlyTenantBillData: Array<string> = [
//   "2023_01_01_AM09_A공장_임차료청구서_v1.pdf",
//   "2023_01_02_AM09_B공장_임차료청구서_v1.pdf",
// ];
// let monthlyManageBillData: Array<string> = [
//   "2023_01_01_AM09_A공장_관리비청구서_v1.pdf",
//   "2023_01_01_AM09_B공장_관리비청구서_v1.pdf",
// ];

interface reportData {
  id: number;
  file_name: string;
  url: string;
}

export const BillModalState = atom<{
  show: boolean;
  currentMonth: number;
  monthyReportData: reportData[];
  monthyTenantBillData: reportData[];
  monthyManageBillData: reportData[];
}>({
  key: "BillModalState",
  default: {
    show: false,
    currentMonth: 1,
    monthyReportData: [],
    monthyTenantBillData: [],
    monthyManageBillData: [],
  },
});

export const SideMenuBarState = atom<{
  show: boolean;
}>({
  key: "SideMenuBarState",
  default: {
    show: false,
  },
});

export enum UserLoginStatusType {
  LOADING = "LOADING", // 계정 로그인 중 상태
  LOGGEDOUT = "LOGGEDOUT", // 계정 로그인 실패 상태
  LOGGEDIN = "LOGGEDIN", // 계정 로그인 성공 상태
}

// const localStorageEffects =
//   (key: string) =>
//   ({ setSelf, onSet }: any) => {
//     const savedValue = localStorage.getItem(key);
//     if (savedValue != null) {
//       setSelf(JSON.parse(savedValue));
//     }

//     onSet((newValue: any, _: any, isReset: boolean) => {
//       isReset
//         ? localStorage.removeItem(key)
//         : localStorage.setItem(key, JSON.stringify(newValue));
//     });
//   };

export const UserStatus = atom<{
  loginStatusType: string | null;
  loginStatusMsg: string | null;
  userEmail: string | null;
  userPhoneNumber: string | null;
  userName: string | null;
  userType: string | null;
}>({
  key: "UserStatus",
  default: {
    loginStatusType: UserLoginStatusType.LOGGEDOUT,
    loginStatusMsg: "",
    userEmail: "",
    userPhoneNumber: "",
    userName: "",
    userType: "",
  },
});

// export const UserStatus = atom<{
//   loginStatusType: UserLoginStatusType;
//   loginStatusMsg: string;
//   userEmail: string;
//   userPhoneNumber: string;
//   userName: string;
//   userType: string;
// }>({
//   key: "UserStatus",
//   default: {
//     loginStatusType: UserLoginStatusType.LOGGEDOUT,
//     loginStatusMsg: "",
//     userEmail: "",
//     userPhoneNumber: "",
//     userName: "",
//     userType: "",
//   },
//   effects: [localStorageEffects("accessToken")],
// });

export const FactoryMetaInfoModalState = atom<{
  show: boolean | null;
  factory_name: string | null;
  factory_id?: number | null;
  month?: number | null;
  year?: number | null;
  mgmt_fee_per_pyeong?: number | null;
  rental_fee_per_pyeong?: number | null;
  deposit_per_pyeong?: number | null;
  total_land_area?: number | null;
  rent_area?: number | null;
  owner_area?: number | null;
  empty_area?: number | null;
}>({
  key: "FactoryMetaInfoModalState",
  default: {
    show: false,
    factory_name: "",
    factory_id: 0,
    month: 0,
    year: 0,
    mgmt_fee_per_pyeong: 0,
    rental_fee_per_pyeong: 0,
    deposit_per_pyeong: 0,
    total_land_area: 0,
    rent_area: 0,
    owner_area: 0,
    empty_area: 0,
  },
});

export const BillDetailModalState = atom<{
  show: boolean | null;
  tenant_name: string | null;
  year: number | null;
  month: number | null;
  rental_fee: number | null;
  total_elec_fee: number | null;
  fire_safety_fee: number | null;
  elec_safety_fee: number | null;
  manage_fee: number | null;
  base_elec_fee: number | null;
  used_elec_fee: number | null;
  elec_used_amount: number | null;
  elec_prev_month_used_amount: number | null;
  elec_fund: number | null;
  water_sewage_fee: number | null;
  unpaid_fee: number | null;
  late_fee: number | null;
  rental_book_id: number | null;
}>({
  key: "BillDetailModalState",
  default: {
    show: false,
    tenant_name: "",
    year: 0,
    month: 0,
    rental_fee: 0,
    total_elec_fee: 0,
    fire_safety_fee: 0,
    elec_safety_fee: 0,
    manage_fee: 0,
    base_elec_fee: 0,
    used_elec_fee: 0,
    elec_used_amount: 0,
    elec_prev_month_used_amount: 0,
    elec_fund: 0,
    water_sewage_fee: 0,
    unpaid_fee: 0,
    late_fee: 0,
    rental_book_id: 0,
  },
});
