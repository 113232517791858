import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { factoryColor } from "../../constants/Colors";
import thunderIcon from "../../asset/thunder.png";
import climbingIcon from "../../asset/climbing-human.png";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { myFont } from "../../constants/Fonts";
import { CommonType } from "../../constants/Styles";

export const Apeal = () => {
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width:501px) and (max-width: 1023px)",
  });

  const isUnderTablit = useMediaQuery({
    query: "(max-width: 1440px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width:500px)",
  });

  return (
    <MainCtnr>
      <AlignCtnr
        row={isUnderTablit ? false : true}
        width={isUnderTablit ? "100%" : "1440px"}
        height={isUnderTablit ? "auto" : "100%"}
      >
        <CustomCtnr
          row={false}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          etc={"padding-top: 40px;"}
          tablitEtc={"padding-top: 30px;"}
          mobileEtc={"align-self:center;padding-top: 0px; width: 90vw;"}
        >
          <CategoryCtnr>
            <Icon src={thunderIcon}></Icon>
            고객고민
          </CategoryCtnr>
          <Title>
            이제 불편한 공장 관리와
            {isMobile || isTablet ? <br /> : " "}
            이별할 수 있어요
          </Title>
          <SubTitle>
            표준화된 공장 관리 서비스를
            {isMobile || isTablet ? <br /> : " "}
            경험해보세요!
          </SubTitle>
        </CustomCtnr>
        <CustomImg
          src={climbingIcon}
          width={isUnderTablit ? "400px" : "550px"}
          height={isUnderTablit ? "100%" : "100%"}
          tablitEtc="margin-top: -60px;"
        />
      </AlignCtnr>
    </MainCtnr>
  );
};

export const Icon = styled.img`
  width: 20px;
  margin-right: 15px;
`;

const CategoryCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 20px;
  font-weight: 700;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
`;

const MainCtnr = styled.div`
  box-sizing: border-box;
  height: auto;
  background-color: #f2f4f6;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${Pixel.mobileWidth}) {
    padding-top: 40px;
    flex-direction: column;
  }
`;

interface rowOption {
  row?: boolean;
  width?: string;
  height?: string;
}

const AlignCtnr = styled.div<rowOption>`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  box-sizing: border-box;
  justify-content: space-between;

  width: ${({ width }) => (width ? width : "100%")};
  padding-left: ${Pixel.headerPadding};
  height: ${({ height }) => (height ? height : "100%")};

  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 100%;
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-left: ${Percent.mobileHeaderPadding};
  }
`;

const Title = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 5px;
  color: ${factoryColor.textPrimary};
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 7vw;
  }
`;

const SubTitle = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  color: ${factoryColor.mainGreen};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  @media (max-width: ${Pixel.mobileWidth}) {
    margin-top: 15px;
    font-size: 7vw;
  }
`;

interface ImgProp {
  width?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  desktopEtc?: string;
  tablitEtc?: string;
  mobileEtc?: string;
}

export const CustomImg = styled.img<ImgProp>`
  display: flex;
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  margin-left: -120px;

  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};

  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "")};

  @media (max-width: ${Pixel.desktopWidth}) {
    margin-left: 0px;
    ${({ tablitEtc }) => (tablitEtc ? tablitEtc : "")}
    align-self: flex-end;
    padding-right: 0px;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    margin-left: 0px;
    object-position: 40px center;
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }

`;

export interface flexOption {
  row?: boolean;
  justifyContent?: string;
  alignItems?: string;
  width?: string;
  etc?: string;
  tablitEtc?: string;
  mobileEtc?: string;
}

export const CustomCtnr = styled.div<flexOption>`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};

  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "")};
  flex-wrap: wrap;
  ${({ etc }) => (etc ? etc : "")}

  @media (max-width: ${Pixel.desktopWidth}) {
    ${({ tablitEtc }) => (tablitEtc ? tablitEtc : "")}
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }
`;

const RightAttachCtnr = styled.div`
  position: relative;
  width: 100vw;
  left: 0px;
  border: 1px solid blue;
`;
