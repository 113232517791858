import { atom } from "recoil";
import { factoryData } from "../screen/ManagerScreen";

export const ManagerTabState = atom<{
  currentTab: number;
}>({
  key: "ManagerTabState",
  default: {
    currentTab: 0,
  },
});

export const CurrenlyManagingFactories = atom<{
  myFactories: factoryData[];
}>({
  key: "CurrentlyManagingFactories",
  default: {
    myFactories: [],
  },
});

export const NotMyFactories = atom<{
  notMyFactories: factoryData[];
}>({
  key: "NotMyFactories",
  default: {
    notMyFactories: [],
  },
});

