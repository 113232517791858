import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  AddFactoryModalState,
  GlobalLoadingState,
  ModifyChungGuModalState,
  FactoryMetaInfoModalState,
  BillDetailModalState,
} from "../../store/ModalStores";
import { alignItemType, CommonType, JustifyType } from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import closeIcon from "../../asset/close-circle.png";

export type NoticeModalProps = {};

export const TenantBillDetailModal = () => {
  const [thisModal, setThisModal] = useRecoilState(BillDetailModalState);
  const resetThisModal = useResetRecoilState(BillDetailModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const [rentalBookID, setRentalBookID] = useState(thisModal.rental_book_id);
  const [tN, setTN] = useState(thisModal.tenant_name);
  const [year, setYear] = useState(thisModal.year);
  const [month, setMonth] = useState(thisModal.month);
  const [rF, setRF] = useState(thisModal.rental_fee);
  const [tEF, setTEF] = useState(thisModal.total_elec_fee);
  const [fSF, setFSF] = useState(thisModal.fire_safety_fee);
  const [eSF, setESF] = useState(thisModal.elec_safety_fee);
  const [mF, setMF] = useState(thisModal.manage_fee);
  const [bEF, setBEF] = useState(thisModal.base_elec_fee);
  const [uEF, setUEF] = useState(thisModal.used_elec_fee);
  const [eUA, setEUA] = useState(thisModal.elec_used_amount);
  const [ePMUA, setEPMUA] = useState(thisModal.elec_prev_month_used_amount);
  const [eF, setEF] = useState(thisModal.elec_fund);
  const [wSF, setWSF] = useState(thisModal.water_sewage_fee);
  const [uPF, setUPF] = useState(thisModal.unpaid_fee);
  const [lF, setLF] = useState(thisModal.late_fee);

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const numberFormat = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberToKorean = (nb: number | undefined) => {
    if (nb === undefined) {
      return "";
    }
    var inputNumber: number = nb < 0 ? 0 : nb;
    var unitWords = ["", "만", "억", "조", "경"];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = "";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    return resultString;
  };

  useEffect(() => {
    console.log("this modal here")
    console.log(thisModal)
    setRentalBookID(thisModal.rental_book_id);
    setTN(thisModal.tenant_name);
    setYear(thisModal.year);
    setMonth(thisModal.month);
    setRF(thisModal.rental_fee);
    setTEF(thisModal.total_elec_fee);
    setFSF(thisModal.fire_safety_fee);
    setESF(thisModal.elec_safety_fee);
    setMF(thisModal.manage_fee);
    setBEF(thisModal.base_elec_fee);
    setUEF(thisModal.used_elec_fee);
    setEUA(thisModal.elec_used_amount);
    setEPMUA(thisModal.elec_prev_month_used_amount);
    setEF(thisModal.elec_fund);
    setWSF(thisModal.water_sewage_fee);
    setUPF(thisModal.unpaid_fee);
    setLF(thisModal.late_fee);
  }, [thisModal]);

  const sendRequest = () => {
    if (
      thisModal.rental_book_id === null ||
      thisModal.rental_book_id === undefined ||
      thisModal.rental_book_id < 1
    ) {
      alert("해당 임대장부를 찾을 수 없습니다.");
    } else if (month === null || month === undefined || month < 0) {
      alert("유효하지 않은 월 입니다.");
    } else if (year === null || year === undefined || year < 0) {
      alert("유효하지 않은 연도 입니다.");
    } else {
      setLoadingModal({
        isLoading: true,
        message: "상세정보 업데이트중",
      });
      _client
        .postApiWithToken("/manager/rental_book_bill_detail/update", {
          rental_book_id: rentalBookID,
          rental_fee: rF,
          total_elec_fee: tEF,
          fire_safety_fee: fSF,
          elec_safety_fee: eSF,
          manage_fee: mF,
          base_elec_fee: bEF,
          used_elec_fee: uEF,
          elec_used_amount: eUA,
          elec_prev_month_used_amount: ePMUA,
          elec_fund: eF,
          water_sewage_fee: wSF,
          unpaid_fee: uPF,
          late_fee: lF,
        })
        .then((response) => {
          resetLoadingModal();
          alert(response.msg);
          resetThisModal();
        })
        .catch((err) => {
          resetLoadingModal();
          alert(err);
        });
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ItemCtnr>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  [{tN} {year}년 {month}월 장부 상세 수정]<br />
                  (장부 고유번호: {rentalBookID})
                </div>
                <ItemTitle>
                  임대료 합계(입력값: {numberToKorean(Number(rF) || 0)} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(rF).toString()}
                    onChange={(e) => setRF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  전기요금 합계 (입력값: {numberToKorean(Number(tEF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(tEF).toString()}
                    onChange={(e) => setTEF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  전기 기본요금 (입력값: {numberToKorean(Number(bEF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(bEF).toString()}
                    onChange={(e) => setBEF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  전기 사용량요금 (입력값: {numberToKorean(Number(uEF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(uEF).toString()}
                    onChange={(e) => setUEF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>당월 사용량 (kW)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(eUA).toString()}
                    onChange={(e) => setEUA(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>전월 검침값</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(ePMUA).toString()}
                    onChange={(e) => setEPMUA(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  소방안전 (입력값: {numberToKorean(Number(fSF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(fSF).toString()}
                    onChange={(e) => setFSF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  전기안전 (입력값: {numberToKorean(Number(eSF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(eSF).toString()}
                    onChange={(e) => setESF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  일반관리 (입력값: {numberToKorean(Number(mF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(mF).toString()}
                    onChange={(e) => setMF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  전력기금 (입력값: {numberToKorean(Number(eF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(eF).toString()}
                    onChange={(e) => setEF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  상하수도 (입력값: {numberToKorean(Number(wSF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(wSF).toString()}
                    onChange={(e) => setWSF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  연체료 (입력값: {numberToKorean(Number(lF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(lF).toString()}
                    onChange={(e) => setLF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  미납료 (입력값: {numberToKorean(Number(uPF))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(uPF).toString()}
                    onChange={(e) => setUPF(Number(e.target.value) || 0)}
                    disabled={false}
                  />
                </ItemInputCtnr>
              </ItemCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => sendRequest()}>
                  수정 반영하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "300px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
  height: 90vh;
  overflow-y: auto;
`;

const ItemCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const ItemTitle = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  margin-top: 20px;
`;

const ItemInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

const MgmtInput = styled.input`
  display: flex;
  width: 75%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

const CalcDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 30%;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;
