import styled from "styled-components";
import { factoryColor } from "../constants/Colors";
import leftArrow from "../asset/left-arrow.png";
import rightArrow from "../asset/right-arrow.png";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { myFont } from "../constants/Fonts";
import { JustifyType, alignItemType, textAlign } from "../constants/Styles";
import { RentalBookSelector } from "../components/Dropdown/RentalBookDropdown";
import { RentalBookAtMonth } from "../components/RentalBook/RentalBookAtMonth";
import { _client } from "../apis/client";
import useSWR from "swr";
import { infoManageData, tenantData } from "./InfoManageTab";
import { useEffect, useState, useRef } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  GlobalLoadingState,
  TenantContractModalState,
} from "../store/ModalStores";
import useChecksum from "./UseCheckSumHook";
import { useMediaQuery } from "react-responsive";
import { RowFlex, ColFlex, FlexImg } from "../constants/Flex";
import climbingIcon from "../asset/climbing-human.png";
import { SubmitPage } from "./registerScreens/SubmitPage";

export const SubscribeInfoScreen = () => {
  const isUnderTablit = useMediaQuery({
    query: "(max-width: 1440px)",
  });

  const [companyName, setCompanyName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [agreement, setAgreement] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const sendRequest = () => {
    let regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    let expression: RegExp =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

    if (email?.length < 4) {
      alert("메일 주소를 확인해주세요");
    } else if (username?.length < 1) {
      alert("유저 이름을 확인해주세요");
    } else {
      if (expression.test(email)) {
        setLoadingModal({
          isLoading: true,
          message: "문의 사항을 전송중입니다.",
        });
        _client
          .postApi("/request/subscribe_info", {
            company_name: companyName,
            user_name: username,
            email: email,
          })
          .then((response) => {
            resetLoadingModal();
            alert(response.msg);
          })
          .catch((err) => {
            resetLoadingModal();
            alert(err);
          });
      } else {
        alert("이메일 주소 양식을 확인해주세요.");
      }
    }
  };

  return (
    <ColFlex
      justifyContent="flex-start"
      etc="position: absolute;top:0px;left:0px;width:100%;height:auto;z-index:500;background-color:white;overflow-y: auto;"
    >
      <ColFlex
        width="700px"
        etc="border-radius: 14px; box-shadow: 0px 9px 18px 0 rgba(0,0,0,0.15);background-color:#fff;margin-bottom:25px;margin-top:25px;overflow-y: auto;"
        mobileEtc="width: 90%;"
      >
        <RowFlex
          fontSize="25px"
          fontWeight="700"
          width="100%"
          backgroundColor="#354153"
          color="white"
          etc="padding-top: 10px;padding-bottom: 10px;border-radius: 14px;line-height: 1.6;text-align: center;border: 0px solid red;"
          mobileEtc="font-size: 20px;"
        >
          {" "}
          공장 관리 꿀팁 정보 <br />
          무료 구독 신청
        </RowFlex>
        <RowFlex width="100%" etc="padding-top: 20px;">
          <NormalDiv>
            공장관리 <span style={{ color: "#2EA566" }}>전문가</span>에게
            <br />
            <span style={{ color: "#2EA566" }}>최신 정보</span>
            와 소식을
            <br />
            무료로 받아보세요
            <br />
          </NormalDiv>
          <FlexImg
            src={climbingIcon}
            width={isUnderTablit ? "150px" : "250px"}
            height={isUnderTablit ? "100%" : "100%"}
          />
        </RowFlex>
        <ColFlex
          width="100%"
          backgroundColor="#EFF1F4"
          etc="border: 0px solid red;padding-left: 15px;"
          alignItems="flex-start"
        >
          <FormStatement className="companyName">
            <RedStar> 1.</RedStar> 회사명
          </FormStatement>
          <InputContainer>
            <Input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="회사명을 입력해주세요"
            />
          </InputContainer>
        </ColFlex>
        <ColFlex
          width="100%"
          backgroundColor="#EFF1F4"
          etc="border: 0px solid red;padding-left: 15px;"
          alignItems="flex-start"
        >
          <FormStatement className="userNamem">
            <RedStar> 2.</RedStar> 담당자명
          </FormStatement>
          <InputContainer>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="담당자명을 입력해주세요"
            />
          </InputContainer>
        </ColFlex>
        <ColFlex
          width="100%"
          backgroundColor="#EFF1F4"
          etc="border: 0px solid red;padding-left: 15px;"
          alignItems="flex-start"
        >
          <FormStatement className="userEmail">
            <RedStar> 3.</RedStar> 이메일
          </FormStatement>
          <InputContainer>
            <Input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="이메일을 입력해주세요"
            />
          </InputContainer>
        </ColFlex>
        <ColFlex
          width="100%"
          backgroundColor="#EFF1F4"
          etc="border: 0px solid red;padding-left: 15px;padding-top: 20px;padding-bottom: 20px;"
          alignItems="flex-start"
        >
          <TermsBox>
            <Article>
              <ArticleText>
                1. 수집 항목: 회사명, 이름, 이메일 <br />
                2. 수집 목적: 공장 관리 정보 발송 및 맞춤형 마케팅 정보 제공
                <br />
                3. 보유 기간: 구독 취소 등 마케팅 활용에 대한 동의 철회 시점까지
                <br />
                ※ 마케팅 정보 활용을 위한 개인정보 수집 및 이용에 동의하지 않은
                경우 본 정보를 제공받을 수 없게 됩니다.
                <br />
                ※ 위 수집 항목은 개인정보 제공 동의 철회 시점까지 보유하지만,
                수신 동의 및 철회에 대한 기록은 향후 1년간 별도 보관됩니다.
                <br />
              </ArticleText>
            </Article>
          </TermsBox>
        </ColFlex>
        <RowFlex width="100%" etc="padding-top: 15px;">
          <RowFlex width="100%" height="40px" etc="border: 0px solid black;" mobileEtc="font-size: 13px;">
            {/* <input
              type="checkbox"
              height="30px"
              style={{padding: "30px"}}
              checked={agreement}
              onChange={({ target: { checked } }) => setAgreement(checked)}
            />
            마케팅 활동을 위한 개인정보 수집 및 이용에 동의합니다. */}
            <CheckBox
              type="checkbox"
              checked={agreement}
              onChange={({ target: { checked } }) => setAgreement(checked)}
            />
            마케팅 활동을 위한 개인정보 수집 및 이용에 동의합니다.
          </RowFlex>
        </RowFlex>
        <RowFlex width="100%">
          <NextBtnContainer active={agreement}>
            {/* <button onClick={() => setStep(2)}>동의합니다.</button> */}
            <NextBtn
              disabled={!agreement}
              active={agreement}
              onClick={() => sendRequest()}
            >
              {" "}
              제출하기{" "}
            </NextBtn>
          </NextBtnContainer>
        </RowFlex>
      </ColFlex>
    </ColFlex>
  );
};

export const ArrowIcon = styled.img`
  width: 2.5%;
`;

const HorizontalPaddingCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlack};
  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const NormalDiv = styled.div`
  font-family: "Pretandard-Regular";
  color: ${factoryColor.textPrimary};
  box-sizing: border-box;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.6;
  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 20px;
  }
`;

const SubInfo = styled.h1`
  font-size: 12px;
  padding-top: 10px;
  padding-left: 5px;
  font-weight: 550;
  height: 12px
  line-height: 1.4;
  color: ${factoryColor.mainGreen};

`;

export const FormStatement = styled.h1`
  font-size: 20px;
  padding-top: 10px;
  font-weight: 550;
  line-height: 1.4;
  color: #48484a;
`;

export const RedStar = styled.span`
  color: red;
`;

export interface active {
  active?: boolean;
}

export const InputContainer = styled.div<active>`
  display: flex;
  width: 95%;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 3px;
  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: ${(props) => (props.active ? "#f4f4f5" : "#fff")};
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;
export const ConfirmBtnContainer = styled.div`
  display: "flex";
  width: 100%;
  flex-direction: column;
  height: 40px;
  border-radius: 5px;

  background-color: ${factoryColor.mainGreen};
  margin-top: 30px;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    background-color: rgba(0, 162, 51, 0.9);
  }
`;

export const ConfirmBtn = styled.button`
  width: 100%;
  height: 100%;

  padding-top: 5px;
  padding-bottom: 5px;

  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #008128;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const TermsBox = styled.div`
  overflow: auto;
  height: 90px;
  width: 95%;
  padding-left: 10px;
  border: 1p solid #fff;
  background: #eee;
  box-sizing: border-box;
  border-radius: 14px;
`;
export const Article = styled.div`
  color: #48484a;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  text-align: left;
`;
export const ArticleTittle = styled.h3`
  font-size: 12px;
  font-weight: 700;
  line-height: 2px;
  padding-bottom: 8px;
`;

export const ArticleText = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const CheckBox = styled.input`
  display: flex;
  width: 25px;
  margin-right: 10px;
  outline: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #48484a;
`;

export const NextBtnContainer = styled.div<active>`
  display: flex;
  height: 37px;
  margin-top: 30px;
  border-radius: 5px;
  background-color: ${(props) => (props.active ? "#2EA566" : "#9EA6B4")};
  width: 100%;

  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    opacity: 0.6;
  }
`;

export const NextBtn = styled.button<active>`
  width: 100%;
  height: 100%;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? "white" : "gray")};
  background-color: ${(props) => (props.active ? "#2EA566" : "#9EA6B4")};
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }
`;
