import React from "react";
import logo from "./logo.svg";
import { RecoilRoot } from "recoil";
import { SWRConfig } from "swr";
import MainScreen from "./screen/MainScreen";
// import ConfirmModal from './src/components/common/ConfirmModal';
// import LoadingModal from './src/components/common/LoadingModal';
import "./App.css";

function App() {
  return (
    <RecoilRoot>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          // revalidateOnMount: false,
          refreshWhenHidden: false,
          refreshWhenOffline: false,
        }}
      >
        <div style={{ flex: 1 }}>
          <MainScreen />
          {/* <ConfirmModal />
        <LoadingModal /> */}
        </div>
      </SWRConfig>
    </RecoilRoot>
  );
}

export default App;
