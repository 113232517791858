import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  AddFactoryModalState,
  GlobalLoadingState,
  ModifyChungGuModalState,
} from "../../store/ModalStores";
import { alignItemType, CommonType, JustifyType } from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import closeIcon from "../../asset/close-circle.png";

export type NoticeModalProps = {};

export const ModifyFactoryOwnerReportModal = () => {
  const [thisModal, setThisModal] = useRecoilState(ModifyChungGuModalState);
  const resetThisModal = useResetRecoilState(ModifyChungGuModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const [factoryID, setFactoryID] = useState(thisModal.factory_id);
  const [factoryName, setFactoryName] = useState(thisModal.factory_name);
  const [lookupMonth, setLookupMonth] = useState(thisModal.lookup_month);
  const [lookupYear, setLookupYear] = useState(thisModal.lookup_year);
  const [totalFee, setTotalFee] = useState(thisModal.total_fee);
  const [rentalFee, setRentalFee] = useState(thisModal.rental_fee);
  const [managementFee, setManagementFee] = useState(thisModal.management_fee);
  const [reportUrl, setReportUrl] = useState(thisModal.report_url);
  const [requestUrl, setRequestUrl] = useState(thisModal.request_url);

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const numberFormat = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberToKorean = (nb: number | undefined) => {
    if (nb === undefined) {
      return "";
    }
    var inputNumber: number = nb < 0 ? 0 : nb;
    var unitWords = ["", "만", "억", "조", "경"];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = "";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    return resultString;
  };

  useEffect(() => {
    setFactoryID(thisModal.factory_id);
    setFactoryName(thisModal.factory_name);
    setLookupMonth(thisModal.lookup_month);
    setLookupYear(thisModal.lookup_year);
    setTotalFee(thisModal.total_fee);
    setRentalFee(thisModal.rental_fee);
    setManagementFee(thisModal.management_fee);
    setReportUrl(thisModal.report_url);
    setRequestUrl(thisModal.request_url);
  }, [thisModal]);

  const convertTotalFee = (rentalFee: string) => {
    try {
      var y: number = +rentalFee;
      setTotalFee(y);
    } catch {
      setTotalFee(0);
    }
  };

  const convertRentalFee = (rentalFee: string) => {
    try {
      var y: number = +rentalFee;
      setRentalFee(y);
    } catch {
      setRentalFee(0);
    }
  };

  const convertMgmtFee = (rentalFee: string) => {
    try {
      var y: number = +rentalFee;
      setManagementFee(y);
    } catch {
      setManagementFee(0);
    }
  };

  const calcMgmtFee = (mgmtFee: string) => {
    try {
      var y: number = +mgmtFee;
      if (totalFee && rentalFee) {
        let calcResult = totalFee - rentalFee * 1.1;
        setManagementFee(calcResult);
      } else {
        setManagementFee(y);
      }
    } catch {
      setManagementFee(0);
    }
  };

  const sendRequest = () => {
    if (factoryID < 0) {
      alert("관리 공장을 찾을 수 없습니다.");
    } else if (lookupMonth < 0) {
      alert("유효하지 않은 월 입니다.");
    } else if (lookupYear < 0) {
      alert("유효하지 않은 연도 입니다.");
    } else {
      setLoadingModal({
        isLoading: true,
        message: "청구 내역을 반영하고 있습니다.",
      });
      _client
        .postApiWithToken("/rental_book_for_manager/modify_owner_report/all", {
          factory_id: factoryID,
          lookup_month: lookupMonth,
          lookup_year: lookupYear,
          total_fee: totalFee,
          total_requested_rental_fee: rentalFee,
          total_requested_management_fee: managementFee,
          total_requested_report_url: reportUrl,
          requested_rental_fee_to_owner_url: requestUrl,
        })
        .then((response) => {
          resetLoadingModal();
          alert(response.msg);
          resetThisModal();
        })
        .catch((err) => {
          resetLoadingModal();
          alert(err);
        });
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ItemCtnr>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  [{factoryName} {lookupYear}년 {lookupMonth}월 청구내역 수정]
                </div>
                <ItemTitle>
                  총 금액 (입력값: {numberToKorean(totalFee)} 원)
                  <br /> *VAT 포함
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(totalFee).toString()}
                    onChange={(e) => convertTotalFee(e.target.value)}
                    disabled={false}
                    placeholder="총 금액을 입력하세요. (VAT 포함)"
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  임대료 (입력값: {numberToKorean(rentalFee)} 원)
                  <br /> *VAT 제외
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(rentalFee).toString()}
                    onChange={(e) => convertRentalFee(e.target.value)}
                    disabled={false}
                    placeholder="임대료를 입력하세요. (숫자만)"
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  관리비 (입력값: {numberToKorean(managementFee)} 원)
                  <br /> *VAT 포함 <br />
                </ItemTitle>
                <ItemInputCtnr>
                  <MgmtInput
                    type="number"
                    inputMode="numeric"
                    value={Number(managementFee).toString()}
                    onChange={(e) => convertMgmtFee(e.target.value)}
                    disabled={false}
                    placeholder="관리비를 입력하세요. (숫자만)"
                  />
                  <CalcDiv
                    onClick={() => {
                      calcMgmtFee(Number(managementFee).toString());
                    }}
                  >
                    계산하기
                  </CalcDiv>
                </ItemInputCtnr>
                <ItemTitle>관리 보고서 원본 URL</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={reportUrl}
                    onChange={(e) => setReportUrl(e.target.value)}
                    disabled={false}
                    placeholder="관리 보고서 원본 URL 입력"
                  />
                </ItemInputCtnr>
                <ItemTitle>관리비 청구서 원본 URL</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={requestUrl}
                    onChange={(e) => setRequestUrl(e.target.value)}
                    disabled={false}
                    placeholder="관리비 청구서 원본 URL 입력"
                  />
                </ItemInputCtnr>
              </ItemCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => sendRequest()}>
                  수정 반영하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "300px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
`;

const ItemCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const ItemTitle = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  margin-top: 20px;
`;

const ItemInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

const MgmtInput = styled.input`
  display: flex;
  width: 75%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

const CalcDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 30%;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;
