import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { factoryColor } from "../../constants/Colors";
import processIcon from "../../asset/one-week-process.png";
import processOne from "../../asset/process-one.png";
import processTwo from "../../asset/process-two.png";
import processThree from "../../asset/process-three.png";
import rowProcessOne from "../../asset/row-process-one.png";
import rowProcessTwo from "../../asset/row-process-two.png";
import rowProcessThree from "../../asset/row-process-three.png";
import processArrow from "../../asset/ico-arrow-right.png";
import processArrowDown from "../../asset/ico-arrow-down.png";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { myFont } from "../../constants/Fonts";
import { CommonType } from "../../constants/Styles";

export const OneWeekProcess = () => {
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width:501px) and (max-width: 1023px)",
  });

  const isUnderTablit = useMediaQuery({
    query: "(max-width: 1440px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width:500px)",
  });

  return (
    <MainCtnr>
      <Ctnr
        name="InnerCtnr"
        row={false}
        width={isUnderTablit ? "100%" : "1440px"}
        height={isUnderTablit ? "auto" : "100%"}
      >
        <Ctnr
          name="TextCtnr"
          row={false}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          etc={"padding-left: 144px;padding-top: 70px;align-self: flex-start;"}
          tablitEtc={
            "padding-left: 0px;width: 80vw;align-self: center;padding-top: 50px;"
          }
          mobileEtc={"align-self:center;padding-top: 50px; width: 90vw;"}
        >
          <CategoryCtnr>
            <Icon src={processIcon}></Icon>
            도입 과정
          </CategoryCtnr>
          <Title>
            서비스 도입에 걸리는 시간?
            {isMobile || isTablet ? <br /> : " "}
            <span style={{ color: "#2EA566" }}>단 1주일</span>
          </Title>
          {/* <SubTitle>
            지금 바로 무료신청 하세요.
          </SubTitle> */}
        </Ctnr>
        <Ctnr
          name="processCtnr"
          row={isUnderTablit ? false : true}
          alignItems="center"
          justifyContent="center"
          etc="gap: 15px;padding-top: 50px;padding-bottom: 50px;"
        >
          {isUnderTablit ? (
            <>
              <CustomImg src={rowProcessOne} width={isMobile? "90%":"70%"} />
              <CustomImg src={processArrowDown} width={"50px"} />
              <CustomImg src={rowProcessTwo} width={isMobile? "90%":"70%"} />
              <CustomImg src={processArrowDown} width={"50px"} />
              <CustomImg src={rowProcessThree} width={isMobile? "90%":"70%"} />
            </>
          ) : (
            <>
              <CustomImg src={processOne} width={"300px"} />
              <CustomImg src={processArrow} width={"50px"} />
              <CustomImg src={processTwo} width={"300px"} />
              <CustomImg src={processArrow} width={"50px"} />
              <CustomImg src={processThree} width={"300px"} />
            </>
          )}
        </Ctnr>
      </Ctnr>
    </MainCtnr>
  );
};

const MainCtnr = styled.div`
  box-sizing: border-box;
  height: auto;
  background-color: #f2f4f6;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;
`;

export const Icon = styled.img`
  width: 20px;
  margin-right: 15px;
`;

const CategoryCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 20px;
  font-weight: 700;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
`;

const Title = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 5px;
  color: ${factoryColor.textPrimary};
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 7vw;
  }
`;

const SubTitle = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  color: ${factoryColor.descriptionPrimary};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  @media (max-width: ${Pixel.mobileWidth}) {
    margin-top: 15px;
    font-size: 7vw;
  }
`;

interface ImgProp {
  width?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  desktopEtc?: string;
  tablitEtc?: string;
  mobileEtc?: string;
}

const CustomImg = styled.img<ImgProp>`
  display: flex;
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  margin-left: 0px;

  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};

  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "")};
  @media (max-width: ${Pixel.mobileWidth}) {
    margin-left: 0px;
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }

  @media (max-width: ${Pixel.desktopWidth}) {
    margin-left: 0px;
    ${({ tablitEtc }) => (tablitEtc ? tablitEtc : "")}
    padding-right: 0px;
  }
`;

export interface flexOption {
  name?: string;
  row?: boolean;
  justifyContent?: string;
  alignItems?: string;
  alignSelf?: string;
  width?: string;
  height?: string;
  etc?: string;
  tablitEtc?: string;
  mobileEtc?: string;
}

export const Ctnr = styled.div<flexOption>`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : "center")};

  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  flex-wrap: wrap;
  ${({ etc }) => (etc ? etc : "")}

  @media (max-width: ${Pixel.desktopWidth}) {
    ${({ tablitEtc }) => (tablitEtc ? tablitEtc : "")}
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }
`;

const RightAttachCtnr = styled.div`
  position: relative;
  width: 100vw;
  left: 0px;
  border: 1px solid blue;
`;
