import styled from "styled-components";
import { factoryColor } from "../constants/Colors";
import leftArrow from "../asset/left-arrow.png";
import rightArrow from "../asset/right-arrow.png";

import cleaningIcon from "../asset/cleaning.png";
import fireIcon from "../asset/fire.png";
import lightIcon from "../asset/light.png";
import elevatorIcon from "../asset/elevator.png";
import securityIcon from "../asset/security.png";
import spannerIcon from "../asset/spanner.png";
import metaportIcon from "../asset/metaport.png";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { myFont } from "../constants/Fonts";
import {
  JustifyType,
  alignItemType,
  textAlign,
  CommonType,
} from "../constants/Styles";
import { _client } from "../apis/client";
import useSWR from "swr";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useMediaQuery } from "react-responsive";
import {
  ContractDetailModalState,
  FacilityDetailModalState,
  ModelingRequestModalState,
  ModifyFacilityModalState,
  UserLoginStatusType,
} from "../store/ModalStores";
import { useEffect, useState } from "react";

interface singleFacilityData {
  id: number;
  factory_id: number;
  facility_type: string;
  facility_company_name: string;
  contract_type: string;
  contract_deposit: number;
  facility_manage_fee: number;
  facility_super_tax_fee: number;
  facility_total_fee: number;
  contract_start_date: string;
  contract_end_date: string;
  contract_paper_url: string;
  additional_info: string;
  year: number;
  month: number;
}

interface facilityData {
  factory_name: string;
  all_facilities: singleFacilityData[];
}

let contractSampleUrl =
  "https://drive.google.com/file/d/1-WZjgAs97ZtHyFEmkjSCVQaTrEKJrNGn/view?usp=share_link";

export const FacilityScreen = () => {
  const today = new Date();
  const [currentYear, setCurrentYear] = useState<number>(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(
    today.getMonth() + 1
  );
  const [prevMonth, setPrevMonth] = useState<number>(today.getMonth());
  const [nextMonth, setNextMonth] = useState<number>(today.getMonth() + 2);
  const [currentFactoryName, setCurrentFactoryName] = useState<string>("");
  const [currentFactoryID, setCurrentFactoryID] = useState<number>(0);

  useEffect(() => {
    facilityApiMutate();
    // 12월에서 다음 버튼 클릭시 (12 -> 13)
    if (currentMonth > 12) {
      setCurrentYear(currentYear + 1); // +1년
      setCurrentMonth(1); // 현재 월은 1월로 변경
      setPrevMonth(12); // 전달은 12월로 표시
      setNextMonth(2); // 다음달은 2월로 표시
    }
    // 1월에서 이전 버튼 클릭시 (1 -> 0)
    else if (currentMonth < 1) {
      setCurrentYear(currentYear - 1); // -1년
      setCurrentMonth(12); // 현재 월은 1월로 변경
      setPrevMonth(11); // 전달은 11월로 표시
      setNextMonth(1); // 다음달은 1월로 표시
      // 11 -> 12월 될때
    } else if (currentMonth == 12) {
      setPrevMonth(currentMonth - 1);
      setNextMonth(1);
      // 2월 -> 1월 될때
    } else if (currentMonth == 1) {
      setPrevMonth(12);
      setNextMonth(currentMonth + 1);
    } else {
      setPrevMonth(currentMonth - 1);
      setNextMonth(currentMonth + 1);
    }
  }, [currentMonth]);

  const isLoggedIn =
    localStorage.getItem("realizableLoginStatus") ===
    UserLoginStatusType.LOGGEDIN
      ? true
      : false;

  const {
    data: facilityData,
    error: facilityErr,
    isLoading: facilityLoad,
    mutate: facilityApiMutate,
  } = useSWR(
    isLoggedIn ? "/facility/list" : "/facility/list_sample",
    async (url: string | undefined) => {
      if (isLoggedIn) {
        return await _client.postApiWithToken(url, {
          lookup_year: currentYear,
          lookup_month: currentMonth,
        });
      } else {
        return await _client.postApi(url, {
          lookup_year: 2023,
          lookup_month: 4,
        });
      }
    }
  );

  const [facilityDetailModal, setFacilityDetailModal] = useRecoilState(
    FacilityDetailModalState
  );
  const [modifyFacilityModal, setModifyFacilityModal] = useRecoilState(
    ModifyFacilityModalState
  );

  useEffect(() => {
    console.log("Re-fetch facility data");
    facilityApiMutate();
  }, [modifyFacilityModal, facilityDetailModal]);

  const [modelingModal, setModelingModal] = useRecoilState(
    ModelingRequestModalState
  );

  const openFacilityDetailModal = (
    factory_id: number,
    facility_type: string,
    facility_company_name: string,
    contract_type: string,
    contract_deposit: number,
    facility_manage_fee: number,
    facility_super_tax_fee: number,
    facility_total_fee: number,
    contract_start_date: string,
    contract_end_date: string,
    contract_paper_url: string,
    additional_info: string,
    year: number,
    month: number
  ) => {
    console.log(facility_company_name)
    setFacilityDetailModal({
      show: true,
      factory_id: factory_id,
      facility_type: facility_type,
      facility_company_name: facility_company_name,
      contract_type: contract_type,
      contract_deposit: contract_deposit,
      facility_manage_fee: facility_manage_fee,
      facility_super_tax_fee: facility_super_tax_fee,
      facility_total_fee: facility_total_fee,
      contract_start_date: contract_start_date,
      contract_end_date: contract_end_date,
      contract_paper_url: contract_paper_url,
      additional_info: additional_info,
      year: year,
      month: month,
    });
  };

  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });

  useEffect(() => {
    if (facilityData?.data?.length > 0) {
      setCurrentFactoryName(facilityData.data[0].factory_name);
    }
  }, [facilityData]);

  return (
    <OuterCtnr>
      <HeaderAlignCtnr>
        <PageTitleCtnr>시설 관리</PageTitleCtnr>
        <FlexCtnr row={false} justifyContent={"flex-start"}>
          <FlexCtnr row={false}>
            <PageSubTitleCtnr>시설 종류</PageSubTitleCtnr>
            <PageDescCtnr>
              시설 관리를 한 화면에서 편리하게 관리해보세요!
            </PageDescCtnr>
          </FlexCtnr>
          <SelectCtnr>
            <FactorySelector>
              <FactoryOption
                fontSize={"100%"}
                mobileFontSize={"15px"}
                fontWeight={"500"}
                value={currentFactoryName}
                onChange={(event) => {
                  setCurrentFactoryName(event.target.value);
                }}
              >
                {facilityData?.data?.map((element: facilityData) => {
                  return (
                    <option key={element.factory_name}>
                      {element.factory_name}
                    </option>
                  );
                })}
              </FactoryOption>
            </FactorySelector>
          </SelectCtnr>
          <MonthSelectCtnr>
            <MonthSelector>
              <ArrowIcon src={leftArrow} />
              <MoveMonth onClick={() => setCurrentMonth(currentMonth - 1)}>
                {prevMonth}월
              </MoveMonth>
              <CurrentMonth>
                {currentYear.toString()}년 {currentMonth.toString()}월
              </CurrentMonth>
              <MoveMonth onClick={() => setCurrentMonth(currentMonth + 1)}>
                {nextMonth}월{" "}
              </MoveMonth>
              <ArrowIcon src={rightArrow} />
            </MonthSelector>
          </MonthSelectCtnr>
        </FlexCtnr>
        {facilityErr ? (
          <FlexCtnr row={true}>{facilityErr}</FlexCtnr>
        ) : (
          <FlexCtnr
            key={currentYear}
            row={true}
            justifyContent={"flex-start"}
            etc={"border-bottom: 1px solid #d7dbe2;gap: 25px;"}
          >
            {facilityData?.data?.map((e: facilityData) => {
              if (currentFactoryName === e.factory_name) {
                return (
                  <>
                    {e.all_facilities.map((f: singleFacilityData) => {
                      return (
                        <FacilityItem
                          key={f.factory_id}
                          onClick={() =>
                            openFacilityDetailModal(
                              f.factory_id,
                              f.facility_type,
                              f.facility_company_name,
                              f.contract_type,
                              f.contract_deposit,
                              f.facility_manage_fee,
                              f.facility_super_tax_fee,
                              f.facility_total_fee,
                              f.contract_start_date,
                              f.contract_end_date,
                              f.contract_paper_url,
                              f.additional_info,
                              f.year,
                              f.month
                            )
                          }
                        >
                          <IconCtnr>
                            {f.facility_type === "청소" && (
                              <FacilityIcon src={cleaningIcon} />
                            )}
                            {f.facility_type === "소방" && (
                              <FacilityIcon src={fireIcon} />
                            )}
                            {f.facility_type === "전기" && (
                              <FacilityIcon src={lightIcon} />
                            )}
                            {f.facility_type === "승강기" && (
                              <FacilityIcon src={elevatorIcon} />
                            )}
                            {f.facility_type === "경비" && (
                              <FacilityIcon src={securityIcon} />
                            )}
                            {f.facility_type === "공사" && (
                              <FacilityIcon src={spannerIcon} />
                            )}
                          </IconCtnr>
                          <FacilityTitle>
                            {f.facility_type}
                          </FacilityTitle>
                          <Subtitle>계약 상세 보기</Subtitle>
                        </FacilityItem>
                      );
                    })}
                  </>
                );
              } else {
                return null;
              }
            })}
          </FlexCtnr>
        )}
        <FlexCtnr
          row={isPc ? true : false}
          justifyContent="space-between"
          etc="margin-top: 30px;"
        >
          <FlexCtnr
            row={isPc ? false : true}
            justifyContent={isPc ? "flex-start" : "space-between"}
            etc={"gap: 15px;"}
          >
            <FlexCtnr
              row={false}
              justifyContent={"flex-start"}
              etc={"align-items: flex-start;"}
            >
              <FlexCtnr
                row={true}
                justifyContent="flex-start"
                etc={"font-size: 30px;font-weight:700;margin-bottom: 15px;"}
              >
                시설 3D 모델링 확인
              </FlexCtnr>
              <FlexCtnr row={true} etc={"font-size: 20px;font-weight:500"}>
                공장 내부 구조를 3D로 볼 수 있습니다.
              </FlexCtnr>
            </FlexCtnr>
            <BtnCtnr
              row={true}
              // fill={false}
              onClick={() => setModelingModal({ show: true })}
              etc="padding: 20px; background-color: black; color: white; font-size:20px; border-radius: 10px;font-weight: 600;align-items: center;"
            >
              모델링 신청하기
            </BtnCtnr>
          </FlexCtnr>
          <FlexCtnr
            row={true}
            etc={isPc ? "margin-top: 10px;" : "margin-top: 20px;"}
          >
            <MetaportImg src={metaportIcon} />
          </FlexCtnr>
        </FlexCtnr>
      </HeaderAlignCtnr>
    </OuterCtnr>
  );
};

const OuterCtnr = styled.div`
  display: flex;
  flex-direction: column;

  align-items: ${alignItemType.center};
  justify-content: ${JustifyType.center};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #ffffff;
`;

const HeaderAlignCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlack};
  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const PageTitleCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 40px;
  font-weight: 700;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border-bottom: 1px solid ${factoryColor.border_secondary};
`;

const PageSubTitleCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 5px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 25px;
  font-weight: 700;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
`;

const PageDescCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 5px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 18px;
  font-weight: 600;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
`;

const SelectCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  align-content: center;
  align-items: center;
  text-align: center;
  ${CommonType.alignSelfStart}
  overflow: hidden;

  height: 50px;
  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

const FactorySelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.fromStart};
  text-align: ${textAlign.start};
  width: 200px;
  padding: 12px;

  font-size: 18px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const FactoryOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  width: 100%;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export interface flexOption {
  row: boolean;
  justifyContent?: string;
  fill?: boolean;
  etc?: string;
}

export const FlexCtnr = styled.div<flexOption>`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  box-sizing: border-box;
  width: ${({ fill }) => (fill ? "100%" : "")};
  flex-wrap: wrap;
  ${({ etc }) => (etc ? etc : "")}
`;

export const BtnCtnr = styled.div<flexOption>`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  box-sizing: border-box;
  width: ${({ fill }) => (fill ? "100%" : "")};
  flex-wrap: wrap;
  ${({ etc }) => (etc ? etc : "")}

  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;

const FacilityItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  border-radius: 20px;
  background-color: #e9f9f0;
  color: #118849;
  margin-right: 20px;
  margin-bottom: 20px;
  gap: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    margin-right: 0px;
  }
`;

const IconCtnr = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FacilityTitle = styled.div`
  dislpay: flex;
  flex-direction: column;
  justify-content: cetner;
  align-items: center;
  text-align: center;
  color: #118849;
  font-weight: 700;
  font-size: 18px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #2ea566;
  text-align: center;
`;

const FacilityIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 30px;
`;

const MetaportImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 700px;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: 80vw;
  }
  @media (max-width: ${Pixel.mobileWidth}) {
    width: 80vw;
  }
`;

export const MonthSelectCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 30px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  height: 50px;
  font-size: 18px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 0px solid ${factoryColor.border_secondary};
`;

export const MonthSelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 350px;
  padding: 10px;

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

export const MoveMonth = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 20%;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
  border: 0px solid ${factoryColor.border_secondary};
`;

const CurrentMonth = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 52%;

  font-size: 22px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border-left: 1px solid ${factoryColor.border_secondary};
  border-right: 1px solid ${factoryColor.border_secondary};
  padding-left: 5%;
  padding-right: 5%;
`;
export const ArrowIcon = styled.img`
  width: 2.5%;
`;
