import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  AddFactoryModalState,
  GlobalLoadingState,
  ModifyChungGuModalState,
  FactoryMetaInfoModalState,
} from "../../store/ModalStores";
import { alignItemType, CommonType, JustifyType } from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import closeIcon from "../../asset/close-circle.png";

export type NoticeModalProps = {};

export const ModifyFactoryStatsModal = () => {
  const [thisModal, setThisModal] = useRecoilState(FactoryMetaInfoModalState);
  const resetThisModal = useResetRecoilState(FactoryMetaInfoModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const [factoryID, setFactoryID] = useState(thisModal.factory_id);
  const [factoryName, setFactoryName] = useState(thisModal.factory_name);
  const [lookupMonth, setLookupMonth] = useState(thisModal.month);
  const [lookupYear, setLookupYear] = useState(thisModal.year);
  const [mgmtFee, setMgmtFee] = useState(thisModal.mgmt_fee_per_pyeong);
  const [rentalFee, setRentalFee] = useState(thisModal.rental_fee_per_pyeong);
  const [deposit, setDeposit] = useState(thisModal.deposit_per_pyeong);
  const [totalLand, setTotalLand] = useState(thisModal.total_land_area);
  const [rentArea, setRentArea] = useState(thisModal.rent_area);
  const [ownerArea, setOwnerArea] = useState(thisModal.owner_area);
  const [emptyArea, setEmptyArea] = useState(thisModal.empty_area);

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const numberFormat = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberToKorean = (nb: number | undefined) => {
    if (nb === undefined) {
      return "";
    }
    var inputNumber: number = nb < 0 ? 0 : nb;
    var unitWords = ["", "만", "억", "조", "경"];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = "";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    return resultString;
  };

  useEffect(() => {
    setFactoryID(thisModal.factory_id);
    setFactoryName(thisModal.factory_name);
    setLookupMonth(thisModal.month);
    setLookupYear(thisModal.year);
    setMgmtFee(thisModal.mgmt_fee_per_pyeong);
    setRentalFee(thisModal.rental_fee_per_pyeong);
    setDeposit(thisModal.deposit_per_pyeong);
    setTotalLand(thisModal.total_land_area);
    setRentArea(thisModal.rent_area);
    setOwnerArea(thisModal.owner_area);
    setEmptyArea(thisModal.empty_area);
  }, [thisModal]);

  const sendRequest = () => {
    if (factoryID === null || factoryID === undefined || factoryID < 0) {
      alert("관리 공장을 찾을 수 없습니다.");
    } else if (
      lookupMonth === null ||
      lookupMonth === undefined ||
      lookupMonth < 0
    ) {
      alert("유효하지 않은 월 입니다.");
    } else if (
      lookupYear === null ||
      lookupYear === undefined ||
      lookupYear < 0
    ) {
      alert("유효하지 않은 연도 입니다.");
    } else {
      setLoadingModal({
        isLoading: true,
        message: "Meta 정보를 내역을 반영하고 있습니다.",
      });
      _client
        .postApiWithToken("/manager/factory_stats_info/update", {
          factory_id: factoryID,
          month: lookupMonth,
          year: lookupYear,
          mgmt_fee_per_pyeong: mgmtFee,
          rental_fee_per_pyeong: rentalFee,
          deposit_per_pyeong: deposit,
          total_land_area: totalLand,
          rent_area: rentArea,
          owner_area: ownerArea,
          empty_area: emptyArea,
        })
        .then((response) => {
          resetLoadingModal();
          alert(response.msg);
          resetThisModal();
        })
        .catch((err) => {
          resetLoadingModal();
          alert(err);
        });
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ItemCtnr>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  [{factoryName} {lookupYear}년 {lookupMonth}월 관리 현황 수정]
                </div>
                <ItemTitle>
                  평당 관리비 (입력값: {numberToKorean(Number(mgmtFee))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(mgmtFee).toString()}
                    onChange={(e) => setMgmtFee(Number(e.target.value) || 0)}
                    disabled={false}
                    placeholder="평당 관리비를 입력하세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  평당 임대료 (입력값: {numberToKorean(Number(rentalFee))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(rentalFee).toString()}
                    onChange={(e) => setRentalFee(Number(e.target.value) || 0)}
                    disabled={false}
                    placeholder="평당 임대료를 입력하세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  평당 보증금 (입력값: {numberToKorean(Number(deposit))} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(deposit).toString()}
                    onChange={(e) => setDeposit(Number(e.target.value) || 0)}
                    disabled={false}
                    placeholder="평당 보증금을 입력하세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>공부상 면적 (단위: 제곱미터)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(totalLand).toString()}
                    onChange={(e) => setTotalLand(Number(e.target.value) || 0)}
                    disabled={false}
                    placeholder=""
                  />
                </ItemInputCtnr>
                <ItemTitle>실 임대 면적 (단위: 제곱미터)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(rentArea).toString()}
                    onChange={(e) => setRentArea(Number(e.target.value) || 0)}
                    disabled={false}
                    placeholder=""
                  />
                </ItemInputCtnr>
                <ItemTitle>임대인 사용 면적 (단위: 제곱미터)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(ownerArea).toString()}
                    onChange={(e) => setOwnerArea(Number(e.target.value) || 0)}
                    disabled={false}
                    placeholder=""
                  />
                </ItemInputCtnr>
                <ItemTitle>공실 면적 면적 (단위: 제곱미터)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={Number(emptyArea).toString()}
                    onChange={(e) => setEmptyArea(Number(e.target.value) || 0)}
                    disabled={false}
                    placeholder=""
                  />
                </ItemInputCtnr>
              </ItemCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => sendRequest()}>
                  수정 반영하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "300px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
`;

const ItemCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const ItemTitle = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  margin-top: 20px;
`;

const ItemInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

const MgmtInput = styled.input`
  display: flex;
  width: 75%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

const CalcDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 30%;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;
