import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {userJoin} from "./StartRegister"
import completeImg from "../../asset/coplete.png";
import { factoryColor } from "../../constants/Colors";
import { useRecoilState } from "recoil";
import { LoginModalState } from "../../store/ModalStores";

export interface RegformProp {
  setStep(step: number): void;
  setFormData(form: any): void;
  formData: userJoin;
}

export const RegisterCompletePage = () => {
//   const history = useHistory();
//   const login_status = useSelector((state) =>
//     state.userReducer.get_login_status()
//   );
//   if (login_status === UserLoginStatusType.LOGGEDIN) {
//     history.push("/");
//     return null;
//   }
//   const { open_login_modal } = useLoginModal();
  let navigate = useNavigate();
  const [loginModal, setLoginModal] = useRecoilState(LoginModalState);

  const goMainPage = () => {
    // To to main and Login
    navigate("/");
    setLoginModal({
      show: true,
      onPress: () => {},
    });

    // try {
    //   open_login_modal();
    // } catch (e) {
    //   console.error(`로그인 모달 열기 실패, 원인: ${e}`);
    // }
  };

  return (
    <RegisterBackground>
      <RegisterInner>
        <CompleteItem>
          <CompleteIcon src={completeImg} />
          <TitleDiv>가입이 완료되었습니다!</TitleDiv>
        </CompleteItem>
        <CompleteBtnContainer>
          <CompleteBtn onClick={() => goMainPage()}>
            메인 페이지로 이동
          </CompleteBtn>
        </CompleteBtnContainer>
      </RegisterInner>
    </RegisterBackground>
  );
};

export const RegisterBackground = styled.div`
  position: relative;

  padding: 0;
  margin: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  top: 4px;

  z-index: 100;
  display: flex;
  font-family: NotoSansKR;
  justify-content: center;
  box-sizing: border-box;
  background-color: #eee;
  @media (max-width: 500px) {
    top: 2px;
  }

  @media (max-height: 800px) {
    height: 900px;
  }
`;

export const RegisterInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 195px;
  width: 502px;
  height: 170px;
  border-radius: 14px;
  align-items: center;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;

  border-radius: 14px;
  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;


  @media (max-width: 700px) {
    margin-top: 100px;
    width: 60%;
    height: 150px;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  font-family: NotoSansKR;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.84px;
  text-align: center;
  color: #48484a;
`;

interface prop {
  active?: boolean;
}

const ProgressItem = styled.div<prop>`
  display: flex;
  flex-direction: column;
  width: 190px;
  align-items: center;
  justify-content: center;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  text-align: center;
  color: #48484a;
  @media (max-width: 500px) {
    font-size: 14px;
  }

`;

const CompleteItem = styled.div<prop>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  color: #48484a;
  @media (max-width: 500px) {
    font-size: 14px;
  }

`;

const CompleteIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 15%;
`;

const CompleteBtnContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 37px;
  width: 100%;
  border-radius: 5px;
  border: solid 3px ${factoryColor.mainGreen};
  background-color: ${factoryColor.mainGreen};
  text-align: center;
  align-items: center;
  justify-content: center;
  :hover {
    border: 3px solid rgba(0, 162, 51, .8);
    opacity: 0.8;
  }

  margin-top: 0%;

  @media (max-width: 700px) {
    margin-top: 0%;
  }

  @media (max-width: 500px) {
    margin-top: 14px;
  }
`;

const CompleteBtn = styled.button`
  width: 100%;
  height: 25px;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

