import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
// import Carousel from "react-multi-carousel";
import { Carousel } from "react-responsive-carousel";
import { factoryColor } from "../../constants/Colors";
import reviewIcon from "../../asset/review.png";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { myFont } from "../../constants/Fonts";
import {
  alignA,
  alignB,
  GlobalRowFlex,
  textAlign,
} from "../../constants/Styles";
import mainBgr from "../../asset/landing-bgr.png";

export const ReviewComment = () => {
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:501px) and (max-width: 1023px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width:500px)",
  });

  return (
    <MainCtnr>
      <AlignCtnr>
        <CategoryCtnr>
          <Icon src={reviewIcon} style={{ marginRight: "15px" }} />
          고객사례
        </CategoryCtnr>
        <Title>
          수많은 공장 대표님들이
          {isMobile || isTablet ? <br /> : " "}
          믿고 선택했어요.{" "}
        </Title>
      </AlignCtnr>
    </MainCtnr>
  );
};

export const Icon = styled.img`
  width: 20px;
`;

const CategoryCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 20px;
  font-weight: 700;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
`;

const MainCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  padding-top: 50px;
  padding-bottom: 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Pixel.mobileWidth}) {
    padding-top: 30px;
    padding-bottom: 10px;
  }
`;

const AlignCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlack};
  width: ${Pixel.desktopWidth};
  overflow: auto;
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const Title = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 5px;
  color: ${factoryColor.textPrimary};
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 30px;
  }
`;

const SubTitle = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  color: ${factoryColor.mainGreen};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
`;

interface Props {
  border?: boolean;
  height?: string;
  width?: string;
  alignA?: string;
  alignB?: string;
  textAlign?: string;
  paddingVertical?: string;
  paddingHorizontal?: string;
  marginVertical?: string;
  marginHorizontal?: string;
}

const LocalRowFlex = styled.div<Props>`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: no-wrap;
  border: 1px solid black;
  flex: 0 0 auto;
`;

const LocalColFlex = styled.div<Props>`
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  justify-content: ${(props) => props.alignA || alignA.center};
  align-items: ${(props) => props.alignB || alignB.center};
  text-align: ${(props) => props.textAlign || textAlign.center};
  box-sizing: border-box;
  border: ${(props) => (props.border ? "1px solid red" : "none")};
  margin-top: ${(props) => props.marginVertical || "0px"};
  margin-bottom: ${(props) => props.marginVertical || "0px"};
  margin-left: ${(props) => props.marginHorizontal || "0px"};
  margin-right: ${(props) => props.marginHorizontal || "0px"};
`;

const CardImg = styled.div<Props>`
  background-color: #ffffff;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    url(${mainBgr});
  background-repeat: no-repeat;
  background-size: initial;
  height: 50%;
  width: 100%;
  border: 1px solid green;
  border-radius: 10px;
`;

const NamePart = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;

  font-size: 20px;
  font-weight: 700;
  color: ${factoryColor.mainGreen};

  box-sizing: border-box;
`;
// const ContentsPart = styled.div`
//   font-family: ${myFont.regular};
//   margin-top: 15px;
//   margin-bottom: 15px;
//   align-content: center;
//   align-items: center;
//   text-align: center;

//   font-size: 20px;
//   font-weight: 400;
//   color: ${factoryColor.descriptionSecondary};

//   box-sizing: border-box;
// `;

const ContentsPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: ${myFont.regular};
  margin-top: 15px;
  margin-bottom: 15px;
  align-content: center;
  align-items: center;
  text-align: center;

  font-size: 20px;
  font-weight: 400;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
`;

const WrapFlex = styled.div`
  overflow-wrap: break-word;
  word-break: break-all;

  align-items: flex-start;
  font-family: ${myFont.regular};
  text-align: ${textAlign.start};
  font-size: 20px;
  font-weight: 400;
  color: ${factoryColor.descriptionSecondary};
  box-sizing: border-box;
`;
