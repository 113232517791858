import styled, { CSSProperties } from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import { factoryColor } from "../constants/Colors";
import closeIcon from "../../asset/close-circle.png";
import {
  WhiteSpan,
  BlackSpan,
  GreenSpan,
  GreenSpanUnderline,
  CommonType,
} from "../constants/Styles";
import { Border, Pixel } from "../constants/Pixel";
import {
  LawRequestModalState,
  LoginModalState,
  SideMenuBarState,
  UserLoginStatusType,
  UserStatus,
} from "../store/ModalStores";
import { useNavigate } from "react-router-dom";
import cancelImg from "../asset/cancel.png";
import fireIcon from "../asset/fire.png";
import { ColFlex, Flex, RowFlex } from "../constants/Flex";

export const SideMenuBar = () => {
  const [sideMenuBar, setSideMenuBar] = useRecoilState(SideMenuBarState);
  const resetMenuBar = useResetRecoilState(SideMenuBarState);
  const [loginModal, setLoginModal] = useRecoilState(LoginModalState);
  const [lawRequestModal, setLawRequestModal] =
    useRecoilState(LawRequestModalState);
  const resetLogin = useResetRecoilState(LoginModalState);
  const resetUserStatus = useResetRecoilState(UserStatus);

  let navigate = useNavigate();
  let mainStyle = defatulMainStyle;

  const clickClose = () => {
    console.log("click close");
    setSideMenuBar({
      show: false,
    });
  };
  const goRentalBookPage = () => {
    resetMenuBar();
    navigate("/rental_book");
  };

  const goRentalBookManagerPage = () => {
    resetMenuBar();
    navigate("/rental_book_manager");
  };

  const goManagerPage = () => {
    resetMenuBar();
    navigate("/manager");
  };

  const goFacilityPage = () => {
    resetMenuBar();
    navigate("/facility");
  };

  const lawInquery = () => {
    if (
      localStorage.getItem("realizableLoginStatus") ===
      UserLoginStatusType.LOGGEDIN
    ) {
      resetMenuBar();
      setLawRequestModal({
        show: true,
      });
      //Open Law Inquery Modal
    } else {
      alert("[공장장]\n로그인이 필요한 서비스입니다.");
      setLoginModal({
        show: true,
        onPress: () => {},
      });
    }
  };

  const goElecPage = () => {
    resetMenuBar();
    navigate("/elec_info");
  };

  const goMyPage = () => {
    resetMenuBar();
    navigate("/mypage");
  };

  const goMainPage = () => {
    resetMenuBar();
    navigate("/");
  };

  const goLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.setItem(
      "realizableLoginStatus",
      UserLoginStatusType.LOGGEDOUT
    );
    localStorage.removeItem("realizableLoginStatusMsg");
    localStorage.removeItem("realizableUserEmail");
    localStorage.removeItem("realizableUserPhoneNumber");
    localStorage.removeItem("realizableUserName");
    localStorage.removeItem("realizableUserType");
    resetUserStatus();
    navigate("/");
    alert("[공장장]\n로그아웃 되었습니다.");
    resetMenuBar();
  };

  const isManager =
    localStorage.getItem("realizableUserType") === "관리인" ? true : false;

  const isLoggedIn =
    localStorage.getItem("realizableLoginStatus") ===
    UserLoginStatusType.LOGGEDIN
      ? true
      : false;

  const openLoginModal = () => {
    resetMenuBar();
    setLoginModal({
      show: true,
      onPress: () => {},
    });
  };

  if (sideMenuBar.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetMenuBar} />
        <div style={mainStyle}>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <Ctnr>
                <CloseCtnr>
                  <CloseImg src={cancelImg} onClick={resetMenuBar} />
                </CloseCtnr>

                {isLoggedIn ? (
                  <>
                    <EachRowCtnr className="myPageCtnr" onClick={goMyPage}>
                      {/* <ColFlex etc="gap: 10px;"> */}
                      <RowFlex
                        width="45px"
                        height="45px"
                        etc="padding: 8px;border-radius: 30px;background-color:#2EA566;"
                      >
                        {
                          localStorage
                            .getItem("realizableUserName")
                            ?.toString()[0]
                        }
                      </RowFlex>
                      {/* <RowFlex>마이페이지로 이동 {">"}</RowFlex> */}
                      {/* </ColFlex> */}
                    </EachRowCtnr>
                  </>
                ) : (
                  <></>
                )}
                <EachRowCtnr onClick={goMainPage}>메인 화면</EachRowCtnr>
                {isManager ? (
                  <EachRowCtnr onClick={goRentalBookManagerPage}>
                    임대장부 (관리자)
                  </EachRowCtnr>
                ) : (
                  <EachRowCtnr onClick={goRentalBookPage}>
                    임대 장부
                  </EachRowCtnr>
                )}
                <EachRowCtnr onClick={goFacilityPage}>시설 관리</EachRowCtnr>
                <EachRowCtnr onClick={lawInquery}>법률 문의</EachRowCtnr>
                <EachRowCtnr onClick={goElecPage}>전력 관리</EachRowCtnr>
                {isManager && (
                  <EachRowCtnr onClick={goManagerPage}>관리자</EachRowCtnr>
                )}
                {isLoggedIn ? (
                  <EachRowCtnr onClick={goLogout}>로그아웃</EachRowCtnr>
                ) : (
                  <EachRowCtnr onClick={openLoginModal}>로그인</EachRowCtnr>
                )}
              </Ctnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const OuterDiv = styled.div`
  position: "fixed";
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  background: #003c3c;
  z-index: 10;
  display: flex;
`;

const Ctnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsStart}
  ${CommonType.justifyContenStart}
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 15px;
  gap: 30px;
  height: 80%;
  z-index: 100;
`;
const CloseCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContenEnd}
  ${CommonType.alignSelfStart}
  z-index: 220;
  width: 50px;
`;

const CloseImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 40px;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
    transform: rotate(90deg);
    transition: all 0.25s ease-in-out;
  }
  z-index: 230;
`;

const IconCtnr = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const FacilityIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 30px;
`;

const EachRowCtnr = styled.div`
  padding-left: 20px;
  font-size: 20px;
  color: white;
  ${CommonType.fontWeight500}
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  width: "50vw",
  height: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  fontSize: "20px",
  textAlign: "center",
  color: `${factoryColor.mainGreen}`,
};
