import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import logoGreen from "../../asset/logo_green.png";
import menuIcon from "../../asset/side_bar_menu.png";
import { factoryColor } from "../../constants/Colors";
import { myFont } from "../../constants/Fonts";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { CommonType, JustifyType } from "../../constants/Styles";
import {
  LawRequestModalState,
  LoginModalState,
  SideMenuBarState,
  UserLoginStatusType,
  UserStatus,
} from "../../store/ModalStores";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useEffect } from "react";
import { RowFlex } from "../../constants/Flex";

export const RealizableHeader = () => {
  let navigate = useNavigate();
  const [sideMenuBar, setSideMenuBar] = useRecoilState(SideMenuBarState);
  const [userStatus, setUserStatus] = useRecoilState(UserStatus);
  const resetUserStatus = useResetRecoilState(UserStatus);
  const [loginModal, setLoginModal] = useRecoilState(LoginModalState);
  const resetLogin = useResetRecoilState(LoginModalState);

  const goLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.setItem(
      "realizableLoginStatus",
      UserLoginStatusType.LOGGEDOUT
    );
    localStorage.removeItem("realizableLoginStatusMsg");
    localStorage.removeItem("realizableUserEmail");
    localStorage.removeItem("realizableUserPhoneNumber");
    localStorage.removeItem("realizableUserName");
    localStorage.removeItem("realizableUserType");
    resetUserStatus();
    navigate("/");
    alert("[공장장]\n로그아웃 되었습니다.");
    console.log("logout");
  };

  const goMyPage = () => {
    navigate("/mypage");
  };

  // Sync User Status with LocalStorage <-> ATOM Variables
  useEffect(() => {
    if (
      localStorage.getItem("realizableLoginStatus") ===
        UserLoginStatusType.LOGGEDOUT ||
      localStorage.getItem("realizableLoginStatus") ===
        UserLoginStatusType.LOADING
    ) {
      console.log("here1");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("realizableLoginStatusMsg");
      localStorage.removeItem("realizableUserEmail");
      localStorage.removeItem("realizableUserPhoneNumber");
      localStorage.removeItem("realizableUserName");
      localStorage.removeItem("realizableUserType");
      resetUserStatus();
      console.log(userStatus);
    } else {
      // let storageToken = localStorage.getItem("accessToken");
      setUserStatus({
        loginStatusType: localStorage.getItem("realizableLoginStatus"),
        loginStatusMsg: localStorage.getItem("realizableLoginStatusMsg"),
        userEmail: localStorage.getItem("realizableUserEmail"),
        userPhoneNumber: localStorage.getItem("realizableUserPhoneNumber"),
        userName: localStorage.getItem("realizableUserName"),
        userType: localStorage.getItem("realizableUserType"),
      });
    }
  }, []);

  // useEffect(() => {
  //   console.log("here2");
  //   console.log(userStatus.loginStatusType);
  //   let storageToken = localStorage.getItem("accessToken");
  //   if (
  //     storageToken != "" &&
  //     localStorage.getItem("realizableLoginStatus") ===
  //       UserLoginStatusType.LOGGEDIN
  //   ) {
  //     console.log("here3");
  //     console.log(userStatus.loginStatusType);
  //     setUserStatus({
  //       loginStatusType: localStorage.getItem("realizableLoginStatus"),
  //       loginStatusMsg: localStorage.getItem("realizableLoginStatusMsg"),
  //       userEmail: localStorage.getItem("realizableUserEmail"),
  //       userPhoneNumber: localStorage.getItem("realizableUserPhoneNumber"),
  //       userName: localStorage.getItem("realizableUserName"),
  //       userType: localStorage.getItem("realizableUserType"),
  //     });
  //   }
  // }, [userStatus]);

  const isNotManager =
    localStorage.getItem("realizableUserType") === "관리인" ? false : true;

  const goRentalBookPage = () => {
    if (
      localStorage.getItem("realizableLoginStatus") ===
      UserLoginStatusType.LOGGEDIN
    ) {
      navigate("/rental_book");
    } else {
      navigate("/rental_book");
      // alert("[공장장]\n회원 전용 서비스입니다.");
    }
  };
  const [lawRequestModal, setLawRequestModal] =
    useRecoilState(LawRequestModalState);
  const lawInquery = () => {
    if (
      localStorage.getItem("realizableLoginStatus") ===
      UserLoginStatusType.LOGGEDIN
    ) {
      //Open Law Inquery Modal
      setLawRequestModal({
        show: true,
      });
    } else {
      alert("[공장장]\n로그인이 필요한 서비스입니다.");
      setLoginModal({
        show: true,
        onPress: () => {},
      });
    }
  };

  const goManagerPage = () => {
    navigate("/manager");
  };

  const goRentalBookManagerPage = () => {
    navigate("/rental_book_manager");
  };

  const goElecInfoPage = () => {
    navigate("/elec_info");
  };

  const goMainPage = () => {
    navigate("/");
  };

  const openSideBarMenu = () => {
    setSideMenuBar({
      show: true,
    });
  };

  const goFacilityPage = () => {
    navigate("/facility");
  };

  const openLoginModal = () => {
    setLoginModal({
      show: true,
      onPress: () => {},
    });
  };

  return (
    <header>
      <WrapperContentHeader>
        <HeaderContainer>
          <LeftContainer>
            <LeftHeaderItem onClick={goMainPage}>
              <MainLogoImg src={logoGreen}></MainLogoImg>
            </LeftHeaderItem>
            <LeftHeaderSubText>
              {" "}
              <h1 style={{ fontSize: "16px" }}>
                임대공장을 위한 구독형 관리사무소{" "}
              </h1>
            </LeftHeaderSubText>
          </LeftContainer>
          <RightContainer>
            {isNotManager && (
              <RightHeaderItem onClick={goRentalBookPage}>
                {" "}
                임대장부{" "}
              </RightHeaderItem>
            )}
            {localStorage.getItem("realizableUserType") === "관리인" && (
              <RightHeaderItem onClick={goRentalBookManagerPage}>
                {" "}
                임대장부
              </RightHeaderItem>
            )}
            {/* <RightHeaderItem onClick={goRegisterPage}>
              {" "}
              커뮤니티{" "}
            </RightHeaderItem> */}
            <RightHeaderItem onClick={goFacilityPage}>
              {" "}
              시설관리{" "}
            </RightHeaderItem>
            <RightHeaderItem onClick={goElecInfoPage}>
              {" "}
              전력 정보{" "}
            </RightHeaderItem>
            <RightHeaderItem onClick={lawInquery}> 법률 문의 </RightHeaderItem>
            {localStorage.getItem("realizableUserType") === "관리인" && (
              <RightHeaderItem onClick={goManagerPage}>
                {" "}
                관리자{" "}
              </RightHeaderItem>
            )}
            {(userStatus.loginStatusType === UserLoginStatusType.LOGGEDOUT ||
              localStorage.getItem("realizableLoginStatus") === null) && (
              <MainBtn
                bgrColor={factoryColor.mainGreen}
                fontColor={factoryColor.white}
                onClick={openLoginModal}
              >
                시작하기 {">"}
              </MainBtn>
            )}
            {userStatus.loginStatusType === UserLoginStatusType.LOADING && (
              <MainBtn
                bgrColor={factoryColor.mainGreen}
                fontColor={factoryColor.white}
              >
                로그인 중..
              </MainBtn>
            )}
            {userStatus.loginStatusType === UserLoginStatusType.LOGGEDIN && (
              <>
                <RightHeaderItem onClick={goLogout}> 로그아웃 </RightHeaderItem>
                <MyPageBtnCtnr onClick={goMyPage}>
                  {localStorage.getItem("realizableUserName")?.toString()[0]}
                </MyPageBtnCtnr>
              </>
            )}
          </RightContainer>
          <SideBarMenuContainer onClick={openSideBarMenu}>
            <MenuImg src={menuIcon}></MenuImg>
          </SideBarMenuContainer>
        </HeaderContainer>
      </WrapperContentHeader>
    </header>
  );
};

export const WrapperContentHeader = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 70px;

  font-family: ${myFont.regular};

  background-color: ${factoryColor.bg_secondary};
  box-sizing: border-box;
  color: ${factoryColor.textPrimary};

  font-size: 15.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.31px;
  box-shadow: 0px 3px 2.8px 0.2px rgba(0, 0, 0, 0.1);

  @media (max-width: ${Pixel.mobileWidth}) {
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    box-shadow: 0px 2px 1.4px 0.1px rgba(0, 0, 0, 0.1);
    font-weight: 400;
    line-height: 0.8;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: center;

  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};

  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 35%;
  box-sizing: border-box;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: 100%;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 100%;
    justify-content: center;
    min-width: 100px;
  }
`;

export const MainLogoImg = styled.img`
  width: 73.72px;
  height: 40px;

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 49.15px;
    height: 26.67px;
  }
`;

export const MenuImg = styled.img`
  width: 30px;
  height: auto;
  :hover {
    transform: rotate(180deg);
  }
`;

export const RightContainer = styled.div`
  border: ${Border.zeroPxBlack};

  display: flex;
  flex-direction: row;
  ${CommonType.justifyContenEnd}
  ${CommonType.alignItemsEnd}
  gap: 15px;
  width: 65%;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: ${Pixel.desktopWidth}) {
    display: none;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    display: none;
  }
`;

export const SideBarMenuContainer = styled.div`
  display: none;
  position: relative;
  top: -2px;
  ${CommonType.alignSelfEnd}

  width: 10%;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: ${Pixel.desktopWidth}) {
    display: block;
  }
`;
export const LeftHeaderItem = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  display: inline-flex;
`;

export const LeftHeaderSubText = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  font-family: ${myFont.regular};
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: ${factoryColor.mainGreen};

  @media (max-width: ${Pixel.mobileWidth}) {
    display: none;
  }
`;

const MyPageBtnCtnr = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  padding: 8px;
  border-radius: 40px;
  background-color: #2ea566;
  width: 35px;
  height: 35px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const RightHeaderItem = styled.div`
  border: ${Border.zeroPxBlack};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-weight: 500;
  font-size: 15px;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }

  @media (max-width: 700px) {
    font-size: 2.2vw;
    margin-left: 15px;
  }

  @media (max-width: 500px) {
    font-size: 3.5vw;
    margin-left: 10px;
  }
`;

export const PointContainer = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  border-radius: 14.2px;
  border: solid 2px #d1d1d6;
  background-color: #fff;
  margin: 0 0 0 19px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  min-width: 76px;
  align-items: center;

  @media (max-width: 500px) {
    min-width: 15px;
    height: 20px;
    border: solid 1px #d1d1d6;
    padding-left: 2px;
    padding-right: 2px;
    margin: 0 0 0 0;
  }
`;

export const CoinImg = styled.img`
  width: 26px;
  height: 16px;
  margin-right: 12px;
  object-fit: contain;
  @media (max-width: 500px) {
    margin-right: 3px;
    height: 12px;
  }
`;

interface BtnProp {
  bgrColor: string;
  fontColor: string;
}

export const MainBtn = styled.button<BtnProp>`
  font-family: "Pretandard-Regular";
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  width: 100px;
  height: 30px;

  background-color: ${({ bgrColor }) => bgrColor};
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
  color: ${({ fontColor }) => fontColor};
  opacity: 1;
  margin-right: 50px;

  &:hover {
    opacity: 0.65;
  }
  border-radius: 30px;

  @media (max-width: ${Pixel.desktopWidth}) {
    margin-right: 0px;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    display: none;
  }
`;
