import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AgreementPage } from "./AgreementPage";
import { SubmitPage } from "./SubmitPage";
import { factoryColor } from "../../constants/Colors";
import rightPathImg from "../../asset/Path.png";
import step1Active from "../../asset/reg_step1_active.png";
import step2deactive from "../../asset/reg_step2_deactive.png";
import step2Active from "../../asset/reg_step2_active.png";
import step3deactive from "../../asset/reg_step3_deactive.png";
import step3Active from "../../asset/reg_step3_active.png";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { myFont } from "../../constants/Fonts";
import {
  JustifyType,
  alignItemType,
  textAlign,
  CommonType,
  alignContentType,
} from "../../constants/Styles";

import { useState } from "react";
import { ValidationPage } from "./ValidationPage";

export interface userJoin {
  phone_number: string;
  pw: string;
  email: string;
  user_name: string;
  user_type: string;
}

export interface RegformProp {
  setStep(step: number): void;
  setFormData(form: any): void;
  formData: userJoin;
}

export const StartRegisterScreen = () => {
  const [step, setStep] = useState(1);
  let navigate = useNavigate();
  const [formData, setFormData] = useState<userJoin>({
    phone_number: "",
    pw: "",
    email: "",
    user_name: "",
    user_type: "",
  });

  const goRegisterCompletePage = () => {
    navigate("/register_complete");
  };

  const stepSwitcher = () => {
    switch (step) {
      case 1:
        return (
          <AgreementPage
            setStep={setStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
        case 2:
          return (
            <ValidationPage
              setStep={setStep}
              setFormData={setFormData}
              formData={formData}
            />
          );
        case 3:
          return (
            <SubmitPage
              setStep={setStep}
              setFormData={setFormData}
              formData={formData}
            />
          );
      case 4:
        goRegisterCompletePage();
        return null;
      default:
        return null;
    }
  };

  return (
    <RegisterBackground>
      <StepHeadCtnr>
        <StepImg src={step1Active} active={true} />
        <StepTitle active={step == 1}>이용약관 동의</StepTitle>
        <NextImg src={rightPathImg} />
        <StepImg src={step2Active} active={step > 1} />
        <StepImg src={step2deactive} active={step < 2} />
        <StepTitle active={step > 1}>본인 인증</StepTitle>
        <NextImg src={rightPathImg} />
        <StepImg src={step3Active} active={step > 2} />
        <StepImg src={step3deactive} active={step < 3} />
        <StepTitle active={step > 2}>기본 정보 입력</StepTitle>
      </StepHeadCtnr>
      <RegisterInner>{stepSwitcher()}</RegisterInner>
    </RegisterBackground>
  );
};

const RegisterBackground = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;

  font-family: ${myFont.regular};
  font-weight: 500;

  flex: 1;
  ${CommonType.displayColumnFlex};
  ${CommonType.alignItemsCenter};
  align-content: ${alignContentType.center};

  box-sizing: border-box;
  background-color: ${factoryColor.bg_secondary};
  @media (max-width: 500px) {
    top: 0px;
  }

  @media (max-height: 800px) {
    height: 900px;
  }
`;

const StepHeadCtnr = styled.div`
  ${CommonType.displayRowFlex};
  ${CommonType.justifyContentCenter};
  ${CommonType.alignItemsCenter};
  margin-top: 25px;
  margin-bottom: 25px;

  @media (max-width: ${Pixel.desktopWidth}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const RegisterInner = styled.div`
  ${CommonType.displayColumnFlex};
  margin-bottom: 25px;
  width: 702px;
  border-radius: 14px;
  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding-bottom: 25px;
  padding-top: 25px;

  @media (max-width: ${Pixel.desktopWidth}) {
    ${CommonType.displayColumnFlex};
    width: 90%;
  }
  @media (max-width: ${Pixel.mobileWidth}) {
    width: 90%;
  }
`;

interface divProp {
  active?: boolean;
}
const StepTitle = styled.div<divProp>`
  font-weight: ${({ active }) => (active ? "700" : "400")};
`;

const StepImg = styled.img<divProp>`
  display: ${({ active }) => (active ? "" : "none")};
  width: 25x;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 17px;
    height: 17px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;

const NextImg = styled.img`
  width: 20x;
  padding-left: 10px;
  padding-right: 10px;
  height: 20px;

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 10px;
    height: 10px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;
