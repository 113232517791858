import styled from "styled-components";
import { factory } from "typescript";
import { factoryColor } from "../../constants/Colors";
import { myFont } from "../../constants/Fonts";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import * as DOMPurify from "dompurify";
import { useEffect } from "react";

export const Footer = () => {
  const goMainPage = () => {
    console.log("Goto Main Page");
  };
  const goRegisterPage = () => {
    console.log("Goto Register Page");
  };

  const naverAdCommonHTML1 = `<script type="text/javascript" src="//wcs.naver.net/wcslog.js"> </script>`;
  const naverAdCommonHTML2 = `<script type="text/javascript">if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_2742ead514dd";if (!_nasa) var _nasa={};if(window.wcs){wcs.inflow();wcs_do(_nasa);}</script>`;
  const naverAdCommonHTMLSingle = `<script type="text/javascript" src="//wcs.naver.net/wcslog.js"> </script><script type="text/javascript">if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_2742ead514dd";if (!_nasa) var _nasa={};if(window.wcs){wcs.inflow();wcs_do(_nasa);}</script>`;
  // const script = document.createElement("script");
  // script.async = true;
  // document.body.append(script);
  // script.textContent = `if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_2742ead514dd";if (!_nasa) var _nasa={};if(window.wcs){wcs.inflow();wcs_do(_nasa);}`;
  // document.body.append(script);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "//wcs.naver.net/wcslog.js";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   const script2 = document.createElement("script");
  //   script2.textContent = `if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_2742ead514dd";if (!_nasa) var _nasa={};if(window.wcs){wcs.inflow();wcs_do(_nasa);}`;
  //   script.async = true;
  //   document.body.appendChild(script2);

  //   return () => {
  //     document.body.removeChild(script);
  //     document.body.removeChild(script2);
  //   };
  // }, []);

  return (
    <Wrapper>
      <FooterContainer>
        <LeftContainer>
          <LeftFooterCompanyName>realizable</LeftFooterCompanyName>
          <LeftFooterCompanyDesc>
            리얼라이저블 주식회사 | 대표 원동명 | 사업자 등록번호 578-86-02558
          </LeftFooterCompanyDesc>
          <LeftFooterCompanyDesc>
            +82-32-821-9968 | 인천 남동구 은청로 36, 108호 (고잔동) |
            info@realizable.kr
          </LeftFooterCompanyDesc>
        </LeftContainer>
        <RightContainer>
          <RightFooterCopyright>
            Copyright 2023 © realizable Inc. All Rights Reserved
          </RightFooterCopyright>
          <RightFooterTermCtnr>
            <FooterP>
              사이트맵 |
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: "#7F8A9B", textDecoration: "none" }}
                href="https://blog.naver.com/realizable-kr"
              >
                {" "}네이버 블로그,{" "}
              </a>{" "}
              {" "}
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: "#7F8A9B", textDecoration: "none" }}
                href="https://www.facebook.com/profile.php?id=100092397137600"
              >
                페이스북
              </a>
            </FooterP>
          </RightFooterTermCtnr>
          {/* <div
            className="NaverCommonHtml1"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(naverAdCommonHTMLSingle, {
                FORCE_BODY: true,
                ADD_TAGS: ["script"],
              }),
              // __html: naverAdCommonHTML1,
            }}
          /> */}
          {/* <div
            className="NaverCommonHtml2"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(naverAdCommonHTML2, {
                FORCE_BODY: true,
                ADD_TAGS: ["script"],
              }),
            }}
          /> */}
        </RightContainer>
      </FooterContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 10;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  height: 120px;

  font-family: ${myFont.regular};

  background-color: ${factoryColor.white};
  box-sizing: border-box;
  color: ${factoryColor.textPrimary};

  font-size: 15.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.31px;
  box-shadow: 0px 3px 2.8px 0.2px rgba(0, 0, 0, 0.1);

  @media (max-width: ${Pixel.desktopWidth}) {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    box-shadow: 0px 2px 1.4px 0.1px rgba(0, 0, 0, 0.1);
    font-weight: 400;
    line-height: 0.8;
  }
`;

const LeftContainer = styled.div`
  border: ${Border.zeroPxBlack};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  min-width: 220px;
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};

  @media (max-width: ${Pixel.desktopWidth}) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  border: ${Border.zeroPxBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  min-width: 220px;
  box-sizing: border-box;

  @media (max-width: 901px) {
    width: 400px;
  }

  @media (max-width: ${Pixel.desktopWidth}) {
    align-items: flex-start;
    padding-top: 15px;
    width: 100%;

`;

const LeftFooterCompanyName = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  margin-bottom: 15px;

  font-family: ${myFont.regular};
  font-size: 16px;
  font-weight: 800;
  color: ${factoryColor.textPrimary};
`;

const LeftFooterCompanyDesc = styled.div`
  box-sizing: border-box;
  display: inline-flex;

  font-family: ${myFont.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: ${factoryColor.descriptionSecondary};
`;

const RightFooterCopyright = styled.div`
  box-sizing: border-box;
  display: flex;

  font-family: ${myFont.regular};
  font-size: 16px;
  font-weight: 400;
  color: ${factoryColor.descriptionSecondary};
  border: ${Border.zeroPxBlue};
`;

const RightFooterTermCtnr = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: ${Border.zeroPxRed};
  height: 30px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (max-width: ${Pixel.desktopWidth}) {
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    width: ${Percent.tabletHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

export const FooterP = styled.p`
  font-family: ${myFont.regular};
  font-weight: 700;
  font-size: 15px;
  color: ${factoryColor.descriptionSecondary};
`;
