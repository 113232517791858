import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  AddFactoryModalState,
  AddNewTenantModalState,
  GlobalLoadingState,
  ModifyChungGuModalState,
} from "../../store/ModalStores";
import { alignItemType, CommonType, JustifyType } from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import closeIcon from "../../asset/close-circle.png";

export type NoticeModalProps = {};

export const AddNewTenantModal = () => {
  const [thisModal, setThisModal] = useRecoilState(AddNewTenantModalState);
  const resetThisModal = useResetRecoilState(AddNewTenantModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const [factoryID, setFactoryID] = useState(thisModal.factory_id);
  const [lookupMonth, setLookupMonth] = useState(thisModal.lookup_month);
  const [lookupYear, setLookupYear] = useState(thisModal.lookup_year);
  const [tenantName, setTenantName] = useState(thisModal.tenant_name);
  const [tenantCompanyName, setTenantCompanyName] = useState(
    thisModal.tenant_company_name
  );
  const [tenantPhoneNumber, setTenantPhoneNumber] = useState(
    thisModal.tenant_phone_number
  );

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  useEffect(() => {
    setFactoryID(thisModal.factory_id);
    setLookupMonth(thisModal.lookup_month);
    setLookupYear(thisModal.lookup_year);
    setTenantName(thisModal.tenant_name);
    setTenantCompanyName(thisModal.tenant_company_name);
    setTenantPhoneNumber(thisModal.tenant_phone_number);
  }, [thisModal]);

  const sendRequest = () => {
    if (factoryID < 0) {
      alert("관리 공장을 찾을 수 없습니다.");
    } else if (lookupMonth < 0) {
      alert("유효하지 않은 월 입니다.");
    } else if (lookupYear < 0) {
      alert("유효하지 않은 연도 입니다.");
    } else if (tenantName.length < 0) {
      alert("사용자 이름을 1자 이상 입력해주세요.");
    } else if (tenantCompanyName.length < 0) {
      alert("사용자 회사명을 1자 이상 입력해주세요.");
    } else if (tenantPhoneNumber.length < 0) {
      alert("사용자 전화번호를 1자 이상 입력해주세요.");
    } else {
      setLoadingModal({
        isLoading: true,
        message: "신규 임차인을 추가하고 있습니다.",
      });
      _client
        .postApiWithToken("/rental_book_for_manager/tenant/add", {
          factory_id: factoryID,
          lookup_month: lookupMonth,
          lookup_year: lookupYear,
          tenant_name: tenantName,
          tenant_company_name: tenantCompanyName,
          tenant_phone_number: tenantPhoneNumber,
        })
        .then((response) => {
          resetLoadingModal();
          alert(response.msg);
          resetThisModal();
        })
        .catch((err) => {
          resetLoadingModal();
          alert(err);
        });
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ItemCtnr>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  [신규 사용자 추가 ({lookupYear}/{lookupMonth})]
                </div>
                <ItemTitle>사용자 이름 (대표 성함)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={tenantName}
                    onChange={(e) => setTenantName(e.target.value)}
                    disabled={false}
                    placeholder="임차인 이름을 입력하세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  사용자 회사명
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={tenantCompanyName}
                    onChange={(e) => setTenantCompanyName(e.target.value)}
                    disabled={false}
                    placeholder="사용자 회사명을 입력하세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>사용자 휴대폰 번호</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={tenantPhoneNumber}
                    onChange={(e) => setTenantPhoneNumber(e.target.value)}
                    disabled={false}
                    placeholder="사용자 휴대폰 번호 (숫자만)"
                  />
                </ItemInputCtnr>
             </ItemCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => sendRequest()}>
                  추가하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "300px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
`;

const ItemCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const ItemTitle = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  margin-top: 20px;
`;

const ItemInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;
