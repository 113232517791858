import styled from "styled-components";
import { factoryColor } from "../../constants/Colors";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { myFont } from "../../constants/Fonts";
import {
  alignA,
  alignB,
  GlobalRowFlex,
  textAlign,
} from "../../constants/Styles";
import mainBgr from "../../asset/landing-bgr.png";
import review1 from "../../asset/factory_review_01.jpeg";
import review2 from "../../asset/factory_review_02.jpeg";
import review3 from "../../asset/factory_review_06.jpeg";
import review4 from "../../asset/factory_review_04.jpeg";
import review5 from "../../asset/factory_review_05.jpeg";
import review6 from "../../asset/factory_review_03.jpeg";
import doubleQuote from "../../asset/double_quote.png";
import { reviewData } from "../../constants/MockData";
import { isPropertySignature } from "typescript";

const imageUrlList = [
  mainBgr,
  review1,
  review2,
  review3,
  review4,
  review5,
  review6
]

export const ReviewCarousel = () => {
  const curBorder = false;
  return (
    <MainCtnr>
      <AlignCtnr>
        <LocalRowFlex border={curBorder}>
          {reviewData.map((item) => (
            <LocalColFlex
              key={item.id}
              alignA={alignA.fromStart}
              height={"403px"}
              width={"312px"}
              marginHorizontal="15px"
            >
              <CardImg url={imageUrlList[item.id]} />
              <GlobalRowFlex
                height="20%"
                width="100%"
                border={curBorder}
                alignA={alignA.spaceBewteen}
                paddingHorizontal="15px"
              >
                <GlobalRowFlex width="80%" alignA={alignA.fromStart}>
                  <NamePart>{item.name}</NamePart>
                </GlobalRowFlex>
                <Icon src={doubleQuote} />
              </GlobalRowFlex>
              <GlobalRowFlex
                height="30%"
                width="100%"
                border={curBorder}
                alignA={alignA.fromStart}
                paddingHorizontal="15px"
              >
                <WrapFlex>{item.content}</WrapFlex>
              </GlobalRowFlex>
            </LocalColFlex>
          ))}
        </LocalRowFlex>
      </AlignCtnr>
    </MainCtnr>
  );
};

export const Icon = styled.img`
  width: 20px;
`;

const MainCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  padding-bottom: 110px;
  padding-top: 35px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AlignCtnr = styled.div`
  box-sizing: border-box;
  width: ${Pixel.desktopWidthIncludePadding};

  overflow-x: scroll;
  overflow-y: hidden;

  display: flex;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: 100vw;
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
    ::-webkit-scrollbar{
      display: none;
      transform: translateY(-10px);
      width: 24px;
    }
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 100vw;
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
    ::-webkit-scrollbar{
      display: none;
      transform: translateY(-10px);
      width: 24px;
    }
  }
`;

interface Props {
  border?: boolean;
  height?: string;
  width?: string;
  alignA?: string;
  alignB?: string;
  textAlign?: string;
  paddingVertical?: string;
  paddingHorizontal?: string;
  marginVertical?: string;
  marginHorizontal?: string;
}

const LocalRowFlex = styled.div<Props>`
  display: flex;
  flex-direction: row;
`;

const LocalColFlex = styled.div<Props>`
  display: flex;
  border-radius: 10px;
  border: 1px solid ${factoryColor.border_secondary};
  flex-direction: column;
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  justify-content: ${(props) => props.alignA || alignA.center};
  align-items: ${(props) => props.alignB || alignB.center};
  text-align: ${(props) => props.textAlign || textAlign.center};
  box-sizing: border-box;
  margin-top: ${(props) => props.marginVertical || "0px"};
  margin-bottom: ${(props) => props.marginVertical || "0px"};
  margin-left: ${(props) => props.marginHorizontal || "0px"};
  margin-right: ${(props) => props.marginHorizontal || "0px"};
`;


interface IndexProp {
  url: string;
}
const CardImg = styled.div<IndexProp>`
  background-color: #ffffff;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    url(${({url}) => url});
  background-repeat: no-repeat;
  background-size: initial;
  height: 40%;
  width: 100%;
  border-radius: 10px;
`;

const NamePart = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;

  font-size: 18px;
  font-weight: 700;
  color: ${factoryColor.mainGreen};

  box-sizing: border-box;
`;

const WrapFlex = styled.div`
  overflow-wrap: break-word;
  word-break: break-all;

  align-items: flex-start;
  font-family: ${myFont.regular};
  text-align: ${textAlign.start};
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: ${factoryColor.descriptionSecondary};
  box-sizing: border-box;
`;
