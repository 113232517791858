import { useEffect, useState } from "react";
import styled from "styled-components";
import { RegformProp } from "./StartRegister";
import {
  Statement,
  DescText,
  PhoneNumberInputContainer,
  PhoneNumberInput,
  OuterDiv,
  ContentCtnr,
} from "./ValidationPage";
import { factoryColor } from "../../constants/Colors";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { Path, _client, PhoneAuthType } from "../../apis/client";
import { CommonType } from "../../constants/Styles";

let userTypeOptions: Array<string> = ["일반 관리자", "리얼라이저블 관리자"];

export const SubmitPage = (prop: RegformProp) => {
  const { setFormData, formData, setStep } = prop;
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [userType, setUserType] = useState<string>("일반 관리자");

  const emailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const usernameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value);
  };

  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const passwordConfirmHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(e.currentTarget.value);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      email: email,
      pw: password,
      user_name: username,
      user_type: userType,
    });
  }, [email, password, passwordConfirm, username, userType]);

  const join = () => {
    console.log("가입 시도");
    if (password !== passwordConfirm) {
      alert("비밀번호가 일치하지 않습니다");
    } else if (password.toString().length < 4) {
      alert("비밀번호가 너무 짧습니다.");
    } else {
      _client
        .postApiV2(Path.join("web"), formData)
        .then(() => {
          console.log("가입 성공");
          setStep(4);
        })
        .catch((err) => {
          if (err.status === 422) {
            alert("이메일 주소의 형식이 잘못되었습니다.");
          } else {
            console.log(err);
            if (err.data.error) {
              alert("가입에 실패하였습니다: " + err.data.error);
            } else {
              alert("가입에 실패하였습니다: " + err.statusText);
            }
          }
        });
    }
  };
  return (
    <>
      <OuterDiv>
        <ContentCtnr>
          <Statement>
            {"  "}휴대폰번호 인증{" "}
            <DescText>(휴대폰 번호가 로그인을 위한 ID로 사용됩니다.)</DescText>
          </Statement>
          {/* <PhoneNumberInputContainer active={true} finished={verifySuccess}> */}
          <PhoneNumberInputContainer active={true} finished={true}>
            <PhoneNumberInput
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              value={formData.phone_number}
              disabled={false}
              placeholder="본인의 휴대폰 번호를 입력하세요 (숫자만 입력)"
            />
            {/* <DisabledBtn className="VerifyBtn" disabled={true}>
            {" "}
            전송하기{" "}
          </DisabledBtn> */}
          </PhoneNumberInputContainer>
          <SubInfo>* 인증이 정상적으로 완료되었습니다.</SubInfo>
          <FormStatement className="passwordForm">
            <RedStar> *</RedStar> 비밀번호
          </FormStatement>
          <InputContainer>
            <Input
              type="password"
              value={password}
              onChange={(e) => passwordHandler(e)}
              placeholder="비밀번호(8자리 이상)를 입력해주세요"
            />
          </InputContainer>
          <FormStatement className="passwordConfirmForm">
            <RedStar> *</RedStar> 비밀번호 확인
          </FormStatement>
          <InputContainer>
            <Input
              type="password"
              value={passwordConfirm}
              onChange={(e) => passwordConfirmHandler(e)}
              placeholder="비밀번호(8자리 이상)를 입력해주세요"
            />
          </InputContainer>
          <FormStatement className="userForm">
            <RedStar> *</RedStar> 사용자 이름
          </FormStatement>
          <InputContainer>
            <Input
              type="text"
              value={username}
              onChange={(e) => usernameHandler(e)}
              placeholder="사용자 이름을 입력해주세요"
            />
          </InputContainer>
          <FormStatement className="userForm">
            <RedStar> *</RedStar> 사용자 유형
          </FormStatement>
          <InputContainer>
            <UserTypeOption
              fontSize={"1vw"}
              mobileFontSize={"3vw"}
              fontWeight={"500"}
              value={userType}
              onChange={(event) => {
                setUserType(event.target.value);
              }}
            >
              {userTypeOptions.map((element) => {
                return <option key={element}>{element}</option>;
              })}
            </UserTypeOption>
          </InputContainer>
          <FormStatement className="emailForm">
            <RedStar> *</RedStar> 이메일 주소{" "}
          </FormStatement>
          <InputContainer>
            <Input
              type="text"
              value={email}
              onChange={(e) => emailHandler(e)}
              placeholder="이메일 주소를 입력해주세요"
            />
          </InputContainer>
          <ConfirmBtnContainer>
            <ConfirmBtn className="btn" onClick={() => join()}>
              가입 완료하기
            </ConfirmBtn>
          </ConfirmBtnContainer>
        </ContentCtnr>
      </OuterDiv>
    </>
  );
};

const SubInfo = styled.h1`
  font-size: 12px;
  padding-top: 10px;
  padding-left: 5px;
  font-weight: 550;
  height: 12px
  line-height: 1.4;
  color: ${factoryColor.mainGreen};

`;

export const FormStatement = styled.h1`
  font-size: 15px;
  padding-top: 10px;
  padding-left: 5px;
  font-weight: 550;
  line-height: 1.4;
  color: #48484a;
  height: 14px;
`;

export const RedStar = styled.span`
  color: red;
`;

export interface active {
  active?: boolean;
}

export const InputContainer = styled.div<active>`
  height: 30px;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 3px;
  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: ${(props) => (props.active ? "#f4f4f5" : "#fff")};
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;
export const ConfirmBtnContainer = styled.div`
  display: "flex";
  width: 100%;
  flex-direction: column;
  height: 40px;
  border-radius: 5px;

  background-color: ${factoryColor.mainGreen};
  margin-top: 30px;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    background-color: rgba(0, 162, 51, 0.9);
  }
`;

export const ConfirmBtn = styled.button`
  width: 100%;
  height: 100%;

  padding-top: 5px;
  padding-bottom: 5px;

  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #008128;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

export const UserTypeOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  height: 100%;
  width: 100%;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;
