import { useState } from "react";
import styled from "styled-components";
import { RegformProp } from "./StartRegister";
import { factoryColor } from "../../constants/Colors";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { Path, _client, PhoneAuthType } from "../../apis/client";
import { CommonType } from "../../constants/Styles";

export const ValidationPage = (prop: RegformProp) => {
  const { setStep, setFormData, formData } = prop;
  const [phone, setPhone] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [codeHasBeenSent, setCodeHasBeenSent] = useState(false);

  const [verifyCode, setVerifyCode] = useState("");
  const [validVerifyCode, setValidVerifyCode] = useState(false);
  const [verifyHasBeenSent, setVerifyHasBeenSent] = useState(false);

  const [isButtonActive, setIsButtonActive] = useState(false);

  const onChangePhoneNumber = (e: string) => {
    setPhone(e);
  };

  const onChangeVerifiyCode = (e: string) => {
    setVerifyCode(e);
  };

  const clickHandlerCreate = () => {
    console.log("clickHandlerCreate()");
    // let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    let regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    setValidPhoneNumber(regExp.test(phone));
    console.log(`핸드폰번호 유효성 검사 : ${regExp.test(phone)}`);

    setCodeHasBeenSent(true);

    if (regExp.test(phone)) {
      console.log("msg request has been sent");
        _client
          .postApi(
            Path.phone_auth(PhoneAuthType.CREATE, {
              phone: phone,
              verify_code: undefined,
            })
          )
          .catch((e) => {
            setIsButtonActive(false);
            alert(`다음과 같은 이유로 인증에 실패하였습니다: ${e}`);
          })
          .then(() => setIsButtonActive(true));
    }
  };

  const clickHandlerVerfiy = () => {
    let regExp = /^\d{4}$/;
    setValidVerifyCode(regExp.test(verifyCode));
    console.log(`핸드폰 인증번호 4자리 : ${regExp.test(verifyCode)}`);

    _client
      .postApi(
        Path.phone_auth(PhoneAuthType.VERIFY, {
          phone: phone,
          verify_code: verifyCode,
        })
      )
      .then(() => {
        setFormData({ ...formData, phone_number: phone });
        setStep(3);
      })
      .catch((e) => {
        setVerifyHasBeenSent(true);
        console.error(e);
      });
  };

  return (
    <>
      <OuterDiv>
        <MainDescription>
          <MainDescTitle>공장장에 오신 것을 환영합니다.</MainDescTitle>
          <MainDescSubtitle>
            회원 서비스 이용을 위해 가입해주세요.
          </MainDescSubtitle>
        </MainDescription>
        <ContentCtnr>
          <Statement>
            {" "}
            휴대폰 번호 인증{" "}
            <DescText>(휴대폰 번호가 로그인을 위한 ID로 사용됩니다.)</DescText>
          </Statement>
          <PhoneNumberInputContainer active={true} finished={false}>
            <PhoneNumberInput
              type="number"
              value={phone}
              pattern="[0-9]*"
              inputMode="numeric"
              disabled={false}
              onChange={(e) => onChangePhoneNumber(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  clickHandlerCreate();
                }
              }}
              placeholder="본인의 휴대폰 번호를 입력하세요"
            />
            <VerifyBtn
              className="VerifyBtn"
              disabled={false}
              //   disabled={!validPhoneNumber || isButtonActive}
              onClick={() => clickHandlerCreate()}
            >
              {" "}
              인증하기{" "}
            </VerifyBtn>
          </PhoneNumberInputContainer>
          <Comment active={codeHasBeenSent && validPhoneNumber}>
            {" "}
            * 해당 휴대폰번호로 인증번호를 전송했습니다. 문자를 확인해주시기
            바랍니다.{" "}
          </Comment>
          <FailComment active={codeHasBeenSent && !validPhoneNumber}>
            {" "}
            * 올바른 휴대폰 번호를 입력해주세요.{" "}
          </FailComment>
          <PhoneNumberInputContainer active={isButtonActive} finished={false}>
            <PhoneNumberInput
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              value={verifyCode}
              disabled={false}
              onChange={(e) => onChangeVerifiyCode(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  clickHandlerVerfiy();
                }
              }}
              placeholder="인증 코드"
            />
            <ConfirmBtn
              className="btn"
              disabled={!isButtonActive}
              onClick={() => clickHandlerVerfiy()}
            >
              {" "}
              확인{" "}
            </ConfirmBtn>
          </PhoneNumberInputContainer>
          {/* <Comment active={codeHasBeenSent && validVerifyCode}>
        {" "}
        * 인증이 정상적으로 완료되었습니다.
      </Comment> */}
          <FailComment active={verifyHasBeenSent}>
            {" "}
            * 인증이 실패하였습니다. 다시 시도해주세요.{" "}
          </FailComment>

          {/* <NextBtnContainer>
            <NextBtn onClick={() => setStep(3)}> 다음으로 이동 </NextBtn>
          </NextBtnContainer> */}
        </ContentCtnr>
      </OuterDiv>
    </>
  );
};

export const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.justifyContenStart}
  ${CommonType.fontFamily}
  ${CommonType.fontSize20}
  ${CommonType.fontWeight500}
  ${CommonType.paddingBottom10}
  background-color: white;
`;

export const MainDescription = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 100px;
  background-color: ${factoryColor.bg_primary};
`;

export const MainDescTitle = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContenEnd}
  ${CommonType.alignSelfStart}
  ${CommonType.fontWeight700}
  margin-left: 30px;
  margin-right: 30px;
`;

export const MainDescSubtitle = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContenEnd}
  ${CommonType.alignSelfStart}
  ${CommonType.fontWeight500}
  font-size: 15px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 12px;
  color: ${factoryColor.descriptionSecondary};
`;

export const ContentCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.justifyContentCenter}
  ${CommonType.alignItemsStart}
  height: auto;
  background-color: ${factoryColor.white};
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
`;

export const NextBtnContainer = styled.div`
  display: flex;
  height: 37px;
  margin-top: 30px;
  border-radius: 5px;
  background-color: ${factoryColor.mainGreen};
  width: 100%;

  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    background-color: rgba(0, 162, 51, 0.9);
  }
`;

export const NextBtn = styled.button`
  width: 100%;
  height: 100%;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${factoryColor.mainGreen};
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }
`;

export const Statement = styled.h1`
  font-size: 15px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  color: #48484a;
`;

export const DescText = styled.span`
  color: #757575;
`;

export interface activeProp {
  active?: boolean;
  finished?: boolean;
}

export const PhoneNumberInputContainer = styled.div<activeProp>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: row;
  height: 30px;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 3px;
  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: ${(props) => (props.finished ? "#f4f4f5" : "#fff")};
`;

export const PhoneNumberInput = styled.input`
  display: flex;
  width: 70%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 11px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

export const DisabledBtn = styled.button`
  display: flex;
  z-index: 200;
  width: 30%;
  height: 100%;
  font-size: 14px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;

  border-radius: 5px;
  background-color: #aeaeb2;
`;

export const VerifyBtn = styled.button`
  display: flex;
  z-index: 200;
  height: 100%;
  width: 20%;
  font-size: 14px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;

  border-radius: 5px;
  background-color: ${factoryColor.mainGreen};

  :hover {
    opacity: 0.9;
  }
  @media (max-width: 500px) {
    font-size: 11px;
    width: 30%;
  }
`;

export const ConfirmBtn = styled.button`
  display: flex;
  z-index: 200;
  width: 20%;
  height: 100%;
  font-size: 14px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;

  border-radius: 5px;
  background-color: ${factoryColor.mainGreen};

  :hover {
    opacity: 0.9;
  }

  @media (max-width: 500px) {
    font-size: 11px;
    width: 30%;
  }
`;

export const Comment = styled.h5<activeProp>`
  display: ${(props) => (props.active ? "" : "none")};
  font-size: 12px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  ${CommonType.fontFamily}
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.08px;
  text-align: left;
  color: ${factoryColor.mainGreen};
`;

export const FailComment = styled.h5<activeProp>`
  display: ${(props) => (props.active ? "" : "none")};
  font-size: 12px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  ${CommonType.fontFamily}
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.08px;
  text-align: left;
  color: red;
`;
