import styled from "styled-components";
import { factoryColor } from "./Colors";

// 자주 쓰는 컴포넌트 모음
export const CommonType = {
  displayFlex: "display: flex;",
  borderBox: "box-sizing: border-box;",
  flexColumnDirection: "flex-direction: column;",
  flexRowDirection: "flex-direction: row;",
  displayRowFlex: "display: flex; flex-direction: row; box-sizing: border-box;",
  displayColumnFlex: "display: flex; flex-direction: column; box-sizing: border-box;",

  // Fill Leftover space
  flexGrow: "display: flex; flex-grow: 1;",

  alignItemsCenter: "align-items: center;",
  alignItemsStart: "align-items: flex-start;",
  alignItemsEnd: "align-items: flex-end;",
  alignItemsStretch: "align-items: stretch;",
  alignItemsBasedOnText: "align-items: baseline;",

  alignSelfAuto: "align-self: auto;",
  alignSelfCenter: "align-self: center;",
  alignSelfStart: "align-self: flex-start;",
  alignSelfEnd: "align-self: flex-end;",
  alignSelfStretch: "align-self: stretch;",
  alignSelfBasedOnText: "align-self: baseline;",

  justifyContentCenter: "justify-content: center;",
  justifyContenStart: "justify-content: flex-start;",
  justifyContenEnd: "justify-content: flex-end;",
  justifyContentSpaceBetween: "justify-content: space-between;",
  justifyContentSpaceAround: "justify-content: space-around;",
  justifyContentSpaceEvenly: "justify-content: space-evenly;",

  textAlignStart: "text-align: start;",
  textAlignEnd: "text-align: end;",
  textAlignLeft: "text-align: left;",
  textAlignRight: "text-align: right;",
  textAlignCenter: "text-align: center;",
  textAlignJustify: "text-align: justify;",
  textAlignJustifyAll: "text-align: justify-all;",
  textAlignMatchParent: "text-align: match-parent;",

  
  fontFamily: "font-family: Pretandard-Regular;",
  fontSize20: "font-size: 20px;",
  fontWeight500: "font-weight: 500;",
  fontWeight700: "font-weight: 700;",

  paddingTop10: "padding-top: 10px;",
  paddingBottom10: "padding-bottom: 10px;",
  paddingLeft10: "padding-left: 10px;",
  paddingRight10: "padding-right: 10px;",

  marginTop10: "margin-top: 10px;",
  marginBottom10: "margin-bottom: 10px;",
  marginLeft10: "margin-left: 10px;",
  marginRight10: "margin-right: 10px;",

  paddingTop: "padding-top: ",
  paddingBottom: "padding-bottom: ",
  marginTop: "margin-top: ",
  marginBottom: "margin-bottom: ",

  testBorder: "border: 1px solid black;",
};

// 메인 축 방향으로 정렬 (direction = row: 좌/우, column: 위/아래)
export const JustifyType = {
  fromStart: "flex-start",
  fromEnd: "flex-end",
  center: "center",
  spaceBewteen: "space-between",
  spaceAround: "space-around",
  spaceEven: "space-evenly",
};

// 메인 축 수직 방향 정렬 (direction = row: 위/아래, column: 좌/우)
export const alignItemType = {
  stretch: "stretch",
  fromStart: "flex-start",
  fromEnd: "flex-end",
  center: "center",
  basedOnText: "baseline",
};

// 여러행 정렬
export const alignContentType = {
  stretch: "stretch",
  fromStart: "flex-start",
  fromEnd: "flex-end",
  center: "center",
  spaceBewteen: "space-between",
  spaceAround: "space-around",
  spaceEven: "space-evenly"
};

// 아이템 개별적인 정렬. Align-items 보다 우선권 높음
export const alignSelfType = {
  auto: "auto", // AlignItems 설정 상속
  stretch: "stretch", // 
  fromStart: "flex-start",
  fromEnd: "flex-end",
  center: "center",
  basedOnText: "baseline",
};

export const alignA = {
  fromStart: "flex-start",
  fromEnd: "flex-end",
  center: "center",
  spaceBewteen: "space-between",
  spaceAround: "space-around",
  spaceEven: "space-evenly",
};

export const alignB = {
  stretch: "stretch",
  fromStart: "flex-start",
  fromEnd: "flex-end",
  center: "center",
  basedOnText: "baseline",
};

export const textAlign = {
  start: "start",
  end: "end",
  center: "center",
  justify: "justify",
};

interface Props {
  border?: boolean;
  height?: string;
  width?: string;
  alignA?: string;
  alignB?: string;
  textAlign?: string;
  paddingVertical?: string;
  paddingHorizontal?: string;
  marginVertical?: string;
  marginHorizontal?: string;
}

export const GlobalRowFlex = styled.div<Props>`
  display: flex;
  flex-direction: row;
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  justify-content: ${(props) => props.alignA || alignA.center};
  align-items: ${(props) => props.alignB || alignB.center};
  text-align: ${(props) => props.textAlign || textAlign.center};
  box-sizing: border-box;
  border: ${(props) => (props.border ? "1px solid red" : "none")};
  padding-top: ${(props) => props.paddingVertical || "0px"};
  padding-bottom: ${(props) => props.paddingVertical || "0px"};
  padding-left: ${(props) => props.paddingHorizontal || "0px"};
  padding-right: ${(props) => props.paddingHorizontal || "0px"};
  margin-top: ${(props) => props.marginVertical || "0px"};
  margin-bottom: ${(props) => props.marginVertical || "0px"};
  margin-left: ${(props) => props.marginHorizontal || "0px"};
  margin-right: ${(props) => props.marginHorizontal || "0px"};
`;

export const GlobalColFlex = styled.div<Props>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  justify-content: ${(props) => props.alignA || alignA.center};
  align-items: ${(props) => props.alignB || alignB.center};
  text-align: ${(props) => props.textAlign || textAlign.center};
  box-sizing: border-box;
  border: ${(props) => (props.border ? "1px solid red" : "none")};
  padding-top: ${(props) => props.paddingVertical || "0px"};
  padding-bottom: ${(props) => props.paddingVertical || "0px"};
  padding-left: ${(props) => props.paddingHorizontal || "0px"};
  padding-right: ${(props) => props.paddingHorizontal || "0px"};
`;

export const GreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
`;

export const BlackSpan = styled.span`
  color: ${factoryColor.textPrimary};
`;
export const WhiteSpan = styled.span`
  color: ${factoryColor.white};
`;

export const GreenSpanUnderline = styled.span`
  color: white;
  font-weight: 700;
`;
  // text-decoration: underline;
