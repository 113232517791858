import styled from "styled-components";
import { factoryColor } from "../constants/Colors";
import refreshIcon from "../asset/refresh-icon.png";
import loadingGif from "../asset/ring-loading.gif";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { myFont } from "../constants/Fonts";
import { BtnCtnr, FlexCtnr } from "./FacilityScreen";
import {
  JustifyType,
  alignItemType,
  textAlign,
  CommonType,
  alignContentType,
} from "../constants/Styles";
import { RentalBookSelector } from "../components/Dropdown/RentalBookDropdown";
import { RentalBookAtMonth } from "../components/RentalBook/RentalBookAtMonth";
import { useRecoilState } from "recoil";
import { ManagerTabState } from "../store/ManagerPageStores";
import { CSSProperties, useEffect, useState } from "react";
import useSWR from "swr";
import { BillModalState } from "../store/ModalStores";
import { _client } from "../apis/client";
import { factoryData } from "./ManagerScreen";

export interface customerData {
  custNm: string;
  custNo: string;
  ictg: string;
  ikw: number;
  inds: string;
}

export interface billResponse {
  data: billData;
  msg: string;
  error: string | null;
  error_code: string | null;
}

export interface billData {
  custNo: string;
  bill_ym: string; //청구년월
  mr_ymd: string; //정기검침일
  bill_aply_pwr: number; //요금적용전력
  move_ymd: string;
  base_bill: number; //단위: 원, 기본요금
  kwh_bill: number; // 단위: 원, 전력량요금
  dc_bill: number; //단위: 원, 할인 공제계
  req_bill: number; //단위: 원, 전기 요금 계
  req_amt: number; // 단위:원, 청구 요금
  lload_usekwh: number; // 경부하 사용량, 단위: kWh
  mload_usekwh: number; // 중부하 사용량, 단위: kWh
  maxload_usekwh: number; // 최대부하 사용량, 단위: kWh
  lload_needle: number; // 경부하당월지침, 단위: kWh
  mload_needle: number; // 중부하당월지침, 단위: kWh
  maxload_needle: number; // 최대부하당월지침, 단위: kWh
  jn_pwrfact: number; // 진상역률 (지상역률 60%에서 90%면 기본요금 추가)
  ji_pwrfact: number; // 지상역률 (지상역률 90%이상 85%이하 고객에게 기본요금 감액 혜택)
}

let yearOptions: Array<number> = [2023, 2024, 2025];
let monthOptions: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const ElecManage = () => {
  const today = new Date();
  const [year, setYear] = useState<number>(today.getFullYear());
  const [month, setMonth] = useState<number>(today.getMonth() + 1);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<Array<customerData>>([]);
  const [selectedCustomerNo, setSelectedCustomerNo] = useState<string>("");
  const [selectedCustomerNm, setSelectedCustomerNm] = useState<string>("");
  const [requestedCustomerNo, setRequestedCustomerNo] = useState<string>("");
  const [requestedCustomerNm, setRequestedCustomerNm] = useState<string>("");
  const [billInfo, setBillInfo] = useState<billResponse>();
  const [errMsg, setErrMsg] = useState<string>("");

  const {
    data: myCustomers,
    error: myErr,
    isLoading: myLoad,
    mutate: myMutate,
  } = useSWR("/elec_info/customer_list", async (url) => {
    return await _client.postApiWithToken(url, {});
  });

  useEffect(() => {
    if (myCustomers?.data) {
      setCustomerList(myCustomers.data);
      if (myCustomers.data.length > 0) {
        setSelectedCustomerNm(myCustomers.data[0].custNm);
        setSelectedCustomerNo(myCustomers.data[0].custNo);
      }
    }
  }, [myCustomers]);

  useEffect(() => {
    if (selectedCustomerNm !== "") {
      customerList.forEach((element) => {
        console.log(element.custNm);
        if (element.custNm === selectedCustomerNm) {
          setSelectedCustomerNo(element.custNo);
          console.log(
            "Selected Customer Nm: " +
              selectedCustomerNm +
              ", No: " +
              selectedCustomerNo
          );
        }
      });
    }
  }, [selectedCustomerNm]);


  const numberFormat = (x: number) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "0";
    }
  };
  const numberToKorean = (nb: number) => {
    var inputNumber: number = nb < 0 ? 0 : nb;
    var unitWords = ["", "만 ", "억 ", "조 ", "경"];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = " 원";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    if (resultArray.length === 0) {
      return "0 원";
    }

    return resultString;
  };

  function getBillInfo() {
    setLoading(true);
    setRequestedCustomerNo(selectedCustomerNo);
    setRequestedCustomerNm(selectedCustomerNm);
    // let body = {
    //   custNo: "1130312640",
    //   dataMonth: "202305",
    // };
    _client
      .postApiWithToken("/elec_info/bill", {
        // custNo: "1130312640",
        // dataMonth: "202305",
        custNo: selectedCustomerNo,
        dataMonth:
          year.toString() +
          month.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
      })
      .then((res) => {
        console.log("successfully call. response as follows");
        console.log(res);
        setBillInfo(res);
        setErrMsg("");
        // alert("[공장장]\n" + res.msg);
      })
      .catch((err) => {
        console.log(err);
        setErrMsg(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {isLoading && <div style={backgroudStyle} />}
      {isLoading && (
        <img
          style={{
            backgroundColor: "transparent",
            position: "fixed",
            width: "50px",
            height: "50px",
            left: "50%",
            top: "50%",
          }}
          src={loadingGif}
          alt="loadingGif"
        />
      )}
      <ManageTabWrapper>
        <AboveCardRowCtnr>
          <SelectorCtnr>
            연도:
            <YearSelectorCtnr>
              <PeriodOption
                fontSize={"20px"}
                mobileFontSize={"15px"}
                fontWeight={"500"}
                value={year}
                onChange={(event) => {
                  setYear(Number(event.target.value));
                }}
              >
                {yearOptions.map((element) => {
                  return (
                    <option key={element}>
                      {element}
                      {/* {"년"} */}
                    </option>
                  );
                })}
              </PeriodOption>
            </YearSelectorCtnr>
            월:
            <MonthSelectorCtnr>
              <PeriodOption
                fontSize={"20px"}
                mobileFontSize={"15px"}
                fontWeight={"500"}
                value={month}
                onChange={(event) => {
                  setMonth(Number(event.target.value));
                }}
              >
                {monthOptions.map((element) => {
                  return (
                    <option key={element}>
                      {element}
                      {/* {"월"} */}
                    </option>
                  );
                })}
              </PeriodOption>
            </MonthSelectorCtnr>
            조회 할 고객명:
            <MonthSelectorCtnr>
              <PeriodOption
                fontSize={"20px"}
                mobileFontSize={"15px"}
                fontWeight={"500"}
                value={selectedCustomerNm}
                onChange={(event) => {
                  setSelectedCustomerNm(event.target.value);
                }}
              >
                {customerList.map((element) => {
                  return <option key={element.custNo}>{element.custNm}</option>;
                })}
              </PeriodOption>
            </MonthSelectorCtnr>
          </SelectorCtnr>
          <BtnCtnr
            row={true}
            etc="align-items: center;width: 186px;height: 56px;border:1px solid #2EA566;border-radius: 32px;background-color:#E9F9F0;color: #2EA566;font-size:18px;"
            onClick={() => getBillInfo()}
          >
            청구서 조회하기
          </BtnCtnr>
          {/* <BtnCtnr
            row={true}
            etc="align-items: center; width: 186px;height: 56px;border:1px solid #E6E9EE;border-radius: 32px;background-color:#E6E9EE;color: #121D2E;font-size:18px;"
            onClick={() => getBillInfo()}
          >
            고객 한전 설정하기
          </BtnCtnr> */}
        </AboveCardRowCtnr>
        {errMsg !== "" && <div>{errMsg}</div>}
        {errMsg == "" && billInfo?.data && (
          <EachFactoryCtnr key={billInfo.data.custNo}>
            <EachFactoryTitleCtnr>
              <EFTitle>
                요청한 고객명: {requestedCustomerNm} (번호:{" "}
                {requestedCustomerNo}){" "}
              </EFTitle>
              <EFRightSide></EFRightSide>
            </EachFactoryTitleCtnr>
            <EachFactoryDescriptionCtnr>
              <DescRowCtnr>
                <GrayColorP>고객 번호 | </GrayColorP>
                <div> {billInfo.data.custNo} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>청구 년월 | </GrayColorP>
                <div> {billInfo.data.bill_ym} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>정기 검침일 | </GrayColorP>
                <div> {billInfo.data.mr_ymd} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>요금 적용 전력 | </GrayColorP>
                <div> {billInfo.data.bill_aply_pwr} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>이사 정산일 | </GrayColorP>
                <div> {billInfo.data.move_ymd} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>기본요금 | </GrayColorP>
                <div> {billInfo.data.base_bill} 원 ({numberToKorean(billInfo.data.base_bill)})</div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>전력량요금 | </GrayColorP>
                <div> {billInfo.data.kwh_bill} ({numberToKorean(billInfo.data.kwh_bill)})</div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>할인 공제 계 | </GrayColorP>
                <div> {billInfo.data.dc_bill} 원</div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>전기 요금 계 | </GrayColorP>
                <div> {billInfo.data.req_bill} 원  ({numberToKorean(billInfo.data.req_bill)})</div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>청구 요금 | </GrayColorP>
                <div> {billInfo.data.req_amt} 원  ({numberToKorean(billInfo.data.req_amt)})</div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>경부하 사용량 | </GrayColorP>
                <div> {billInfo.data.lload_usekwh} kWh </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>중부하 사용량 | </GrayColorP>
                <div> {billInfo.data.mload_usekwh} kWh </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>최대부하 사용량 | </GrayColorP>
                <div> {billInfo.data.maxload_usekwh} kWh </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>경부하 당월지침 | </GrayColorP>
                <div> {billInfo.data.lload_needle} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>중부하 당월지침 | </GrayColorP>
                <div> {billInfo.data.mload_needle} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>최대 부하 당월지침 | </GrayColorP>
                <div> {billInfo.data.maxload_needle} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>진상역률 (60%~90%면 기본 요금 추가) | </GrayColorP>
                <div> {billInfo.data.jn_pwrfact} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>
                  지상역률 (90%이상 85% 이하 고객에게 기본요금 감액 혜택) |{" "}
                </GrayColorP>
                <div> {billInfo.data.ji_pwrfact} </div>
              </DescRowCtnr>
            </EachFactoryDescriptionCtnr>
          </EachFactoryCtnr>
        )}
      </ManageTabWrapper>
    </>
  );
};

const ManageTabWrapper = styled.div`
  ${CommonType.displayColumnFlex}
  padding-top: 15px;
  padding-bottom: 15px;
`;

const AboveCardRowCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContenStart}
  ${CommonType.alignItemsCenter}
  gap: 20px;
  border: 0px solid black;
`;
const SelectorCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentCenter}
  ${CommonType.alignItemsCenter}
  font-size: 18px;
  gap: 15px;
`;
const YearSelectorCtnr = styled.div`
  display: flex;
  flex-direction: row;
`;

const MonthSelectorCtnr = styled.div`
  display: flex;
  flex-direction: row;
`;

const SyncBtnCtnr = styled.div`
  ${CommonType.displayRowFlex}
  background-color: ${factoryColor.mainGreen};
  ${CommonType.displayColumnFlex}
  color: white;
`;

const EachFactoryCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  margin-top: 20px;
  border-left: solid 1px #d7dbe2;
  border-right: solid 1px #d7dbe2;
  border-top: solid 3px #d7dbe2;
  border-bottom: solid 3px #d7dbe2;
`;

const EachFactoryTitleCtnr = styled.div`
  background-color: #f9fafc;
  padding: 20px;
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentSpaceBetween}
`;

const EFTitle = styled.div`
  font-size: 30px;
  ${CommonType.fontWeight700}
`;

const EFRightSide = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
`;

const EFSyncBtnCtnr = styled.div`
  font-size: 20px;
  color: #2ea566;
  background-color: #e9f9f0;
`;

const EachFactoryDescriptionCtnr = styled.div`
  background-color: white;
  padding: 20px;
  ${CommonType.displayColumnFlex}
  gap: 15px;
  font-size: 20px;
`;
const DescRowCtnr = styled.div`
  ${CommonType.displayRowFlex}
  gap: 10px;
`;

const GrayColorP = styled.div`
  display: flex;
  color: ${factoryColor.descriptionSecondary};
`;

const EachFactoryBillTitleCtnr = styled.div`
  background-color: #f9fafc;
  padding: 20px;
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentSpaceBetween}
  font-size: 20px;
`;

const EachFactoryBillCardCtnr = styled.div`
  padding: 20px;
  ${CommonType.displayRowFlex}
  flex-wrap: wrap;
  gap: 15px;
`;

const BillCard = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.justifyContentCenter}
  ${CommonType.alignItemsCenter}
  width: 268px;
  height: 173px;
  padding: 15px;
  border-radius: 20px;
  background-color: #f2f4f6;
  gap: 15px;
`;

const BillTitle = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.fontWeight700}
  font-size: 20px;
`;

const BillBtnCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  background-color: #121d2e;
  width: 100%;
  color: white;
  cursor: pointer;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

const BillRefreshBtn = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentCenter}
  ${CommonType.alignItemsCenter}
  color: black;
  gap: 15px;
  cursor: pointer;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

const RefreshIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 25px;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const PeriodOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  height: 100%;
  width: 100%;

  border-radius: 5px;
  border: solid 1px #D7DBE2;
  padding: 3px;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.3)",
};
