import { createGlobalStyle } from "styled-components";
// import FontRegular from "../fonts/Pretendard-Regular.ttf";
// import FontMedium from "../fonts/Pretendard-Medium.ttf";
// import FontLight from "../fonts/Pretendard-Light.ttf";
// import FontThin from "../fonts/Pretendard-Thin.ttf";
// import FontSemiBold from "../fonts/Pretendard-SemiBold.ttf";
// import FontVariable from "../fonts/PretendardVariable.ttf";
// import FontExtraLight from "../fonts/Pretendard-ExtraLight.ttf";
// import FontExtraBold from "../fonts/Pretendard-ExtraBold.ttf";
// import FontBold from "../fonts/Pretendard-Bold.ttf";
// import FontBlack from "../fonts/Pretendard-Black.ttf";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Pretendard-Regular';
        src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }
    `;
//   @font-face {
//         font-family: 'FontRegular';
//         src: local('FontRegular'), local('FontRegular');
//         font-style: normal;
//         src: url(${FontRegular}) format('truetype');
//   }
//   @font-face {
//         font-family: 'FontMedium';
//         src: local('FontMedium'), local('FontMedium');
//         font-style: normal;
//         src: url(${FontMedium}) format('truetype');
//   }
//   @font-face {
//         font-family: 'FontLight';
//         src: local('FontLight'), local('FontLight');
//         font-style: normal;
//         src: url(${FontLight}) format('truetype');
//   }
//   `;

export const myFont = {
    regular: "Pretandard-Regular"
  };
  