import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { factoryColor } from "../constants/Colors";
import refreshIcon from "../asset/refresh-icon.png";
import loadingGif from "../asset/ring-loading.gif";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { RowFlex, ColFlex } from "../constants/Flex";
import { myFont } from "../constants/Fonts";
import { BtnCtnr, FlexCtnr } from "./FacilityScreen";
import {
  JustifyType,
  alignItemType,
  textAlign,
  CommonType,
  alignContentType,
} from "../constants/Styles";
import { RentalBookSelector } from "../components/Dropdown/RentalBookDropdown";
import { RentalBookAtMonth } from "../components/RentalBook/RentalBookAtMonth";
import { useRecoilState, useResetRecoilState } from "recoil";
import { ManagerTabState } from "../store/ManagerPageStores";
import { CSSProperties, useEffect, useState } from "react";
import useSWR from "swr";
import {
  BillModalState,
  ElecInfoSettingModalState,
  GlobalLoadingState,
  LoginModalState,
  UserLoginStatusType,
  UserStatus,
} from "../store/ModalStores";
import { _client } from "../apis/client";
import { factoryData } from "./ManagerScreen";

export interface elecUserData {
  id: number;
  phone_number: string;
  customer_type: string;
  birthday_number: string;
  elec_register_number: string;
  company_register_number: string;
}

export const ElecMapping = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [userList, setUserList] = useState<Array<elecUserData>>([]);

  const {
    data: allElecUsers,
    error: fetchError,
    isLoading: dataIsLoading,
    mutate: fetch,
  } = useSWR("/elec_info/read_all", async (url) => {
    return await _client.postApiWithToken(url, {});
  });

  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const deleteConfirmModal = (
    phone_number: string,
    elec_register_number: string
  ) => {
    confirmAlert({
      title: "아래 고객의 한전 연결을 해지하시겠습니까?",
      message: "고객 휴대폰 번호: " + phone_number.toString(),
      buttons: [
        {
          label: "예",
          onClick: () => {
            setLoadingModal({
              isLoading: true,
              message: "해지 중입니다.",
            });
            _client
              .postApiWithToken("/elec_info/delete", {
                phone_number: phone_number,
                elec_register_number: elec_register_number,
              })
              .then((response) => {
                fetch()
                resetLoadingModal();
                alert(response.msg);
              })
              .catch((err) => {
                fetch()
                resetLoadingModal();
                alert(err);
              });
          },
        },
        {
          label: "아니오",
          // onClick: () => (),
        },
      ],
    });
  };

  function isOdd(n: number) {
    if (n === 0) {
      return false;
    } else if (n % 2 === 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (allElecUsers?.data) {
      setUserList(allElecUsers.data);
    }
  }, [allElecUsers]);

  useEffect(() => {
    if (dataIsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [dataIsLoading]);

  const [userStatus, setUserStatus] = useRecoilState(UserStatus);
  const [loginModal, setLoginModal] = useRecoilState(LoginModalState);
  const [elecSettingModal, setElecSettingModal] = useRecoilState(
    ElecInfoSettingModalState
  );

  useEffect(() => {
    fetch();
  }, [elecSettingModal]);

  const openElecInfoSetting = () => {
    if (userStatus.loginStatusType !== UserLoginStatusType.LOGGEDIN) {
      alert("로그인이 필요한 서비스입니다.");
      setLoginModal({
        show: true,
        onPress: () => {},
      });
    } else if (userStatus.loginStatusType === UserLoginStatusType.LOGGEDIN) {
      if (userStatus.userPhoneNumber !== null) {
        setElecSettingModal({
          show: true,
          phone_number: "",
          elec_register_number: "",
          customer_type: "개인사업자",
          company_register_number_head: "",
          company_register_number_tail: "",
          birthday_number: "",
          is_phone_number_editable: true,
          agreement: true,
          from_customer: false, // 관리자에 의한 정보 추가
        });
      }
    }
  };
  return (
    <>
      {isLoading && <div style={backgroudStyle} />}
      {isLoading && (
        <img
          style={{
            backgroundColor: "transparent",
            position: "fixed",
            width: "50px",
            height: "50px",
            left: "50%",
            top: "50%",
          }}
          src={loadingGif}
          alt="loadingGif"
        />
      )}
      <ManageTabWrapper>
        <ColFlex
          width="100%"
          alignItems="flex-start"
          etc="border: 0px solid black;"
        >
          <BtnCtnr
            row={true}
            etc="align-items: center;width: 186px;height: 56px;border:1px solid #2EA566;border-radius: 32px;background-color:#E9F9F0;color: #2EA566;font-size:18px;"
            onClick={() => openElecInfoSetting()}
          >
            신규 고객 추가
          </BtnCtnr>

          <div
            className="TableContainer"
            style={{
              marginTop: "15px",
              textAlign: "center",
              width: "100%",
              fontSize: "15px",
            }}
          >
            <InfoTable width="100%">
              <Thead>
                <Tr>
                  <Th>
                    공장장
                    <br />
                    고유번호
                  </Th>
                  <Th>고객 휴대폰 번호</Th>
                  <Th>한전 고객번호</Th>
                  <Th>고객유형</Th>
                  <Th>생년월일</Th>
                  <Th>법인번호</Th>
                  <Th>동작</Th>
                </Tr>
              </Thead>
              <tbody>
                {allElecUsers?.data?.map(
                  (item: elecUserData, index: number) => {
                    return (
                      <AbTbodyTr
                        key={item.id}
                        backgroundColor={isOdd(index) ? "fff" : "#f4f4f5"}
                      >
                        <AbTbodyTd>{item.id.toString()}</AbTbodyTd>
                        <AbTbodyTd>{item.phone_number}</AbTbodyTd>
                        <AbTbodyTd>{item.elec_register_number}</AbTbodyTd>
                        <AbTbodyTd>{item.customer_type}</AbTbodyTd>
                        <AbTbodyTd>
                          {item.customer_type === "개인사업자"
                            ? item.birthday_number
                            : "해당없음"}
                        </AbTbodyTd>
                        <AbTbodyTd>
                          {item.customer_type === "법인사업자"
                            ? item.company_register_number
                            : "해당없음"}
                        </AbTbodyTd>
                        <AbTbodyTd>
                          <RowFlex etc="gap: 10px;">
                            {/* <SmallModifyBtn>수정</SmallModifyBtn> */}
                            <SmallDeleteBtn
                              onClick={() =>
                                deleteConfirmModal(
                                  item.phone_number,
                                  item.elec_register_number
                                )
                              }
                            >
                              해지
                            </SmallDeleteBtn>
                          </RowFlex>
                        </AbTbodyTd>
                      </AbTbodyTr>
                    );
                  }
                )}
              </tbody>
            </InfoTable>
          </div>
        </ColFlex>
      </ManageTabWrapper>
    </>
  );
};

const ManageTabWrapper = styled.div`
  ${CommonType.displayColumnFlex}
  padding-top: 15px;
  padding-bottom: 15px;
`;

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.3)",
};

interface tableOption {
  width: string;
}

export const TableContainer = styled.div`
  margin-top: 15px;
  text-align: center;
  width: 100%;
  font-size: 15px;
`;

export const InfoTable = styled.table<tableOption>`
  width: ${({ width }) => width};
  border-collapse: collapse;
  position: relative;
`;

export const Thead = styled.thead`
  font-weight: 500;
  text-align: left;
`;
export const Tr = styled.tr`
  font-weight: 500;
  text-align: left;
  background-color: #2ea566;
  color: white;
  font-size: 15px;
`;
export const Th = styled.th`
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: 500;
  text-align: center;
`;

export interface tbodyProps {
  backgroundColor?: string;
}

export const AbTbodyTr = styled.tr<tbodyProps>`
  background-color: ${(props) => props.backgroundColor};

  &:hover {
    background-color: #e3e3e3;
  }

  @media (max-width: 500px) {
    font-size: 4.5vw;
  }
`;

export interface tdProp {
  width?: string;
  mobileWidth?: string;
}

export const AbTbodyTd = styled.td<tdProp>`
  width: ${(props) => props.width};
  padding: 8px;
  overflow-wrap: break-word;
  padding-top: 1%;
  padding-bottom: 1%;
  @media (max-width: 500px) {
    width: ${(props) => props.mobileWidth};
  }
`;

const SmallDeleteBtn = styled.div`
  color: #f03e3e;
  border: 1px solid #f03e3e;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const SmallModifyBtn = styled.div`
  color: #1d5b3a;
  border: 1px solid #1d5b3a;
  border-radius: 15px;
  cursor: pointer;
  padding: 5px;
  &:hover {
    opacity: 0.5;
  }
`;
