import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  AddFactoryModalState,
  GlobalLoadingState,
  ModifyChungGuModalState,
  ModifyContractDetailModalState,
  ModifyFacilityModalState,
  TenantContractModalState,
} from "../../store/ModalStores";
import { alignItemType, CommonType, JustifyType } from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import closeIcon from "../../asset/close-circle.png";

export type NoticeModalProps = {};
export interface contractInfo {
  factory_id: number;
  facility_type: string;
  facility_company_name: string;
  contract_type: string;
  contract_deposit: string;
  facility_manage_fee: string;
  facility_super_tax_fee: string;
  facility_total_fee: string;
  contract_start_date: string;
  contract_end_date: string;
  contract_paper_url: string;
  additional_info: string;
  year: number;
  month: number;
}

export const ModifyFacilityModal = () => {
  const [thisModal, setThisModal] = useRecoilState(ModifyFacilityModalState);
  const resetThisModal = useResetRecoilState(ModifyFacilityModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const [factoryID, setFactoryID] = useState(thisModal.factory_id);
  const [facilityType, setFacilityType] = useState(thisModal.facility_type);
  const [facilityCompanyName, setFacilityCompanyName] = useState(
    thisModal.facility_company_name
  );
  const [contractType, setContractType] = useState(thisModal.contract_type);
  const [contractDeposit, setContractDeposit] = useState(
    thisModal.contract_deposit
  );
  const [facilityManageFee, setFacilityManageFee] = useState(
    thisModal.facility_manage_fee
  );
  const [facilitySuperTaxFee, setFacilitySuperTaxFee] = useState(
    thisModal.facility_super_tax_fee
  );
  const [facilityTotalFee, setFacilityTotalFee] = useState(
    thisModal.facility_total_fee
  );
  const [contractStartDate, setContractStartDate] = useState(
    thisModal.contract_start_date
  );
  const [contractEndDate, setContractEndDate] = useState(
    thisModal.contract_end_date
  );
  const [contractPaperUrl, setContractPaperUrl] = useState(
    thisModal.contract_paper_url
  );
  const [additionalInfo, setAdditionalInfo] = useState(
    thisModal.additional_info
  );
  const [month, setMonth] = useState(thisModal.month);
  const [year, setYear] = useState(thisModal.year);

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const numberFormat = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const stringNumberToKorean = (nb: string) => {
    try {
      var y: number = +nb;
      var inputNumber: number = y < 0 ? 0 : y;
      var unitWords = ["", "만", "억", "조", "경"];
      var splitUnit = 10000;
      var splitCount = unitWords.length;
      var resultArray = [];
      var resultString = "";

      for (var i = 0; i < splitCount; i++) {
        var unitResult =
          (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0) {
          resultArray[i] = unitResult;
        }
      }

      for (var i = 0; i < resultArray.length; i++) {
        if (!resultArray[i]) continue;
        resultString =
          String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
      }

      return resultString;
    } catch {
      return "";
    }
  };

  useEffect(() => {
    setFactoryID(thisModal.factory_id);
    setFacilityType(thisModal.facility_type);
    setFacilityCompanyName(thisModal.facility_company_name);
    setContractType(thisModal.contract_type);
    setContractDeposit(thisModal.contract_deposit);
    setFacilityManageFee(thisModal.facility_manage_fee);
    setFacilitySuperTaxFee(thisModal.facility_super_tax_fee);
    setFacilityTotalFee(thisModal.facility_total_fee);
    setContractStartDate(thisModal.contract_start_date);
    setContractEndDate(thisModal.contract_end_date);
    setContractPaperUrl(thisModal.contract_paper_url);
    setAdditionalInfo(thisModal.additional_info);
    setMonth(thisModal.month);
    setYear(thisModal.year);
  }, [thisModal]);

  const sendRequest = () => {
    if (factoryID < 0) {
      alert("관리 공장을 찾을 수 없습니다.");
    } else if (month < 0) {
      alert("유효하지 않은 월 입니다.");
    } else if (year < 0) {
      alert("유효하지 않은 연도 입니다.");
    } else {
      setLoadingModal({
        isLoading: true,
        message: "상세 계약을 변경하고 있습니다.",
      });
      _client
        .postApiWithToken("/facility/update", {
          factory_id: factoryID,
          facility_type: facilityType,
          facility_company_name: facilityCompanyName,
          contract_type: contractType,
          contract_deposit: contractDeposit,
          facility_manage_fee: facilityManageFee,
          facility_super_tax_fee: facilitySuperTaxFee,
          facility_total_fee: facilityTotalFee,
          contract_start_date: contractStartDate,
          contract_end_date: contractEndDate,
          contract_paper_url: contractPaperUrl,
          additional_info: additionalInfo,
          year: year,
          month: month,
        })
        .then((response) => {
          resetLoadingModal();
          alert(response.msg);
          resetThisModal();
        })
        .catch((err) => {
          resetLoadingModal();
          alert(err);
        });
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ItemCtnr>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  [{year}년 {month}월 {facilityType} 계약 상세 수정]
                  <br />
                </div>
                <ItemTitle>업체명</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={facilityCompanyName || ""}
                    onChange={(e) => setFacilityCompanyName(e.target.value)}
                    disabled={false}
                    placeholder="업체명"
                  />
                </ItemInputCtnr>
                <ItemTitle>대금 지급 유형</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={contractType || ""}
                    onChange={(e) => setContractType(e.target.value)}
                    disabled={false}
                    placeholder="대금 지급 유형 (e.g., 매월 지급)"
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  계약 보증금 (입력값: {stringNumberToKorean(contractDeposit)}{" "}
                  원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={contractDeposit || ""}
                    onChange={(e) => setContractDeposit(e.target.value)}
                    disabled={false}
                    placeholder="보증금을 입력하세요. (숫자만)"
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  시설 관리비 (입력값: {stringNumberToKorean(facilityManageFee)}{" "}
                  원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={facilityManageFee || ""}
                    onChange={(e) => setFacilityManageFee(e.target.value)}
                    disabled={false}
                    placeholder="시설 관리비를 입력하세요. (숫자만)"
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  시설 관리비 부가세 (입력값:{" "}
                  {stringNumberToKorean(facilitySuperTaxFee)} 원)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={facilitySuperTaxFee || ""}
                    onChange={(e) => setFacilitySuperTaxFee(e.target.value)}
                    disabled={false}
                    placeholder="시설 관리비 부가세를 입력하세요. (숫자만)"
                  />
                </ItemInputCtnr>
                <ItemTitle>계약 시작일 (e.g., 20220101)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={contractStartDate || ""}
                    onChange={(e) => setContractStartDate(e.target.value)}
                    disabled={false}
                    placeholder="계약 시작일 입력 (숫자만)."
                  />
                </ItemInputCtnr>
                <ItemTitle>계약 종료일 (e.g., 20221231)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={contractEndDate || ""}
                    onChange={(e) => setContractEndDate(e.target.value)}
                    disabled={false}
                    placeholder="계약 종료일 입력 (숫자만)."
                  />
                </ItemInputCtnr>
                <ItemTitle>업체 연락처 정보</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={additionalInfo || ""}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    disabled={false}
                    placeholder="관리 업체 연락처 정보 입력"
                  />
                </ItemInputCtnr>
                <ItemTitle>계약서 원본 URL</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={contractPaperUrl || ""}
                    onChange={(e) => setContractPaperUrl(e.target.value)}
                    disabled={false}
                    placeholder="계약서 원본 URL 입력"
                  />
                </ItemInputCtnr>
              </ItemCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => sendRequest()}>
                  수정 반영하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "300px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
  height: 90vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ItemCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const ItemTitle = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  margin-top: 20px;
`;

const ItemInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;
