import { singleElecDashboardData } from "../screen/ElecInfoScreen";

export const tenantData = [
  {
    id: 1,
    name: "김OO",
    companyName: "(주) Realizable",
    RentalFee: "11,000,000 원 (천백만 원)",
    ManagementFee: "5,500,000 원 (오백오십만 원)",
  },
  {
    id: 2,
    name: "김OO",
    companyName: "(주) Realizable",
    RentalFee: "11,000,000 원 (천백만 원)",
    ManagementFee: "5,500,000 원 (오백오십만 원)",
  },
  {
    id: 3,
    name: "김OO",
    companyName: "(주) Realizable",
    RentalFee: "11,000,000 원 (천백만 원)",
    ManagementFee: "5,500,000 원 (오백오십만 원)",
  },
];

export const elecSaveData = [
  {
    id: 1,
    time: "오전 8시~10시",
    saveElec: "424.1 kWh",
    saveMoney: "1,125,909 원",
  },
  {
    id: 2,
    time: "새벽 2시~4시",
    saveElec: "86.1 kWh",
    saveMoney: "827,515 원",
  },
  {
    id: 3,
    time: "저녁 8시~10시",
    saveElec: "42.1 kWh",
    saveMoney: "617,422 원",
  },
];

export const elecCompareData = [
  {
    id: 1,
    month: "당월",
    data: "53,177",
  },
  {
    id: 2,
    month: "전월",
    data: "45,098",
  },
  {
    id: 3,
    month: "전년동월",
    data: "56,003",
  },
];

export const elecCurrentData = [
  {
    id: 1,
    month: "경부하",
    data: "3,177",
  },
  {
    id: 2,
    month: "중부하",
    data: "4,098",
  },
  {
    id: 3,
    month: "최대부하",
    data: "1,003",
  },
];

export const contractData = [
  {
    id: 1,
    name: "임대유형",
    contents: "월세 선불",
  },
  {
    id: 2,
    name: "보증금",
    contents: "10,000,000 원 (천만 원)",
  },
  {
    id: 3,
    name: "납부일",
    contents: "매달 10일",
  },
  {
    id: 4,
    name: "월 임대료",
    contents: "300,000 원 (30만 원)",
  },
  {
    id: 5,
    name: "월 관리비",
    contents: "100,000 원 (10만 원)",
  },
  {
    id: 6,
    name: "계약 기간",
    contents: "2021년 2월 1일 ~ 2023년 1월 31일",
  },
];
export const reviewData = [
  {
    id: 1,
    name: "최OO (**테크 대표님)",
    // content: '천지는 발휘하기 인간의 봄바람이다. 예가 풍부하게 방황하여도, 이상의 않는 힘차게 두기 이상, 위하여 것이다. 오아이스'
    content:
      "공장 관리가 어려워 서비스를 이용하게 되었는데요, 번거롭게 매달려있던 관리 업무에서 완전히 해방됐습니다. 강추합니다.",
  },
  {
    id: 2,
    name: "김OO (**산업 대표님)",
    content:
      "임차인이 들어오고 나갈때마다 골치가 아팠는데 깔끔하게 조율해주시더라구요. 올해도 재계약 했습니다.",
  },
  {
    id: 3,
    name: "백OO (**물산 부장님)",
    content:
      "전기 사용료를 여러 임차인에게 나눠서 청구할 때마다 문의가 끊이질 않았는데, 여기서 투명하게 청구서를 만들어주다보니 문제가 해결됐습니다.",
  },
  {
    id: 4,
    name: "강OO (**TECH 이사님)",
    content: "공실이나 미납금 관련된 부분들이 케어가 돼서 놀랐습니다.",
  },
  {
    id: 5,
    name: "홍ㅇㅇ (**산업 대표님)",
    content:
      "공장장을 이용하면서 개인 여가 시간이 많아졌습니다. 임차인 민원에 응대할 일도 없어졌구요. 강력 추천입니다.",
  },
  {
    id: 6,
    name: "김OO (**정공 부장님)",
    content:
      "다른 관리 용역 업체랑 다르게 서비스 형태이다 보니 수시로 임대료나 공실, 미납 상황을 확인할 수 있어서 좋네요.",
  },
];