import styled from "styled-components";
import { factoryColor } from "../constants/Colors";
import leftArrow from "../asset/left-arrow.png";
import rightArrow from "../asset/right-arrow.png";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { myFont } from "../constants/Fonts";
import {
  JustifyType,
  alignItemType,
  textAlign,
  CommonType,
} from "../constants/Styles";
import { RentalBookSelector } from "../components/Dropdown/RentalBookDropdown";
import { RentalBookAtMonth } from "../components/RentalBook/RentalBookAtMonth";
import { _client } from "../apis/client";
import useSWR from "swr";
import { infoManageData, tenantData } from "./InfoManageTab";
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useMediaQuery } from "react-responsive";
import {
  ContractDetailModalState,
  GlobalLoadingState,
  UserLoginStatusType,
  UserStatus,
} from "../store/ModalStores";

export interface rentalBookTenantData {
  tenant_id: number;
  tenant_company_name: string;
  phone_number: string;
  tenant_name: string;
  current_rental_fee: number;
  current_management_fee: number;
  requested_paper_url: string;
  current_contract_paper_url: string;
  is_overdue: boolean;
}

export interface rentalBookData {
  factory_id: number;
  factory_name: string;
  factory_owner_name: string;
  factory_owner_phone_number: string;
  factory_address: string;
  total_fee: number;
  total_requested_rental_fee: number;
  total_requested_management_fee: number;
  total_requested_report_url: string;
  requested_rental_fee_to_owner_url: string;
  total_overdue_rental_fee: number;
  total_overdue_management_fee: number;
  all_tenants: rentalBookTenantData[];
}

export const RentalBookScreen = () => {
  const today = new Date();
  const [currentYear, setCurrentYear] = useState<number>(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(
    today.getMonth() + 1
  );
  const [prevMonth, setPrevMonth] = useState<number>(today.getMonth());
  const [nextMonth, setNextMonth] = useState<number>(today.getMonth() + 2);

  useEffect(() => {
    myMutate();
    // 12월에서 다음 버튼 클릭시 (12 -> 13)
    if (currentMonth > 12) {
      setCurrentYear(currentYear + 1); // +1년
      setCurrentMonth(1); // 현재 월은 1월로 변경
      setPrevMonth(12); // 전달은 12월로 표시
      setNextMonth(2); // 다음달은 2월로 표시
    }
    // 1월에서 이전 버튼 클릭시 (1 -> 0)
    else if (currentMonth < 1) {
      setCurrentYear(currentYear - 1); // -1년
      setCurrentMonth(12); // 현재 월은 1월로 변경
      setPrevMonth(11); // 전달은 11월로 표시
      setNextMonth(1); // 다음달은 1월로 표시
      // 11 -> 12월 될때
    } else if (currentMonth == 12) {
      setPrevMonth(currentMonth - 1);
      setNextMonth(1);
      // 2월 -> 1월 될때
    } else if (currentMonth == 1) {
      setPrevMonth(12);
      setNextMonth(currentMonth + 1);
    } else {
      setPrevMonth(currentMonth - 1);
      setNextMonth(currentMonth + 1);
    }
  }, [currentMonth]);

  const numberFormat = (x: number) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "0";
    }
  };

  const numberToKorean = (nb: number) => {
    var inputNumber: number = nb < 0 ? 0 : nb;
    var unitWords = ["", "만 ", "억 ", "조 ", "경"];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = " 원";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    if (resultArray.length === 0) {
      return "0 원";
    }

    return resultString;
  };

  const isLoggedIn =
    localStorage.getItem("realizableLoginStatus") ===
    UserLoginStatusType.LOGGEDIN
      ? true
      : false;

  const {
    data: myRentalBook,
    error: myErr,
    isLoading: myLoad,
    mutate: myMutate,
  } = useSWR(
    isLoggedIn ? "/rental_book/all" : "/rental_book/all_sample",
    async (url: string | undefined) => {
      if (isLoggedIn) {
        return await _client.postApiWithToken(url, {
          lookup_year: currentYear,
          lookup_month: currentMonth,
        });
      } else {
        return await _client.postApi(url, {
          lookup_year: currentYear,
          lookup_month: currentMonth,
        });
      }
    }
  );

  const isPc = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const isTablitOrMobile = useMediaQuery({
    query: "(max-width: 1023px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const openWindow = (url: string) => {
    window.open(url);
  };

  const [contractDetailModal, setContractDetailModal] = useRecoilState(
    ContractDetailModalState
  );

  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const openContractDetailModal = (
    factory_id: number,
    tenant_name: string,
    tenant_company_name: string,
    year: number,
    month: number,
    tenant_phone_number: string
  ) => {
    setLoadingModal({ isLoading: true, message: "" });
    let _url;
    let _tenant_phone_number = "";
    if (
      localStorage.getItem("realizableLoginStatus") ===
      UserLoginStatusType.LOGGEDIN
    ) {
      _url = "/rental_book/tenant_contract_info";
      _tenant_phone_number = tenant_phone_number;
    } else {
      _url = "/rental_book/tenant_contract_info_sample";
      _tenant_phone_number = "01099999999";
    }
    _client
      .postApi(_url, {
        factory_id: factory_id,
        lookup_year: year,
        lookup_month: month,
        tenant_phone_number: tenant_phone_number,
      })
      .then((response) => {
        console.log(response.data);
        setContractDetailModal({
          show: true,
          factory_id: factory_id,
          lookup_month: month,
          lookup_year: year,
          tenant_name: tenant_name,
          tenant_company_name: tenant_company_name,
          rental_type: response.data.rental_type,
          rental_deposit: response.data.rental_deposit,
          rental_fee_due_date: response.data.rental_fee_due_date,
          tenant_rental_fee: response.data.tenant_rental_fee,
          tenant_manage_fee: response.data.tenant_manage_fee,
          contract_start_date: response.data.contract_start_date,
          contract_end_date: response.data.contract_end_date,
          contract_paper_url: response.data.contract_paper_url,
          tenant_phone_number: _tenant_phone_number,
          agent_phone_number: response.data.agent_phone_number,
        });
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        resetLoadingModal();
      });
  };

  return (
    <RentalBookScreenCtnr>
      <HorizontalPaddingCtnr>
        <FactoryTitleCtnr>
          임대장부
          {/* <div>공지사항</div> */}
        </FactoryTitleCtnr>
        <MonthSelectCtnr>
          <MonthSelector>
            <ArrowIcon src={leftArrow} />
            <MoveMonth onClick={() => setCurrentMonth(currentMonth - 1)}>
              {prevMonth}월
            </MoveMonth>
            <CurrentMonth>
              {currentYear.toString()}년 {currentMonth.toString()}월
            </CurrentMonth>
            <MoveMonth onClick={() => setCurrentMonth(currentMonth + 1)}>
              {nextMonth}월{" "}
            </MoveMonth>
            <ArrowIcon src={rightArrow} />
          </MonthSelector>
          {/* <FactorySelector>
            <FactoryOption
              fontSize={"0.7vw"}
              mobileFontSize={"3vw"}
              fontWeight={"500"}
              value={currentFactory}
              onChange={(event) => {
                setCurrentFactory(event.target.value);
              }}
            >
              {myRentalBook?.data?.map((element: rentalBookData) => {
                return (
                  <option key={element.factory_id}>
                    {element.factory_name}
                  </option>
                );
              })}
            </FactoryOption>
          </FactorySelector> */}
        </MonthSelectCtnr>
        {myErr ? (
          <div>{myErr}</div>
        ) : (
          <>
            {myRentalBook?.data?.map((r: rentalBookData) => {
              return (
                <RentalBookCtnr>
                  <TitleCtnr>
                    {r.factory_name} | {r.factory_owner_name}{" "}
                  </TitleCtnr>
                  <RentalFeeBoxCtnr
                    flexDirection={isPc ? "row" : "column"}
                    width="100%"
                  >
                    <RentalFeeEachBox
                      flexDirection="column"
                      width={isPc ? "50%" : "100%"}
                    >
                      <RentalFeeTitle>청구 내역</RentalFeeTitle>
                      <RentalFeeBody>
                        <RentalFeeRow>
                          <RentalFeeRowTitle>
                            총 금액 {isMobile ? <br /> : ""}(VAT 포함)
                          </RentalFeeRowTitle>
                          <RentalFeeRowContents>
                            {numberFormat(r.total_fee)}
                            {" 원"}
                            <br />
                            {"("}
                            {numberToKorean(r.total_fee)}
                            {")"}
                          </RentalFeeRowContents>
                        </RentalFeeRow>
                        <RentalFeeRow>
                          <RentalFeeRowTitle>
                            임대료 {isMobile ? <br /> : ""}(VAT 제외)
                          </RentalFeeRowTitle>
                          <RentalFeeRowContents>
                            {numberFormat(r.total_requested_rental_fee)}
                            {" 원"}
                            <br />
                            {"("}
                            {numberToKorean(r.total_requested_rental_fee)}
                            {")"}
                          </RentalFeeRowContents>
                        </RentalFeeRow>
                        <RentalFeeRow>
                          <RentalFeeRowTitle>
                            관리비 {isMobile ? <br /> : ""}(VAT 포함)
                          </RentalFeeRowTitle>
                          <RentalFeeRowContents>
                            {numberFormat(r.total_requested_management_fee)}
                            {" 원"}
                            <br />
                            {"("}
                            {numberToKorean(r.total_requested_management_fee)}
                            {")"}
                          </RentalFeeRowContents>
                        </RentalFeeRow>
                        <RentalReportBtnCtnr
                          onClick={() =>
                            openWindow(r.total_requested_report_url)
                          }
                        >
                          관리 보고서 원본 보기 {">"}
                        </RentalReportBtnCtnr>
                        <RentalFeeBtnCtnr
                          onClick={() =>
                            openWindow(r.requested_rental_fee_to_owner_url)
                          }
                        >
                          관리비 청구서 원본 보기 {">"}
                        </RentalFeeBtnCtnr>
                      </RentalFeeBody>
                    </RentalFeeEachBox>
                    <RentalFeeEachBox
                      flexDirection="column"
                      width={isPc ? "50%" : "100%"}
                    >
                      <RentalFeeTitle>연체 내역</RentalFeeTitle>
                      <RentalFeeBody>
                        <RentalFeeRow>
                          <RentalFeeRowTitle>임대료</RentalFeeRowTitle>
                          <RentalFeeRowContents>
                            {numberFormat(r.total_overdue_rental_fee)}
                          </RentalFeeRowContents>
                        </RentalFeeRow>
                        <RentalFeeRow>
                          <RentalFeeRowTitle>관리비</RentalFeeRowTitle>
                          <RentalFeeRowContents>
                            {numberFormat(r.total_overdue_management_fee)}
                          </RentalFeeRowContents>
                        </RentalFeeRow>
                        <RentalFeeBtnCtnr>
                          연체 보고서 보기 {">"}
                        </RentalFeeBtnCtnr>
                      </RentalFeeBody>
                    </RentalFeeEachBox>
                  </RentalFeeBoxCtnr>
                  <TenantsListTitleCtnr>임차인 목록</TenantsListTitleCtnr>
                  {r.all_tenants.map((t: rentalBookTenantData) => (
                    <TenantsItemCtnr isMobile={isTablitOrMobile}>
                      <FlexCtnr
                        row={isTablitOrMobile ? false : true}
                        justifyContent="flex-start"
                        alignItems={isTablitOrMobile ? "flex-start" : "center"}
                        width={isTablitOrMobile ? "100%" : "70%"}
                        etc={"border 0px solid black;"}
                      >
                        <FlexCtnr
                          justifyContent="flex-start"
                          row={true}
                          alignItems={isTablitOrMobile ? "center" : "center"}
                          etc={
                            isTablitOrMobile
                              ? "border: 0px solid red;width: 100%;"
                              : "width: 300px;"
                          }
                        >
                          <TenantsItemLogoCtnr>
                            {t.tenant_company_name[0]}
                          </TenantsItemLogoCtnr>
                          <TenantsItemCompanyName>
                            {t.tenant_name}, {t.tenant_company_name}
                          </TenantsItemCompanyName>
                          {t.is_overdue ? (
                            <OverDueCricle isOverDue={true}>미납</OverDueCricle>
                          ) : (
                            <OverDueCricle isOverDue={false}>
                              완납
                            </OverDueCricle>
                          )}
                        </FlexCtnr>
                        <FlexCtnr
                          justifyContent="flex-start"
                          row={true}
                          alignItems={
                            isTablitOrMobile ? "flex-start" : "center"
                          }
                          etc={
                            isTablitOrMobile
                              ? "width: 100%;border: 0px solid red;"
                              : "width: 350px;border: 0px solid red;"
                          }
                        >
                          <TenantsItemFeeCtnr>
                            <TenantsFeeTitle>임대료</TenantsFeeTitle>
                            <TenantsFeeContents>
                              {numberFormat(t.current_rental_fee)} 원{" ("}
                              {numberToKorean(t.current_rental_fee)}
                              {")"}
                            </TenantsFeeContents>
                          </TenantsItemFeeCtnr>
                          <TenantsItemFeeCtnr>
                            <TenantsFeeTitle>관리비</TenantsFeeTitle>
                            <TenantsFeeContents>
                              {numberFormat(t.current_management_fee)} 원{" ("}
                              {numberToKorean(t.current_management_fee)}
                              {")"}
                            </TenantsFeeContents>
                          </TenantsItemFeeCtnr>
                        </FlexCtnr>
                      </FlexCtnr>
                      <FlexCtnr
                        row={isTablitOrMobile ? false : true}
                        width={isTablitOrMobile ? "100%" : "30%"}
                        etc={"border: 0px solid blue;"}
                      >
                        <TenantsContractBtnCtnr
                          onClick={() => openWindow(t.requested_paper_url)}
                          isMobile={isTablitOrMobile}
                        >
                          이번달 청구서
                        </TenantsContractBtnCtnr>
                        <TenantsContractBtnCtnr
                          onClick={() =>
                            openContractDetailModal(
                              r.factory_id,
                              t.tenant_name,
                              t.tenant_company_name,
                              currentYear,
                              currentMonth,
                              t.phone_number
                            )
                          }
                          isMobile={isTablitOrMobile}
                        >
                          계약 상세
                        </TenantsContractBtnCtnr>
                      </FlexCtnr>
                      {/* 
                      {isTablit ? (
                        <>
                          <TenantsContractBtnCtnr
                            onClick={() => openWindow(t.requested_paper_url)}
                            isMobile={isMobile}
                          >
                            이번달 청구서
                          </TenantsContractBtnCtnr>
                          <TenantsContractBtnCtnr
                            onClick={() =>
                              openContractDetailModal(
                                t.current_contract_paper_url
                              )
                            }
                            isMobile={isMobile}
                          >
                            계약 상세
                          </TenantsContractBtnCtnr>
                        </>
                      ) : (
                        <ColumnCtnr>
                          <TenantsContractBtnCtnr
                            onClick={() => openWindow(t.requested_paper_url)}
                            isMobile={isMobile}
                          >
                            이번달 청구서
                          </TenantsContractBtnCtnr>
                          <TenantsContractBtnCtnr
                            onClick={() =>
                              openWindow(t.current_contract_paper_url)
                            }
                            isMobile={isMobile}
                          >
                            계약 상세
                          </TenantsContractBtnCtnr>
                        </ColumnCtnr>
                      )} */}
                    </TenantsItemCtnr>
                  ))}
                </RentalBookCtnr>
              );
            })}{" "}
          </>
        )}
      </HorizontalPaddingCtnr>
    </RentalBookScreenCtnr>
  );
};

const RentalBookScreenCtnr = styled.div`
  display: flex;
  flex-direction: column;

  align-items: ${alignItemType.center};
  justify-content: ${JustifyType.center};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #ffffff;
`;

export const ArrowIcon = styled.img`
  width: 2.5%;
`;

const HorizontalPaddingCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlack};
  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const FactoryTitleCtnr = styled.div`
  font-family: ${myFont.regular};

  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 40px;
  font-weight: 700;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border-bottom: 1px solid ${factoryColor.border_secondary};
`;

export const MonthSelectCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 30px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  height: 50px;
  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 0px solid ${factoryColor.border_secondary};
`;

export const MonthSelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 400px;
  padding: 10px;

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

export const MoveMonth = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 20%;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
  border: 0px solid ${factoryColor.border_secondary};
`;

const CurrentMonth = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 52%;

  font-size: 22px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border-left: 1px solid ${factoryColor.border_secondary};
  border-right: 1px solid ${factoryColor.border_secondary};
  padding-left: 5%;
  padding-right: 5%;
`;

const FactorySelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.fromStart};
  text-align: ${textAlign.start};
  width: 200px;
  padding: 12px;

  font-size: 18px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const FactoryOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  width: 100%;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const RentalBookCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 30px;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
`;

const TitleCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.fromStart};
  align-self: ${alignItemType.fromStart};
  width: 100%;
  padding: 20px;

  text-align: ${textAlign.start};
  overflow: hidden;

  font-size: 20px;
  font-weight: 700;

  color: ${factoryColor.textPrimary};
  background-color: ${factoryColor.bg_primary};
  border-bottom: 1px solid ${factoryColor.border_secondary};

  box-sizing: border-box;
`;
interface alignOption {
  flexDirection: string;
  width: string;
}

const RentalFeeBoxCtnr = styled.div<alignOption>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: ${({ width }) => width};
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  background-color: white;
`;

const RentalFeeEachBox = styled.div<alignOption>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: ${({ width }) => width};
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  background-color: ${factoryColor.bg_primary};
  border: 1px solid ${factoryColor.border_secondary};
  margin: 15px;
`;

const RentalFeeTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  font-size: 15px;
  border-bottom: 1px solid ${factoryColor.border_secondary};
  padding-top: 12px;
  padding-bottom: 12px;
`;

const RentalFeeBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  font-size: 15px;
  padding: 12px;
`;

const RentalFeeRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.center};
`;

const RentalFeeRowTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  padding-left: 20px;
  padding-bottom: 15px;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.center};
  color: ${factoryColor.descriptionSecondary};
`;

const RentalFeeRowContents = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  padding-right: 20px;
  padding-bottom: 15px;
  justify-content: ${JustifyType.fromEnd};
  align-items: ${alignItemType.center};
  color: ${factoryColor.textPrimary};
  text-align: right;
`;

const RentalReportBtnCtnr = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  background-color: ${factoryColor.textPrimary};
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const RentalFeeBtnCtnr = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  background-color: white;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  border: 1px solid ${factoryColor.textPrimary};
  color: ${factoryColor.textPrimary};
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const TenantsListTitleCtnr = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: ${factoryColor.bg_primary};
  padding: 20px;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: ${factoryColor.textPrimary};
  font-size: 18px;
  font-weight: 500;
  border-top: 1px solid ${factoryColor.border_secondary};
  border-bottom: 1px solid ${factoryColor.border_secondary};
`;

interface mobileMode {
  isMobile?: boolean;
}

const TenantsItemCtnr = styled.div<mobileMode>`
  display: flex;
  font-family: ${myFont.regular};
  width: 100%;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  background-color: white;
  padding: 20px;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: ${factoryColor.textPrimary};
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid ${factoryColor.border_secondary};
`;

const TenantsItemLogoCtnr = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  flex-direction: row;
  background-color: #e14840;
  border-radius: 100px;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: white;
  font-size: 15px;
  font-weight: 700;
`;

const TenantsItemCompanyName = styled.div<mobileMode>`
  display: flex;
  margin-left: 1%;
  flex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: ${factoryColor.textPrimary};
  font-weight: 700;
`;

interface tenantItemOption {
  flexDirection: string;
  width: string;
}

const TenantsItemFeeCtnr = styled.div<mobileMode>`
  display: flex;
  margin-left: 0px;
  flex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  padding: ${({ isMobile }) => (isMobile ? "5px" : "0px")};
`;

const TenantsItemFeeCtnrMobile = styled.div`
  display: flex;
  width: 15%;
  flex-direction: column;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.center};
  ${CommonType.alignSelfStart}
  margin-top: 10px;
  box-sizing: border-box;
`;

const TenantsFeeTitle = styled.div`
  color: ${factoryColor.descriptionSecondary};
  padding-right: 10px;
`;

const TenantsFeeContents = styled.div`
  display: flex;
  flex-direction: row;
  color: ${factoryColor.textPrimary};
`;

const TenantsContractBtnCtnr = styled.div<mobileMode>`
  display: flex;
  width: ${({ isMobile }) => (isMobile ? "100%" : "120px")};
  padding: 10px;
  margin-right: 10px;
  fiex-direction: row;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  font-size: 15px;
  background-color: ${factoryColor.halfMainGreen};
  color: ${factoryColor.mainGreen};
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
  :hover {
    opacity: 0.5;
  }
`;

const RowCtnr = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnCtnr = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface flexOption {
  row: boolean;
  justifyContent?: string;
  alignItems?: string;
  isMobile?: boolean;
  width?: string;
  etc?: string;
}

const FlexCtnr = styled.div<flexOption>`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  box-sizing: border-box;
  gap: 10px;
  padding: 5px;
  ${({ etc }) => (etc ? etc : "")}
  flex-wrap: wrap;
  width: ${({ width }) => (width ? width : "")};
`;

interface overDue {
  isOverDue: boolean;
}

const OverDueCricle = styled.div<overDue>`
  display: flex;
  box-sizing: border-box;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  background-color: ${({ isOverDue }) => (isOverDue ? "#ffefef" : "#d7dbe2")};
  color: ${({ isOverDue }) => (isOverDue ? "#f03e3e" : "#354153")};
  font-size: 15px;
`;
