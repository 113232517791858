import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  ConsultingRequestModalState,
  ElecInfoSettingModalState,
  GlobalLoadingState,
  LawRequestModalState,
} from "../../store/ModalStores";
import closeIcon from "../../asset/close-circle.png";
import {
  alignItemType,
  CommonType,
  GreenSpan,
  JustifyType,
} from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import logoGreen from "../../asset/logo_green.png";
import { useNavigate } from "react-router-dom";
import { Pixel } from "../../constants/Pixel";
import { request } from "http";

let requestType: Array<string> = ["내용증명", "명도소송"];

export const LawRequestModal = () => {
  const [thisModal, setThisModal] = useRecoilState(LawRequestModalState);
  const resetThisModal = useResetRecoilState(LawRequestModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);
  const [agreement, setAgreement] = useState<boolean>(false);
  const [requestOption, setRequestOption] = useState<string>(requestType[0]);

  const [sendMsg, setSendMsg] = useState("");

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const sendRequest = () => {
    if (!agreement) {
      alert("이용 동의 후 접수 가능합니다.");
    } else {
      setLoadingModal({
        isLoading: true,
        message: "한전 설정 중입니다.",
      });
      _client
        .postApiWithToken("/request/law_inquery", {
          inquery_type: requestOption,
          comment: sendMsg
        })
        .then((response) => {
          resetLoadingModal();
          alert(response.msg);
        })
        .catch((err) => {
          resetLoadingModal();
          alert(err);
        })
        .finally(() => {
          resetThisModal();
        });
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ModalHeader>
                <MainLogoImg src={logoGreen}></MainLogoImg>
              </ModalHeader>
              <ModalDescription>
                <LocalGreenSpan>법률 문의</LocalGreenSpan>
              </ModalDescription>
              <ConsultingRequest>
                <ContentDesc>문의 유형을 선택해주세요.</ContentDesc>
                <ContentInputCtnr>
                  <ConsultingOption
                    fontSize={"100%"}
                    mobileFontSize={"3vw"}
                    fontWeight={"500"}
                    value={requestOption}
                    onChange={(event) => {
                      setRequestOption(event.target.value);
                    }}
                  >
                    {requestType.map((element) => {
                      return <option key={element}>{element}</option>;
                    })}
                  </ConsultingOption>
                </ContentInputCtnr>
              </ConsultingRequest>
              <UserVerification>
                <UserVerifyDesc>문의 내용 (간단 작성)</UserVerifyDesc>
                <UserVerifyInputCtnr>
                  <textarea
                    style={{ height: "100px" }}
                    value={sendMsg}
                    rows={20}
                    cols={100}
                    name="send_message"
                    onChange={(e) => setSendMsg(e.target.value)}
                  >
                    {sendMsg}
                  </textarea>
                </UserVerifyInputCtnr>
              </UserVerification>
              <AgreementCtnr>
                <CheckBox
                  type="checkbox"
                  checked={agreement}
                  onChange={({ target: { checked } }) => setAgreement(checked)}
                />
                법률 상담을 위한 제3자 정보 수집 및 이용에 동의합니다. (필수)
              </AgreementCtnr>
              <AgreementBox>
                <Article>
                  <ArticleText>
                    <div>개인정보 수집 및 제3자 정보제공에 대한 동의</div>
                    다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                    <br />
                    ㅇ 개인정보를 제공받는 자 (서비스사업자)
                    <br />
                    상호(법인)명 : 렉시냅틱스 (주) - 법대로 서비스
                    <br />
                    ㅇ 수집ㆍ이용하는 개인정보 항목
                    <br />
                    성명, ID, 전화번호, E-mail
                    <br />
                    ㅇ 제공받는 자의 개인정보 이용 목적
                    <br />
                    요청하는 법률 서비스의 종합 제공 및 관련 업무 이관
                    <br />
                    ㅇ 제공받는 자의 보유 및 이용 기간
                    <br />
                    회원탈퇴시 또는 개인정보 제3자 제공 철회 시까지
                    <br />
                    [개인정보 수집 및 개인정보 제3자 제공 동의 거부의 권리]{" "}
                    <br />- 고객은 본 동의를 언제든지 거부할 권리가 있으며, 다만
                    동의하지 않은 경우 서비스 이용에 제한이 있을 수 있습니다.
                  </ArticleText>
                </Article>
              </AgreementBox>
              <SubmitBtnCtnr>
                <SubmitBtn active={agreement} onClick={() => sendRequest()}>
                  접수하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "350px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
`;

const ModalHeader = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 35px;
`;
const ModalDescription = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  background-color: ${factoryColor.bg_primary};
`;

const UserVerification = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const UserVerifyDesc = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
`;

const UserVerifyInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 0px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
`;

const ContentDesc = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  font-size: 15px;
  font-weight: 500;
`;

const ContentInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  font-size: 15px;
  height: auto;
  width: 100%;
  margin-top: 12px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const ConsultingRequest = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  padding: 20px;
`;

const AgreementCtnr = styled.div`
  display: flex;
  flex-direction: row;
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  width: auto;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;

interface activeProp {
  active: boolean;
}

const SubmitBtn = styled.button<activeProp>`
  background-color: ${({ active }) => (active ? "#2EA566" : "white")};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: ${({ active }) => (active ? "pointer" : "")};
  outline: none;
  font-weight: 700;
  font-size: 15px;
  color: ${({ active }) => (active ? "white" : "gray")};
  background-repeat: no-repeat;
  border: ${({ active }) => (active ? "none" : "1px solid gray")};
  opacity: 1;
  :hover {
    opacity: ${({ active }) => (active ? "0.8" : "")};
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const ConsultingOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  height: 100%;
  width: 100%;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const CheckBox = styled.input`
  display: flex;
  width: 25px;
  margin-right: 10px;
  outline: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  color: gray;
`;

const MainLogoImg = styled.img`
  width: 36.5px;
  height: 20px;

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 49.15px;
    height: 26.67px;
  }
`;

const AgreementBox = styled.div`
  position: relative;
  overflow: auto;
  height: 90px;
  padding-left: 20px;
  padding-right: 10px;
  border: 1p solid #fff;
  background: #eee;
  box-sizing: border-box;
`;
const Article = styled.div`
  color: #48484a;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  text-align: left;
`;
const ArticleText = styled.div`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;
