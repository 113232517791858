import styled from "styled-components";
import { useRecoilState } from "recoil";
import { ConsultingRequestModalState } from "../../store/ModalStores";
import { useMediaQuery } from "react-responsive";
import bottomBgr from "../../asset/bottom_bg.png";
import { factoryColor } from "../../constants/Colors";
import thunderIcon from "../../asset/thunder.png";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { myFont } from "../../constants/Fonts";
import {
  WhiteSpan,
  BlackSpan,
  GreenSpan,
  GreenSpanUnderline,
  CommonType,
} from "../../constants/Styles";
import { RoundButton } from "../../components/Buttons/RoundButton";

export const BottomPad = () => {
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:501px) and (max-width: 1439px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width:500px)",
  });
  const openQuestionModal = () => {
    setConsultingModal({
      show: true,
      onPress: () => {},
    });
  };

  const [consultingModal, setConsultingModal] = useRecoilState(
    ConsultingRequestModalState
  );
  return (
    <MainCtnr>
      <AlignCtnr>
        <Title>
          잠깐만 써보셔도
          {isMobile || isTablet ? <br /> : " "}
          차이를 아실거예요.
        </Title>
        <SubTitle>
          관리가 필요 없는 공장 관리를
          {isMobile || isTablet ? <br /> : " "}
          경험해보세요!
        </SubTitle>
        <MainBtnCtnr>
          <RoundButton
            isActive={true}
            onClick={openQuestionModal}
            fontColor={factoryColor.textPrimary}
            bgrColor={factoryColor.white}
          >
            도입 상담 <span style={{ fontWeight: "800" }}>{" >"}</span>
          </RoundButton>
        </MainBtnCtnr>
      </AlignCtnr>
    </MainCtnr>
  );
};

export const Icon = styled.img`
  width: 20px;
  margin-right: 15px;
`;

const MainCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  padding-top: 110px;
  padding-bottom: 110px;
  background-color: ${factoryColor.mainGreen};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Pixel.mobileWidth}) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
`;

const AlignCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlack};

  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};

  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const Title = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 5px;
  color: ${factoryColor.primaryLight};
  opacity: 0.8;
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 7vw;
  }
`;

const SubTitle = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  color: ${factoryColor.white};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  @media (max-width: ${Pixel.mobileWidth}) {
    margin-top: 15px;
    font-size: 7vw;
  }
`;

const MainBtnCtnr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 15px;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;

  @media (max-width: ${Pixel.mobileWidth}) {
    justify-content: center;
    flex-direction: column;
    width: 90%;
  }
`;

interface BtnProp {
  bgrColor: string;
  fontColor: string;
}

export const MainBtn = styled.button<BtnProp>`
  font-family: "Pretandard-Regular";
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 150px;
  height: 50px;

  background-color: ${({ bgrColor }) => bgrColor};
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
  color: ${({ fontColor }) => fontColor};
  opacity: 1;
  margin-right: 50px;

  &:hover {
    opacity: 0.65;
  }
  border-radius: 30px;

  @media (max-width: 1440px) {
    height: 50px;
    width: 150px;
    font-size: 18px;
    border-radius: 30px;
  }

  @media (max-width: 500px) {
    width: 60%;
    height: auto;
    padding-top: 2.5vw;
    margin-right: 4vw;
    padding-bottom: 2.5vw;
    border-radius: 4.5vw;
  }
`;
