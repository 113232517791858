import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { factoryColor } from "../../constants/Colors";
import { Border, Percent, Pixel } from "../../constants/Pixel";
import { myFont } from "../../constants/Fonts";
import bookIcon from "../../asset/rental_book.png";
import imgDesktop from "../../asset/rental_book_desktop.png";
import imgTablet from "../../asset/rental_book_tablet.png";
import imgMobile from "../../asset/rental_book_mobile.png";

export const RentalBook = () => {
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:501px) and (max-width: 1439px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width:500px)",
  });

  return (
    <MainCtnr>
      <AlignCtnr>
        <CategoryCtnr>
          <Icon src={bookIcon} />
          임대장부 관리
        </CategoryCtnr>
        <Title>임대장부 관리하기 힘드셨죠?</Title>
        <SubTitle>
          이제 임대료, 관리비 연체 내역을
          <br /> 한 눈에 확인 할 수 있어요.
        </SubTitle>
        {isPc && (<ScreenShot src = {imgDesktop} />)}
        {isTablet && (<ScreenShot src = {imgTablet} />)}
        {isMobile && (<ScreenShot src = {imgMobile} />)}
      </AlignCtnr>
    </MainCtnr>
  );
};

export const Icon = styled.img`
  width: 20px;
  margin-right: 15px;
`;

const CategoryCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 20px;
  font-weight: 700;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
`;

const MainCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  padding-top: 110px;
  padding-bottom: 110px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
  }

`;

const AlignCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlack};
  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const Title = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 400;
  margin-bottom: 5px;
  color: ${factoryColor.textPrimary};
  box-sizing: border-box;
  border: ${Border.zeroPxBlue};

  @media (max-width: ${Pixel.desktopWidth}) {
    font-size: 40px;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 7vw;
  }
`;

const SubTitle = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};

  @media (max-width: ${Pixel.desktopWidth}) {
    font-size: 40px;
  }
  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 7vw;
  }
`;

export const ScreenShot = styled.img`
  width: 100%;
  margin-top: 45px;
  margin-bottom: 45px;
  border: ${Border.zeroPxBlue};
  @media (max-width: ${Pixel.mobileWidth}) {
    margin-top: 30px;
    margin-bottom: 0px;
  }
`;
