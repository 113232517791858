import styled from "styled-components";
import { _client } from "../apis/client";
import { ColFlex, RowFlex, FlexV2 } from "../constants/Flex";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { CommonType } from "../constants/Styles";
import { factoryColor } from "../constants/Colors";

export interface factoryData {
  created_at: string;
  factory_address_doro: string;
  factory_address_jibun: string;
  factory_name: string;
  id: number;
  owner_name: string;
  owner_phone_number: string;
  owner_user_id: string;
  updated_at: string;
  all_managers_name: string;
}

export const PasswordChangeTab = () => {
  const isTablitOrPhone = useMediaQuery({
    query: "(max-width: 784px)",
  });

  const [currentPw, setCurrentPw] = useState<string>("");
  const [newPw, setNewPw] = useState<string>("");
  const [newAgainPw, setNewAgainPw] = useState<string>("");
  const [loginBtnText, setLoginBtnText] = useState<string>("비밀번호 변경하기");
  const [btnStatus, setBtnStatus] = useState<boolean>(false);

  const focustInputText = () => {
    setLoginBtnText("비밀번호 변경하기");
  };

  useEffect(() => {
    if (currentPw && newPw && newAgainPw) {
      if (currentPw.length > 0 && newPw.length > 0 && newAgainPw.length > 0) {
        setBtnStatus(true);
      }
    }
  }, [currentPw, newPw, newAgainPw]);

  const handleClick = () => {
    if (currentPw.length < 7) {
      alert("기존 비밀번호를 입력해주세요. (8자리 이상)");
    } else if (newPw.length < 7) {
      alert("새 비밀번호를 입력해주세요. (8자리 이상)");
    } else if (newAgainPw.length < 7) {
      alert("새 비밀번호를 입력해주세요. (8자리 이상)");
    } else if (newPw !== newAgainPw) {
      alert("입력하신 새 비밀번호가 일치하지 않습니다.");
    } else {
      setLoginBtnText("비밀번호를 변경중입니다...");
      setBtnStatus(false);
      _client
        .postApiWithToken("/auth/reset_password", {
          old_pw: currentPw,
          new_pw: newPw,
          phone: localStorage.getItem("realizableUserPhoneNumber"),
        })
        .then((response) => {
          console.log(response);
          setLoginBtnText("비밀번호 변경하기");
          setBtnStatus(true);
          alert(response.msg)
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setLoginBtnText("비밀번호 변경하기");
          setBtnStatus(true);
        });
    }
  };

  return (
    <>
      <FlexV2
        direction="column"
        className="PwChangeCtnr"
        width="100%"
        etc="border: 1px solid #D7DBE2;margin-top: 20px;"
      >
        <RowFlex
          className="TabTitleRow"
          width="100%"
          justifyContent="flex-start"
          paddingHorizontal="30px"
          paddingVertical="15px"
          etc="background: #F9FAFC;font-size: 20px;color: #121D2E;font-weight: 700;"
        >
          비밀번호 변경
        </RowFlex>
        <FlexV2
          className="PwChangeBodyColumn"
          direction="column"
          paddingHorizontal="30px"
          paddingVertical="15px"
          width="100%"
          etc="border: 0px solid blue;"
        >
          <FlexV2
            className="CurrentPwColumn"
            direction={isTablitOrPhone ? "row" : "column"}
            alignItems={isTablitOrPhone ? "center" : "flex-start"}
            justifyContent={isTablitOrPhone ? "flex-start" : ""}
            etc="border: 0px solid red;"
            width={isTablitOrPhone ? "100%" : "50%"}
          >
            <RowFlex
              className="title"
              fontSize="20px"
              fontWeight="700"
              color="#121D2E"
              paddingVertical="15px"
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              기존 비밀번호
            </RowFlex>
            <RowFlex
              className="title"
              fontSize="17px"
              fontWeight="400"
              color="#7F8A9B"
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              본인 확인을 위해 기존 비밀번호를 입력하세요
            </RowFlex>
            <RowFlex
              className="contents"
              fontSize="15px"
              alignItems="flex-start"
              justifyContent="flex-start"
              etc="gap: 15px;border: 0px solid blue;"
              width={isTablitOrPhone ? "70%" : "100%"}
            >
              <RowFlex
                width={isTablitOrPhone ? "100%" : "80%"}
                justifyContent="flex-start"
                backgroundColor="#ffffff"
                etc="margin-top: 10px;border: 1px solid #D7DBE2;border-radius: 8px;"
                className="content"
              >
                <InputText
                  color={currentPw ? "#48484a" : "#d1d1d6"}
                  value={currentPw}
                  onChange={(e) => setCurrentPw(e.target.value)}
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  onFocus={() => focustInputText()}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleClick();
                    }
                  }}
                />
              </RowFlex>
            </RowFlex>
          </FlexV2>
          <FlexV2
            className="CurrentPwColumn"
            direction={isTablitOrPhone ? "row" : "column"}
            alignItems={isTablitOrPhone ? "center" : "flex-start"}
            justifyContent={isTablitOrPhone ? "flex-start" : ""}
            etc="border: 0px solid red;"
            width={isTablitOrPhone ? "100%" : "50%"}
          >
            <RowFlex
              className="title"
              fontSize="20px"
              fontWeight="700"
              color="#121D2E"
              paddingVertical="15px"
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              etc="margin-top: 15px;"
            >
              새 비밀번호
            </RowFlex>
            <RowFlex
              className="title"
              fontSize="17px"
              fontWeight="400"
              color="#7F8A9B"
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              8자 이상의 새 비밀번호를 입력하세요.
            </RowFlex>
            <RowFlex
              className="contents"
              fontSize="15px"
              alignItems="flex-start"
              justifyContent="flex-start"
              etc="gap: 15px;border: 0px solid blue;"
              width={isTablitOrPhone ? "70%" : "100%"}
            >
              <RowFlex
                width={isTablitOrPhone ? "100%" : "80%"}
                justifyContent="flex-start"
                backgroundColor="#ffffff"
                etc="margin-top: 10px;border: 1px solid #D7DBE2;border-radius: 8px;"
                className="content"
              >
                <InputText
                  color={newPw ? "#48484a" : "#d1d1d6"}
                  value={newPw}
                  onChange={(e) => setNewPw(e.target.value)}
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  onFocus={() => focustInputText()}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleClick();
                    }
                  }}
                />
              </RowFlex>
            </RowFlex>
          </FlexV2>
          <FlexV2
            className="CurrentPwColumn"
            direction={isTablitOrPhone ? "row" : "column"}
            alignItems={isTablitOrPhone ? "center" : "flex-start"}
            justifyContent={isTablitOrPhone ? "flex-start" : ""}
            etc="border: 0px solid red;"
            width={isTablitOrPhone ? "100%" : "50%"}
          >
            <RowFlex
              className="title"
              fontSize="20px"
              fontWeight="700"
              color="#121D2E"
              paddingVertical="15px"
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              etc="margin-top: 15px;"
            >
              비밀번호 확인
            </RowFlex>
            <RowFlex
              className="title"
              fontSize="17px"
              fontWeight="400"
              color="#7F8A9B"
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              신규 비밀번호를 다시 한 번 입력하세요.
            </RowFlex>
            <RowFlex
              className="contents"
              fontSize="15px"
              alignItems="flex-start"
              justifyContent="flex-start"
              etc="gap: 15px;border: 0px solid blue;"
              width={isTablitOrPhone ? "70%" : "100%"}
            >
              <RowFlex
                width={isTablitOrPhone ? "100%" : "80%"}
                justifyContent="flex-start"
                backgroundColor="#ffffff"
                etc="margin-top: 10px;border: 1px solid #D7DBE2;border-radius: 8px;"
                className="content"
              >
                <InputText
                  color={newAgainPw ? "#48484a" : "#d1d1d6"}
                  value={newAgainPw}
                  onChange={(e) => setNewAgainPw(e.target.value)}
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  onFocus={() => focustInputText()}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleClick();
                    }
                  }}
                />
              </RowFlex>
            </RowFlex>
          </FlexV2>
          <FlexV2
            className="CurrentPwColumn"
            direction={isTablitOrPhone ? "row" : "column"}
            alignItems={isTablitOrPhone ? "center" : "flex-start"}
            justifyContent={isTablitOrPhone ? "flex-start" : ""}
            width={isTablitOrPhone ? "100%" : "50%"}
            etc="margin-top: 15px;"
          >
            <SubmitBtnCtnr>
              <SubmitBtn onClick={handleClick}>{loginBtnText}</SubmitBtn>
            </SubmitBtnCtnr>
          </FlexV2>
        </FlexV2>
      </FlexV2>
    </>
  );
};

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  margin-bottom: 5px;
  width: 80%;
`;

const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const InputText = styled.input`
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  outline: none;
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;

  color: ${(props) => props.color};
  &:focus {
    border: 2px solid #008128;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
