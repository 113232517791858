import React from "react";
import styled from "styled-components";
import { factoryColor } from "../../constants/Colors";
import { Pixel } from "../../constants/Pixel";

export type RoundBtnProps = {
  isActive?: boolean;
  etc?: string;
  bgrColor: string;
  fontColor: string;
  onClick: () => void;
  children: React.ReactNode;
};

export const RoundButton: React.FC<RoundBtnProps> = ({ ...props }) => {
  const { isActive, onClick } = props;
  return (
    <StyledRoundButton
      bgrColor={props.bgrColor}
      fontColor={props.fontColor}
      disabled={!isActive}
      onClick={onClick}
    >
      {props.children}
    </StyledRoundButton>
  );
};

interface BtnProp {
  bgrColor: string;
  fontColor: string;
  etc?: string;
}

const StyledRoundButton = styled.button<BtnProp>`
  font-family: "Pretandard-Regular";
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 150px;
  height: 50px;

  background-color: ${({ bgrColor }) => bgrColor};
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
  color: ${({ fontColor }) => fontColor};
  opacity: 1;

  &:hover {
    opacity: 0.65;
  }
  border-radius: 30px;

  @media (max-width: ${Pixel.desktopWidth}) {
    height: 50px;
    width: 150px;
    font-size: 18px;
    border-radius: 30px;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 60%;
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 100px;
  }
`;

export default RoundButton;
