import styled from "styled-components";
import { factoryColor } from "../constants/Colors";
import leftArrow from "../asset/left-arrow.png";
import rightArrow from "../asset/right-arrow.png";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { myFont } from "../constants/Fonts";
import {
  JustifyType,
  alignItemType,
  textAlign,
  CommonType,
  alignContentType,
} from "../constants/Styles";
import { RentalBookSelector } from "../components/Dropdown/RentalBookDropdown";
import { RentalBookAtMonth } from "../components/RentalBook/RentalBookAtMonth";
import { useRecoilState } from "recoil";
import {
  CurrenlyManagingFactories,
  ManagerTabState,
} from "../store/ManagerPageStores";
import { InfoManage } from "./InfoManageTab";
import { ElecManage } from "./ElecManageTab";
import useSWR from "swr";
import { _client } from "../apis/client";
import { useEffect } from "react";
import { BtnCtnr, FlexCtnr } from "./FacilityScreen";
import {
  AddFactoryModalState,
  SendKakaoWelcomeModalState,
} from "../store/ModalStores";
import { ElecMapping } from "./ElecMappingTab";

export interface factoryData {
  created_at: string;
  factory_address_doro: string;
  factory_address_jibun: string;
  factory_name: string;
  id: number;
  owner_name: string;
  owner_phone_number: string;
  owner_user_id: string;
  updated_at: string;
  all_managers_name: string;
}

export const ManagerScreen = () => {
  const [tab, setTab] = useRecoilState(ManagerTabState);
  const [myFactories, setMyFactories] = useRecoilState(
    CurrenlyManagingFactories
  );

  const [welcomeModal, setWelcomeModal] = useRecoilState(
    SendKakaoWelcomeModalState
  );

  const {
    data: myFac,
    error: myErr,
    isLoading: myLoad,
    mutate: myMutate,
  } = useSWR("/manager/my_factory/list", async (url) => {
    return await _client.postApiWithToken(url, {});
  });

  const {
    data: otherFac,
    error: otherErr,
    isLoading: otherLoad,
    mutate: otherMutate,
  } = useSWR("/manager/other_factory/list", async (url) => {
    return await _client.postApiWithToken(url, {});
  });

  useEffect(() => {
    myMutate();
    otherMutate();
    // setMyFactories({ myFactories: myFac.data });
  }, [myFac, tab, otherFac, myFactories]);

  function clickAdd(factoryId: number) {
    _client
      .postApiWithToken("/manager/my_factory/add", {
        factory_id: factoryId,
      })
      .then((res) => {
        myMutate();
        otherMutate();
        alert("[공장장]\n" + res.msg);

        // setMyFactories({ myFactories: res.data });
      })
      .catch((err) => {
        alert("추가에 실패했습니다.");
      });
  }

  function clickDel(factoryId: number) {
    _client
      .postApiWithToken("/manager/my_factory/del", {
        factory_id: factoryId,
      })
      .then((res) => {
        // setMyFactories({ myFactories: res.data });
        myMutate();
        otherMutate();
        alert("[공장장]\n" + res.msg);
      })
      .catch((err) => {
        alert("추가에 실패했습니다.");
      });
  }

  function tabClick(tabID: number) {
    setTab({
      currentTab: tabID,
    });
  }

  const [addFactoryModal, setAddFactoryModal] =
    useRecoilState(AddFactoryModalState);

  const openAddFactoryModal = () => {
    setAddFactoryModal({
      show: true,
    });
  };

  const openWelcomeKakaoModal = () => {
    setWelcomeModal({
      show: true,
    });
  };

  return (
    <BelowHeaderAboveFooterCtnr>
      <InnerPaddingCtnr>
        <TitleCtnr>관리자</TitleCtnr>
        <TabCtnr>
          <TabItem onClick={() => tabClick(0)} active={tab.currentTab === 0}>
            관리공장 설정
          </TabItem>
          <TabItem onClick={() => tabClick(1)} active={tab.currentTab === 1}>
            청구서 생성
          </TabItem>
          <TabItem onClick={() => tabClick(2)} active={tab.currentTab === 2}>
            한전고객 설정
          </TabItem>
          <TabItem onClick={() => tabClick(3)} active={tab.currentTab === 3}>
            한전청구서 조회
          </TabItem>
        </TabCtnr>
        {/* <BodyWrapper active={false}> */}
        <ManageTabWrapper active={tab.currentTab === 0}>
          <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
            <BtnCtnr
              row={true}
              etc="font-weight: 600; align-items: center;width: 150px;height: 50px;border:1px solid #2EA566;margin-top: 20px;border-radius: 10px;background-color:#E9F9F0;color: #2EA566;"
              onClick={() => openAddFactoryModal()}
            >
              + 신규 공장 추가
            </BtnCtnr>
            <BtnCtnr
              row={true}
              etc="font-weight: 600; align-items: center;width: 150px;height: 50px;border:1px solid #FAE100;margin-top: 20px;border-radius: 10px;background-color:#FAE100;color: #371D1E;"
              onClick={() => openWelcomeKakaoModal()}
            >
              + 웰컴 카톡 발송
            </BtnCtnr>
          </div>
          <BodyTitle>내가 관리중인 공장</BodyTitle>
          <BodySubTitle>
            추가 / 제외 버튼을 이용하여 관리 중인 공장을 설정합니다.
          </BodySubTitle>
          <ItemCtnr>
            {myFac?.data?.map((item: factoryData) => (
              <FactoryItem key={item.id}>
                <ItemTitle>
                  <div>{item.factory_name}</div>
                  <ItemAddDel onClick={() => clickDel(item.id)}>
                    제외
                  </ItemAddDel>
                </ItemTitle>
                <ContentsCtnr>
                  <InfoRow>
                    <GrayColorP>임대인 | </GrayColorP> {item.owner_name}
                  </InfoRow>
                  <InfoRow>
                    <GrayColorP>공장 소재지 | </GrayColorP>{" "}
                    {item.factory_address_doro}
                  </InfoRow>
                  <InfoRow>
                    <GrayColorP>현 관리자 | </GrayColorP>{" "}
                    {item.all_managers_name}
                  </InfoRow>
                </ContentsCtnr>
              </FactoryItem>
            ))}
          </ItemCtnr>
        </ManageTabWrapper>
        {tab.currentTab === 0 && <Bar />}
        <ManageTabWrapper active={tab.currentTab === 0}>
          <BodyTitle>그 외 공장</BodyTitle>
          <BodySubTitle>
            추가 / 제외 버튼을 이용하여 관리 중인 공장을 설정합니다.
          </BodySubTitle>
          <ItemCtnr>
            {otherFac?.data?.map((item: factoryData) => (
              <FactoryItem key={item.id}>
                <ItemTitle>
                  <div>{item.factory_name}</div>
                  <ItemAddDel onClick={() => clickAdd(item.id)}>
                    추가
                  </ItemAddDel>
                </ItemTitle>
                <ContentsCtnr>
                  <InfoRow>
                    <GrayColorP>임대인 | </GrayColorP> {item.owner_name}
                  </InfoRow>
                  <InfoRow>
                    <GrayColorP>공장 소재지 | </GrayColorP>{" "}
                    {item.factory_address_doro}
                  </InfoRow>
                  <InfoRow>
                    <GrayColorP>현 관리자 | </GrayColorP>{" "}
                    {item.all_managers_name}
                  </InfoRow>
                </ContentsCtnr>
              </FactoryItem>
            ))}
          </ItemCtnr>
        </ManageTabWrapper>
        {tab.currentTab === 1 && <InfoManage />}
        {tab.currentTab === 2 && <ElecMapping />}
        {tab.currentTab === 3 && <ElecManage />}
      </InnerPaddingCtnr>
    </BelowHeaderAboveFooterCtnr>
  );
};

const BelowHeaderAboveFooterCtnr = styled.div`
  font-family: ${myFont.regular};
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  ${CommonType.paddingTop} 80px;
  ${CommonType.paddingBottom} 80px;
  background-color: #ffffff;
`;

const InnerPaddingCtnr = styled.div`
  ${CommonType.borderBox}
  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: ${JustifyType.fromStart};
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const TitleCtnr = styled.div`
  ${CommonType.displayRowFlex}
  padding-bottom: 5px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: ${alignContentType.center};
  align-items: ${alignItemType.center};
  text-align: ${textAlign};
  overflow: hidden;
  font-size: 40px;
  font-weight: 700;
  color: ${factoryColor.textPrimary};
`;

const TabCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContenStart}
  ${CommonType.alignItemsCenter}
  margin-top: 10px;
  font-size: 15px;
  border-bottom: 1px solid ${factoryColor.border_secondary};
  gap: 30px;
`;

const ItemFillSpace = styled.div`
  display: flex;
  flex-grow: 1;
`;

interface tabProps {
  active?: boolean;
}

const TabItem = styled.div<tabProps>`
  ${CommonType.flexColumnDirection}
  ${CommonType.justifyContentCenter}
  ${CommonType.alignItemsCenter}
  height: 100%;

  font-size: 18px;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.83px;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;

  color: ${(props) => (props.active ? "black" : "#a6a6a6")};
  border-bottom: ${(props) => (props.active ? "2px solid black" : "")};

  :hover {
    color: black;
    border-bottom: 2px solid black;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 100%;
  }
`;

const ManageTabWrapper = styled.div<tabProps>`
  ${(props) => (props.active ? "display:flex;" : "display:none;")}
  ${CommonType.flexColumnDirection}
  ${CommonType.justifyContenStart}
  ${CommonType.alignItemsStart};
`;

const BodyTitle = styled.div`
  font-size: 20px;
  ${CommonType.fontWeight700}
  color: black;
  margin-top: 30px;
`;
const BodySubTitle = styled.div`
  font-size: 15px;
  ${CommonType.fontWeight500}
  color: ${factoryColor.descriptionSecondary};
  margin-top: 10px;
`;

const ItemCtnr = styled.div`
  ${CommonType.displayRowFlex}
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 15px;
  font-size: 20px;
`;

const FactoryItem = styled.div`
  ${CommonType.displayColumnFlex}
  padding: 20px;
  border-radius: 20px;
  background-color: #f9fafc;
  width: 550px;
  @media (max-width: ${Pixel.mobileWidth}) {
    ${CommonType.flexGrow}
    width: 70%;
  }
`;

const ItemTitle = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.fontWeight700}
  ${CommonType.justifyContentSpaceBetween}
  border-bottom: 2px solid #d7dbe2;
  padding-bottom: 10px;
  font-size: 20px;
`;

const ItemAddDel = styled.div`
  color: ${factoryColor.mainGreen};
  ${CommonType.fontWeight500}
  font-size:
  font-size: 18px;
  cursor: pointer;
  opacity: 1;
  :hover {
    font-weight: 700;
    opacity: 0.5;
  }
`;

const ContentsCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  gap: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const InfoRow = styled.div`
  ${CommonType.displayRowFlex}
  color: black;
  font-size: 20px;
  gap: 10px;
  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 18px;
  }
`;

const GrayColorP = styled.div`
  display: flex;
  color: ${factoryColor.card_title};
`;

const Bar = styled.div`
  display: flex;
  ${CommonType.flexGrow}
  background-color: #c0c7d2;
  margin-top: 30px;
  margin-bottom: 10px;
  height: 3px;
`;
