import axios, { AxiosInstance, AxiosResponse } from "axios";
import { serverSetting } from "./setting";

const BASE_URL = `${serverSetting.https ? "https" : "http"}://${
  serverSetting.host
}:${serverSetting.port.toString()}`;

const PathLogin = (type: string) => {
  return `auth/login/${type}`;
};

const PathJoin = (type: string) => {
  return `auth/register/${type}`;
};

export interface PhoneAuthParamProp {
  phone: string;
  verify_code: string | undefined;
}

export enum PhoneAuthType {
  CREATE = "create",
  VERIFY = "verify",
}

const PathPhoneAuth = (action: PhoneAuthType, params: PhoneAuthParamProp) => {
  if (action === PhoneAuthType.CREATE)
    return `auth/phone_auth/${action}?phone_number=${params.phone}`;
  else
    return `auth/phone_auth/${action}?phone_number=${params.phone}&auth_number=${params.verify_code}`;
};

export const Path = {
  login: PathLogin,
  join: PathJoin,
  phone_auth: PathPhoneAuth,
  reset_password: "auth/reset_password_generated",
  update_password: "auth/reset_password",
  unregister: "auth/unregister",
  verify_token: "auth/token/verify",
  me: "auth/me",
};

export default class Client {
  private _client: AxiosInstance;

  constructor() {
    this._client = axios.create({
      baseURL: BASE_URL,
    });
  }

  public async fetchApi(path?: string): Promise<any> {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: "",
        },
      };
      const res = await this._client.get(path || "", config);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async fetchApiWithToken(path?: string): Promise<any> {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: "",
        },
      };
      let token = localStorage.getItem("accessToken");
      if (token) config.headers.Authorization = `Bearer ${token}`;
      const res = await this._client.get(path || "", config);
      return res.data;
    } catch (err: any) {
      if (err.response === undefined) {
        return Promise.reject("네트워크 에러");
      }
      return Promise.reject(err.response.data.error);
    }
  }

  public async postApi(path?: string, data?: any): Promise<any> {
    let res: AxiosResponse<any>;
    try {
      res = await this._client.post(path || "", data);
      return res.data;
    } catch (error: any) {
      console.error(error);
      if (error.response === undefined) {
        return Promise.reject("네트워크 에러");
      }
      console.log(error.response);
      return Promise.reject(error.response.data.error);
    }
  }

  public async getApi(path?: string, data?: any): Promise<any> {
    let res: AxiosResponse<any>;
    try {
      res = await this._client.get(path || "", data);
      return res.data;
    } catch (error: any) {
      console.log("[getAPI0111]")
      console.error(error);
      if (error.response === undefined) {
        return Promise.reject("네트워크 에러");
      }
      console.log("[getAPI0116]")
      console.log(error.response);
      return Promise.reject(error.response.error);
    }
  }

  public async getApiWithToken(path?: string, data?: any): Promise<any> {
    let res: AxiosResponse<any>;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: "",
        },
      };
      let token = localStorage.getItem("accessToken");
      if (token) config.headers.Authorization = `Bearer ${token}`;

      //AXIOS는 get에 body data 인자 없음
      res = await this._client.get(path || "", config);
      return res.data;
    } catch (error: any) {
      console.log("[getAPI0111]")
      console.error(error);
      if (error.response === undefined) {
        return Promise.reject("네트워크 에러");
      }
      console.log("[getAPI0116]")
      console.log(error.response);
      return Promise.reject(error.response.error);
    }
  }


  public async postApiV2(path?: string, data?: any): Promise<any> {
    let res: AxiosResponse<any>;
    try {
      res = await this._client.post(path || "", data);
      return res.data;
    } catch (error: any) {
      console.error(error);
      if (error.response === undefined) {
        return Promise.reject("네트워크 에러");
      }
      console.log(error.response);
      return Promise.reject(error.response);
    }
  }

  public async postApiWithToken(path?: string, data?: any): Promise<any> {
    let res: AxiosResponse<any>;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: "",
        },
      };

      let token = localStorage.getItem("accessToken");
      if (token) config.headers.Authorization = `Bearer ${token}`;
      res = await this._client.post(path || "", data, config);
      return res.data;
    } catch (error: any) {
      console.error(error.respose);
      if (error.response === undefined) {
        return Promise.reject("네트워크 에러");
      }
      return Promise.reject(error.response.data.error);
    }
  }

  public async putApiWithToken(path?: string, data?: any): Promise<any> {
    let res: AxiosResponse<any>;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: "",
        },
      };
      let token = localStorage.getItem("accessToken");
      if (token) config.headers.Authorization = `Bearer ${token}`;
      res = await this._client.put(path || "", data, config);
      return res.data;
    } catch (error: any) {
      console.error(error.respose);
      if (error.response === undefined) {
        return Promise.reject("네트워크 에러");
      }
      return Promise.reject(error.response.data.error);
    }
  }

  public async putApiWithTokenV2(path?: string, data?: any): Promise<any> {
    let res: AxiosResponse<any>;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: "",
        },
      };
      let token = localStorage.getItem("accessToken");
      if (token) config.headers.Authorization = `Bearer ${token}`;
      res = await this._client.put(path || "", data, config);
      return res.data;
    } catch (error: any) {
      console.error(error.respose);
      if (error.response === undefined) {
        return Promise.reject("네트워크 에러");
      }
      return Promise.reject(error.response);
    }
  }
}

export const _client = new Client();
