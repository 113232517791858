import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  AddFactoryModalState,
  GlobalLoadingState,
} from "../../store/ModalStores";
import { alignItemType, CommonType, JustifyType } from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import closeIcon from "../../asset/close-circle.png";

export type NoticeModalProps = {};

export const AddFactoryModal = () => {
  const [thisModal, setThisModal] = useRecoilState(AddFactoryModalState);
  const resetThisModal = useResetRecoilState(AddFactoryModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const [factoryName, setFactoryName] = useState("");
  const [factoryAddrDoro, setFactoryAddrDoro] = useState("");
  const [factoryAddrJibun, setFactoryAddrJibun] = useState("");
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState("");
  const [ownerName, setOwnerName] = useState("");

  //   "factory_name": "AAA",
  //   "factory_address_doro": "DORO",
  //   "factory_address_jibun": "JIBUN",
  //   "owner_phone_number": "01099999999",
  //   "owner_name": "KIM"

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const sendRequest = () => {
    let regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    if (ownerPhoneNumber?.length < 10) {
      alert("휴대폰 번호를 확인해주세요. (숫자만 입력)");
    } else {
      if (regExp.test(ownerPhoneNumber)) {
        setLoadingModal({
          isLoading: true,
          message: "공장을 추가하고 있습니다.",
        });
        _client
          .postApiWithToken("/manager/add_new_factory", {
            factory_name: factoryName,
            factory_address_doro: factoryAddrDoro,
            factory_address_jibun: factoryAddrJibun,
            owner_phone_number: ownerPhoneNumber,
            owner_name: ownerName,
          })
          .then((response) => {
            resetLoadingModal();
            alert(response.msg);
            resetThisModal();
          })
          .catch((err) => {
            resetLoadingModal();
            alert(err);
          });
      } else {
        alert("휴대폰 번호를 확인해주세요. (숫자만 입력)");
      }
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ItemCtnr>
                <ItemTitle>공장 이름</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={factoryName}
                    onChange={(e) => setFactoryName(e.target.value)}
                    disabled={false}
                    placeholder="공장의 이름을 입력하세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>도로명 주소</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={factoryAddrDoro}
                    onChange={(e) => setFactoryAddrDoro(e.target.value)}
                    disabled={false}
                    placeholder="도로명 주소를 입력해주세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>지번 주소</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={factoryAddrJibun}
                    onChange={(e) => setFactoryAddrJibun(e.target.value)}
                    disabled={false}
                    placeholder="지번 주소를 입력해주세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>공장주 이름</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={ownerName}
                    onChange={(e) => setOwnerName(e.target.value)}
                    disabled={false}
                    placeholder="공장주 이름을 입력하세요."
                  />
                </ItemInputCtnr>
                <ItemTitle>공장주 연락처 (숫자만)</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={ownerPhoneNumber}
                    onChange={(e) => setOwnerPhoneNumber(e.target.value)}
                    disabled={false}
                    placeholder="공장주 휴대폰 번호를 입력하세요 (숫자만)"
                  />
                </ItemInputCtnr>
              </ItemCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => sendRequest()}>
                  공장 추가하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "300px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
`;

const ItemCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const ItemTitle = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  margin-top: 20px;
`;

const ItemInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;
