import styled from "styled-components";
import { factoryColor } from "../constants/Colors";
import leftArrow from "../asset/left-arrow.png";
import rightArrow from "../asset/right-arrow.png";
import refreshIcon from "../asset/refresh-icon.png";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { myFont } from "../constants/Fonts";
import { JustifyType, alignItemType, textAlign } from "../constants/Styles";
import { _client } from "../apis/client";
import loadingGif from "../asset/ring-loading.gif";
import useSWR from "swr";
import { useEffect, useState, useRef, CSSProperties } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  ElecInfoSettingModalState,
  GlobalLoadingState,
  LoginModalState,
  TenantContractModalState,
  UserLoginStatusType,
  UserStatus,
} from "../store/ModalStores";
import { Link, Routes, Route, useNavigate } from "react-router-dom";

import useChecksum from "./UseCheckSumHook";
import { useMediaQuery } from "react-responsive";
import ApexChart from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { elecCurrentData, elecSaveData, elecCompareData } from "../constants/MockData";
import { RowFlex, Flex, ColFlex, SelectFlex, FlexImg } from "../constants/Flex";
//이미지 포함 Blog Contents Embed (코드포함) - 아래 방식으로 진행 (2023.08.29)
import { iframeCode } from "../blog_contents/blog_contents_2023_001/blog_contents_2023_001";

const factoryList = ["리얼라이저블 공장"];

export interface clientInfo {
  고객명: string;
  업종명: string;
  고객번호: string;
  계기번호: string;
  계약종별: string;
  계약전력: string;
  저압고압구분: string;
  계기배수: number;
  정기검침일: string;
  조회상태: string;
}

export interface reqInfo {
  청구요금: string;
  청구년월: string;
  기본요금: string;
  전기요금계: string;
  조회상태: string;
}

export interface dailyUsageInfo {
  경부하: number;
  중부하: number;
  최대부하: number;
}

export interface monthlyUsageInfo {
  경부하: number;
  중부하: number;
  최대부하: number;
  조회기간: string;
}

export interface singleElecDashboardData {
  table_id: number;
  고객정보: clientInfo;
  청구정보: reqInfo;
  일일사용량: dailyUsageInfo;
  월별사용량: monthlyUsageInfo;
}

const sampleDataList: Array<singleElecDashboardData> = [
  {
    table_id: -1,
    고객정보: {
      고객명: "리얼라이저블 공장",
      업종명: "금속 OO부품 제조업",
      고객번호: "1111000001",
      계기번호: "00000011111",
      계약종별: "산업용(을)고압A",
      계약전력: "500 (KW)",
      계기배수: 100,
      저압고압구분: "고압",
      정기검침일: "매월 20일",
      조회상태: "정상",
    },
    청구정보: {
      청구요금: "10,000,000 원",
      기본요금: "1,000,000 원",
      전기요금계: "9,000,000 원",
      청구년월: "2023년 5월 (20일)",
      조회상태: "정상",
    },
    일일사용량: {
      경부하: 440,
      중부하: 521,
      최대부하: 418,
    },
    월별사용량: {
      경부하: 5520,
      중부하: 6151,
      최대부하: 7710,
      조회기간: "2023년 5월 16일 ~ 2023년 6월 5일",
    },
  },
];

export const ElecInfoScreen = () => {
  const today = new Date();
  const navigate = useNavigate();
  // sampleData[0].table_id = -1;
  // sampleData[0].고객정보.고객명 = "리얼라이저블 공장";
  // sampleData[0].고객정보.고객번호 = "1111000001";
  // sampleData[0].고객정보.계약종별 = "산업용(을)고압A";
  // sampleData[0].고객정보.업종명 = "금속 OO부품 제조업";
  // sampleData[0].고객정보.계약전력 = "500 (KW)";
  // sampleData[0].고객정보.저압고압구분 = "고압";
  // sampleData[0].고객정보.정기검침일 = "매월 20일";
  // sampleData[0].고객정보.조회상태 = "정상";
  // sampleData[0].청구정보.청구요금 = "10,000,000 원";
  // sampleData[0].청구정보.기본요금 = "1,000,000 원";
  // sampleData[0].청구정보.전기요금계 = "9,000,000 원";
  // sampleData[0].청구정보.청구년월 = "2023년 5월 (20일)";
  // sampleData[0].청구정보.조회상태 = "정상";
  // sampleData[0].일일사용량.경부하 = 440;
  // sampleData[0].일일사용량.중부하 = 521;
  // sampleData[0].일일사용량.최대부하 = 418;
  // sampleData[0].월별사용량.경부하 = 3177;
  // sampleData[0].월별사용량.중부하 = 4098;
  // sampleData[0].월별사용량.최대부하 = 1003;

  const [currentYear, setCurrentYear] = useState<number>(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(today.getMonth());
  const [prevMonth, setPrevMonth] = useState<number>(today.getMonth() - 1);
  const [nextMonth, setNextMonth] = useState<number>(today.getMonth() + 1);
  const [currentDay, setCurrentDay] = useState<number>(today.getDate());
  const [prevDay, setPrevDay] = useState<number>(today.getDate() - 1);
  const [nextDay, setNextDay] = useState<number>(today.getDate() + 1);

  const [currentDate, setCurrentDate] = useState<Date>(today);
  const [userStatus, setUserStatus] = useRecoilState(UserStatus);
  const [loginModal, setLoginModal] = useRecoilState(LoginModalState);
  const [elecSettingModal, setElecSettingModal] = useRecoilState(ElecInfoSettingModalState);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [lastLookupTime, setLastLookupTime] = useState<string>(
    today.getHours().toString() + "시 " + today.getMinutes().toString() + "분 " + today.getSeconds().toString() + "초"
  );
  const moveMonth = (offset: number) => {
    currentDate.setMonth(currentDate.getMonth() + offset);
    setCurrentDate(currentDate);
    setCurrentYear(currentDate.getFullYear());
    setCurrentMonth(currentDate.getMonth());
    if (currentDate.getMonth() - 1 === -1) {
      setPrevMonth(11);
    } else {
      setPrevMonth(currentDate.getMonth() - 1);
    }
    if (currentDate.getMonth() + 1 === 12) {
      setNextMonth(0);
    } else {
      setNextMonth(currentDate.getMonth() + 1);
    }
  };

  useEffect(() => {
    fetch();
  }, [currentMonth, currentDay]);

  const {
    data: dashboardData,
    error: fetchError,
    isLoading: dataIsLoading,
    isValidating: dataIsFetching,
    mutate: fetch,
  } = useSWR("/elec_info/dashboard", async (url) => {
    // setLoading(true);
    return await _client.postApiWithToken(url, {
      yyyymmdd:
        currentYear.toString() +
        (currentMonth + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        currentDay.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
    });
    // .finally(() => setLoading(false));
  });

  const isLoggedIn = localStorage.getItem("realizableLoginStatus") === UserLoginStatusType.LOGGEDIN ? true : false;

  const gotoPrevDay = () => {
    //1일 또는 마지막날에는 다음날짜 클릭 불가
    if (currentDay > 1) {
      setCurrentDay(currentDay - 1);
      setPrevDay(prevDay - 1);
      setNextDay(nextDay - 1);
    } else {
      alert("1일 입니다. 이전 달 조회시 상단에 '월'을 변경해주세요.");
    }
  };

  const checkIfItsLastDay = () => {
    var d = new Date(currentYear, currentMonth, 0);
    if (nextDay === d.getDate() + 1) {
      return true;
    } else {
      return false;
    }
  };
  const gotoNextDay = () => {
    //1일 또는 마지막날에는 다음날짜 클릭 불가
    var d = new Date(currentYear, currentMonth, 0);
    if (currentDay < d.getDate()) {
      setCurrentDay(currentDay + 1);
      setPrevDay(prevDay + 1);
      setNextDay(nextDay + 1);
    } else {
      alert("이달의 마지막 날 입니다. 다음달 조회시 상단에 '월'을 변경해주세요.");
    }
  };

  const [dashboardDataList, setDashboardDataList] = useState<Array<singleElecDashboardData>>(sampleDataList);
  const [currentDashboardData, setCurrentDashboardData] = useState<singleElecDashboardData>(sampleDataList[0]);
  const [currentDataId, setCurrentDataId] = useState<Number>(-1);
  const [currentCustomerName, setCurrentCustomerName] = useState<string>(sampleDataList[0].고객정보.고객명);
  const [customerNameList, setCustomerNameList] = useState<Array<string>>([sampleDataList[0].고객정보.고객명]);

  const setNewCustomer = (name: string) => {
    dashboardDataList.forEach((e) => {
      if (e.고객정보.고객명 === name) {
        setCurrentDataId(e.table_id);
        setCurrentCustomerName(e.고객정보.고객명);
        setCurrentDashboardData(e);
      }
    });
  };

  const [realtimeDataUrl, setRealtimeDataUrl] = useState<string>("");

  const isTablitOrPhone = useMediaQuery({
    query: "(max-width: 785px)",
  });

  // const gotoGrafana = (url: string) => {
  //   // window.open("https://graph.xn--ob0b391ca.com:3000");
  //   window.open(
  //     "https://graph.xn--ob0b391ca.com:3000/d/sUKO9QaVz/7IOY7ZSMIOyghOugpSDrjbDsnbTthLA?orgId=1&refresh=5s&from=now-5m&to=now"
  //   );
  // };

  const openElecInfoSetting = () => {
    if (userStatus.loginStatusType !== UserLoginStatusType.LOGGEDIN) {
      alert("로그인이 필요한 서비스입니다.");
      setLoginModal({
        show: true,
        onPress: () => {},
      });
    } else if (userStatus.loginStatusType === UserLoginStatusType.LOGGEDIN) {
      if (userStatus.userPhoneNumber !== null) {
        setElecSettingModal({
          show: true,
          phone_number: userStatus.userPhoneNumber,
          elec_register_number: "",
          customer_type: "개인사업자",
          company_register_number_head: "",
          company_register_number_tail: "",
          birthday_number: "",
          is_phone_number_editable: false,
          agreement: false,
          from_customer: true,
        });
      }
    }
  };

  const [currentEpochTime, setCurrentEpochTime] = useState(Date.now()); // 남은 시간 (단위: 초)

  const getXminuteBefore = (x: number, currentEpochTime: number) => {
    return currentEpochTime - x * 1000 * 60;
  };

  const [prev5MinEpochTime, setPrev5MinEpochTime] = useState(getXminuteBefore(5, currentEpochTime)); // 남은 시간 (단위: 초)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentEpochTime((prev) => prev + 1);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  // 1분마다 자동 업데이트 // 현재 Disable
  // useEffect(() => {
  //   const refreshTimer = setInterval(() => {
  //     console.log("auto refresh");
  //     fetch();
  //   }, 60000);
  //   return () => clearInterval(refreshTimer);
  // }, []);

  useEffect(() => {
    // 실제 데이터가 들어온 경우 & 값이 존재하는 경우에만 해당 데이터로 변경
    if (dashboardData?.data?.length > 0) {
      setDashboardDataList(dashboardData.data);

      // 최초 데이터 로딩시 0번 데이터로 셋업
      if (currentDataId === -1) {
        setCurrentDashboardData(dashboardData.data[0]);
        setCurrentDataId(dashboardData.data[0].table_id);
        setCurrentCustomerName(dashboardData.data[0].고객정보.고객명);
      } else {
        // 이후 데이터 로딩시 ID로 타겟 찾아서 데이터만 업데이트
        dashboardData.data.forEach((e: singleElecDashboardData) => {
          if (e.table_id === currentDataId) {
            setCurrentDashboardData(e);
          }
        });
      }

      let customerNameListBuffer: string[] = [];
      dashboardDataList.forEach((e) => {
        customerNameListBuffer.push(e.고객정보.고객명);
      });
      setCustomerNameList(customerNameListBuffer);
      setLastLookupTime(
        today.getHours().toString() +
          "시 " +
          today.getMinutes().toString() +
          "분 " +
          today.getSeconds().toString() +
          "초"
      );
    } else if (dashboardData?.data?.length === 0) {
      alert("등록된 공장이 없습니다. 공장 추가 버튼을 통해 공장을 추가해주세요.");
    }
  }, [dataIsLoading, dashboardDataList, dashboardData, currentMonth, currentDay, isLoading]);

  function isOdd(n: number) {
    if (n === 0) {
      return false;
    } else if (n % 2 === 1) {
      return true;
    } else {
      return false;
    }
  }

  const dailyLoadOptions: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    title: {
      text: "일일 사용량",
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2],
    },
    stroke: {
      width: [0, 0, 0],
    },
    labels: ["일일 사용량"],
    colors: ["#4CAF50", "#4894EC", "#FB6E65"],
    yaxis: {
      title: {
        text: "kWh",
      },
      min: 0,
    },
  };

  const monthyLoadOptions: ApexOptions = {
    chart: {
      height: 350,
    },
    title: {
      text: "월별 사용량",
    },
    labels: ["이번달 사용량"],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2, 3, 4, 5],
    },

    stroke: {
      width: [0, 0, 0, 0, 0, 0],
    },
    colors: ["#4CAF50", "#4894EC", "#FB6E65", "#4CAF50", "#4894EC", "#FB6E65"],
    yaxis: {
      title: {
        text: "kWh",
      },
      forceNiceScale: false,
      min: 0,
    },
  };
  const dailyLoad = [
    {
      name: "경부하",
      type: "column",
      data: [currentDashboardData.일일사용량.경부하],
    },
    {
      name: "중간부하",
      type: "column",
      data: [currentDashboardData.일일사용량.중부하],
    },
    {
      name: "최대부하",
      type: "column",
      data: [currentDashboardData.일일사용량.최대부하],
    },
  ];

  const mmseries = [
    {
      name: "경부하",
      type: "column",
      data: [currentDashboardData.월별사용량.경부하],
    },
    {
      name: "중간부하",
      type: "column",
      data: [currentDashboardData.월별사용량.중부하],
    },
    {
      name: "최대부하",
      type: "column",
      data: [currentDashboardData.월별사용량.최대부하],
    },
  ];

  var htmlFile = require("./blog_contents_2023_001/blog_contents_2023_001").default;

  useEffect(() => {
    // console.log(currentEpochTime);
    setPrev5MinEpochTime(getXminuteBefore(5, currentEpochTime));
    // console.log(prev5MinEpochTime);
    let src_url =
      "https://graph.xn--ob0b391ca.com:3000/d-solo/sUKO9QaVz/7IOY7ZSMIOyghOugpSDrjbDsnbTthLA?orgId=1&refresh=5s&from=" +
      prev5MinEpochTime.toString() +
      "&to=" +
      currentEpochTime.toString() +
      "&theme=light&panelId=2";
    setRealtimeDataUrl(src_url);
  }, [currentEpochTime]);

  return (
    <>
      {" "}
      {/* {dataIsFetching && <div style={backgroudStyle} />} */}
      {dataIsFetching && (
        <>
          <div
            style={{
              padding: "15px",
              borderRadius: "30px",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "auto",
              alignItems: "center",
              height: "auto",
              fontSize: "20px",
              color: "#118849",
              textAlign: "center",
            }}
          >
            한전 응답 대기중... 최대 1분이 소요됩니다.
            <img
              style={{
                backgroundColor: "white",
                marginTop: "15px",
                width: "30px",
                height: "30px",
              }}
              src={loadingGif}
              alt="loadingGif"
            />
          </div>
        </>
      )}
      <ColFlex
        className="OuterDiv"
        alignItems="center"
        justifyContent="center"
        etc="padding-top:40px;padding-bottom:0px;"
        backgroundColor="ffffff"
      >
        <HorizontalPaddingCtnr className="HeaderPaddingAlignDiv">
          <FactoryTitleCtnr className="TitlePartOuterDiv" onClick={() => navigate(-1)}>
            전력 정보
          </FactoryTitleCtnr>
          {/* <RowFlex>
            <div
              dangerouslySetInnerHTML={{ __html: iframeCode }}
              style={{ width: "100%", height: "1000px" }}
            ></div>
          </RowFlex> */}
          <RowFlex
            className="SelectorRowOuterDiv"
            justifyContent="flex-start"
            etc="margin-top: 10px;margin-bottom:10px;gap: 20px;"
          >
            <RowFlex
              className="MonthSelectorCtnr"
              width="400px"
              etc="padding: 7px;border:1px solid #D7DBE2;border-radius:10px;"
            >
              <ArrowIcon src={leftArrow} />
              {/* <MoveMonth onClick={() => setCurrentMonth(currentMonth - 1)}> */}
              <MoveMonth onClick={() => moveMonth(-1)}>{(prevMonth + 1).toString()}월</MoveMonth>
              <CurrentMonth>
                {currentYear.toString()}년 {(currentMonth + 1).toString()}월
              </CurrentMonth>
              <MoveMonth onClick={() => moveMonth(+1)}>{(nextMonth + 1).toString()}월</MoveMonth>
              <ArrowIcon src={rightArrow} />
            </RowFlex>
            <RowFlex
              className="FactSelectorCtnr"
              width="140px"
              etc="padding: 5px;border:1px solid #D7DBE2;border-radius:10px;"
            >
              <FactoryOption
                fontSize={"13px"}
                mobileFontSize={"15px"}
                fontWeight={"500"}
                value={currentCustomerName}
                onChange={(event) => {
                  setNewCustomer(event.target.value);
                }}
              >
                {customerNameList.map((element: string) => {
                  return <option key={element}>{element}</option>;
                })}
              </FactoryOption>
            </RowFlex>
            <ElecSettingBtnCtnr onClick={() => openElecInfoSetting()}>+ 공장 추가</ElecSettingBtnCtnr>
            <RowFlex justifyContent="flex-end">마지막 조회한 시간 {lastLookupTime}</RowFlex>
            <ColFlex
              className="FullScreenCtnr"
              width="150px"
              alignItems="flex-end"
              etc="padding: 5px;border:0px solid #D7DBE2;border-radius:10px;"
            >
              <RefreshIcon src={refreshIcon} onClick={fetch} />
            </ColFlex>
          </RowFlex>
          <ColFlex className="BodyOuterCtnrDiv" etc="border: 1px solid #D7DBE2;">
            <TitleCtnr>한전 고객명: {currentDashboardData.고객정보.고객명}</TitleCtnr>
            <CustomerInfoCtnr isMobileView={isTablitOrPhone}>
              <ColFlex
                className="EachInfoCtnrDiv"
                height="240px"
                width={isTablitOrPhone ? "90%" : "50%"}
                backgroundColor="#F9FAFC"
                etc="margin: 15px;border: 1px solid #D7DBE2;"
              >
                <ColFlex
                  className="EachInfoItemTitle"
                  width="100%"
                  etc="border-bottom: 1px solid #D7DBE2;font-size: 18px;"
                >
                  고객 정보{" "}
                </ColFlex>
                <ColFlex
                  className="EachInfoBodyCtnr"
                  width="100%"
                  etc="gap: 10px;padding-top: 15px;padding-bottom: 15px;"
                >
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" etc="padding-left: 5%;" justifyContent="flex-start" color="#7F8A9B">
                      고객 번호
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.고객정보.고객번호}
                    </RowFlex>
                  </RowFlex>
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" color="#7F8A9B" etc="padding-left: 5%;" justifyContent="flex-start">
                      계약종별
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.고객정보.계약종별}
                    </RowFlex>
                  </RowFlex>
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" etc="padding-left: 5%;" justifyContent="flex-start" color="#7F8A9B">
                      업종명
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.고객정보.업종명}
                    </RowFlex>
                  </RowFlex>
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" color="#7F8A9B" etc="padding-left: 5%;" justifyContent="flex-start">
                      계약전력
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.고객정보.계약전력}
                    </RowFlex>
                  </RowFlex>
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" etc="padding-left: 5%;" justifyContent="flex-start" color="#7F8A9B">
                      저/고압 구분
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.고객정보.저압고압구분}
                    </RowFlex>
                  </RowFlex>
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" etc="padding-left: 5%;" color="#7F8A9B" justifyContent="flex-start">
                      조회상태
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.고객정보.조회상태}
                    </RowFlex>
                  </RowFlex>
                </ColFlex>
              </ColFlex>
              <ColFlex
                className="EachInfoCtnrDiv"
                height="240px"
                width={isTablitOrPhone ? "90%" : "50%"}
                backgroundColor="#F2F4F7"
                etc="margin: 15px;border: 1px solid #D7DBE2;"
              >
                <ColFlex
                  className="EachInfoItemTitle"
                  width="100%"
                  backgroundColor="D7DBE2"
                  etc="border-bottom: 1px solid #D7DBE2;font-size: 18px;"
                >
                  청구 정보{" "}
                </ColFlex>
                <ColFlex
                  className="EachInfoBodyCtnr"
                  width="100%"
                  etc="gap: 10px;padding-top: 25px;padding-bottom: 25px;"
                >
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" etc="padding-left: 5%;" justifyContent="flex-start" color="#7F8A9B">
                      청구 요금
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.청구정보.청구요금}
                    </RowFlex>
                  </RowFlex>
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" color="#7F8A9B" etc="padding-left: 5%;" justifyContent="flex-start">
                      기본 요금
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.청구정보.기본요금}
                    </RowFlex>
                  </RowFlex>
                  {/* <RowFlex
                  className="EachInfoRow"
                  width="100%"
                  justifyContent="space-between"
                >
                  <RowFlex
                    width="30%"
                    etc="padding-left: 5%;"
                    justifyContent="flex-start"
                    color="#7F8A9B"
                  >
                    전력량요금
                  </RowFlex>
                  <RowFlex
                    width="70%"
                    justifyContent="flex-start"
                    etc="padding-left: 10%;"
                  >
                    3,010,287 원
                  </RowFlex>
                </RowFlex> */}
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="30%" color="#7F8A9B" etc="padding-left: 5%;" justifyContent="flex-start">
                      전기 요금 계
                    </RowFlex>
                    <RowFlex width="70%" justifyContent="flex-start" etc="padding-left: 10%;">
                      {currentDashboardData.청구정보.전기요금계}
                    </RowFlex>
                  </RowFlex>
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="40%" etc="padding-left: 5%;" color="#7F8A9B" justifyContent="flex-start">
                      청구년월 (정기검침일)
                    </RowFlex>
                    <RowFlex width="60%" justifyContent="flex-start" etc="padding-left: 0%;">
                      {currentYear}년 {currentMonth + 1}월 ({currentDashboardData.고객정보.정기검침일})
                    </RowFlex>
                  </RowFlex>
                  <RowFlex className="EachInfoRow" width="100%" justifyContent="space-between">
                    <RowFlex width="40%" etc="padding-left: 5%;" color="#7F8A9B" justifyContent="flex-start">
                      조회 상태
                    </RowFlex>
                    <RowFlex width="60%" justifyContent="flex-start" etc="padding-left: 0%;">
                      {currentDashboardData.청구정보.조회상태}
                    </RowFlex>
                  </RowFlex>
                  {/* <DetailBtnCtnr>상세 내역 확인</DetailBtnCtnr> */}
                </ColFlex>
              </ColFlex>
            </CustomerInfoCtnr>
            <Flex className="GraphRow" flex={isTablitOrPhone ? "column" : "row"} width="100%" height="400px">
              <ColFlex className="LeftSideGraph" width={isTablitOrPhone ? "100%" : "50%"} height="100%">
                <RowFlex
                  className="DaySelectorCtnr"
                  alignItems="center"
                  justifyContent="flex-end"
                  width="100%"
                  height="10%"
                >
                  <DaySelectCtnr>
                    <DaySelector>
                      <ArrowIcon src={leftArrow} />
                      <MoveMonth onClick={() => gotoPrevDay()}>
                        {prevDay === 0 ? "전월" : prevDay.toString() + "일"}
                      </MoveMonth>
                      <CurrentMonth>
                        {currentDay === today.getDate() ? "오늘 (" + currentDay.toString() + "일)" : currentDay + "일"}
                      </CurrentMonth>
                      <MoveMonth onClick={() => gotoNextDay()}>
                        {checkIfItsLastDay() ? "다음달" : nextDay + "일"}
                      </MoveMonth>
                      <ArrowIcon src={rightArrow} />
                    </DaySelector>
                  </DaySelectCtnr>
                </RowFlex>
                <RowFlex className="GraphA" width="100%" height="90%">
                  <div style={{ width: "100%", height: "400px" }}>
                    <ReactApexChart options={dailyLoadOptions} series={dailyLoad} type="line" height={350} />
                  </div>
                </RowFlex>
              </ColFlex>
              <ColFlex className="RightSideGraph" width={isTablitOrPhone ? "100%" : "50%"} height="100%">
                <ColFlex width="100%" height="40%" className="elecTableSecondItem">
                  <RowFlex etc="border: 0px solid red;">
                    <CircleCtnr color="#FCBD00" />
                    <div style={{ paddingRight: "15px" }}>
                      이번달 사용량 ({currentDashboardData.월별사용량.조회기간})
                    </div>
                    {/* <RefreshIcon src={refreshIcon} /> */}
                    {/* <TableContainer>
                    <InfoTable width="100%">
                      <Thead>
                        <Tr>
                          <Th>항목</Th>
                          <Th>사용량 (kWh)</Th>
                        </Tr>
                      </Thead>
                      <tbody>
                        {elecCurrentData.map((item, index) => {
                          return (
                            <AbTbodyTr
                              key={item.id}
                              backgroundColor={
                                isOdd(index) ? "#fff" : "#f4f4f5"
                              }
                            >
                              <AbTbodyTd>{item.month}</AbTbodyTd>
                              <AbTbodyTd>{item.data}</AbTbodyTd>
                            </AbTbodyTr>
                          );
                        })}
                      </tbody>
                    </InfoTable>
                  </TableContainer> */}
                  </RowFlex>
                </ColFlex>
                <RowFlex className="GraphB" width="100%" height="90%">
                  <div style={{ width: "100%", height: "auto" }}>
                    <ReactApexChart options={monthyLoadOptions} series={mmseries} height="330" />
                  </div>
                </RowFlex>
              </ColFlex>
            </Flex>
            {/* <RowFlex
            width="100%"
            etc="border: 0px solid red;margin-bottom: 20px;"
          >
            <ColFlex width="50%" className="elecTableFirstItem">
              <RowFlex etc="border: 0px solid red;">
                <CircleCtnr color="#C7E5D5" />
                <div> 공장장 추천 가동시간 </div>
                <TableContainer>
                  <InfoTable width="100%">
                    <Thead>
                      <Tr>
                        <Th>추천 가동 시간</Th>
                        <Th>예상 절감 전력 (kWh)</Th>
                        <Th>예상 절감 요금 (원)</Th>
                      </Tr>
                    </Thead>
                    <tbody>
                      {elecSaveData.map((item, index) => {
                        return (
                          <AbTbodyTr
                            key={item.id}
                            backgroundColor={isOdd(index) ? "fff" : "#f4f4f5"}
                          >
                            <AbTbodyTd>{item.time}</AbTbodyTd>
                            <AbTbodyTd>{item.saveElec}</AbTbodyTd>
                            <AbTbodyTd>{item.saveMoney}</AbTbodyTd>
                          </AbTbodyTr>
                        );
                      })}
                    </tbody>
                  </InfoTable>
                </TableContainer>
              </RowFlex>
            </ColFlex>
            <ColFlex width="50%" className="elecTableSecondItem">
              <RowFlex etc="border: 0px solid red;">
                <CircleCtnr color="#003C3C" />
                <div> 사용량 비교 (전월 대비) </div>
                <TableContainer>
                  <InfoTable width="100%">
                    <Thead>
                      <Tr>
                        <Th>월</Th>
                        <Th>사용량 (kWh)</Th>
                      </Tr>
                    </Thead>
                    <tbody>
                      {elecCurrentData.map((item, index) => {
                        return (
                          <AbTbodyTr
                            key={item.id}
                            backgroundColor={isOdd(index) ? "#fff" : "#f4f4f5"}
                          >
                            <AbTbodyTd>{item.month}</AbTbodyTd>
                            <AbTbodyTd>{item.data}</AbTbodyTd>
                          </AbTbodyTr>
                        );
                      })}
                    </tbody>
                  </InfoTable>
                </TableContainer>
              </RowFlex>
            </ColFlex>
          </RowFlex> */}
            {/* <iframe
            src="https://graph.xn--ob0b391ca.com:3000/d-solo/sUKO9QaVz/7IOY7ZSMIOyghOugpSDrjbDsnbTthLA?orgId=1&refresh=5s&from=1682332903113&to=1682333203113&theme=light&panelId=6"
            width="100%"
            height="400px"
          ></iframe> */}
            {/* <RowCtnr>
              <iframe
                src="https://www.notioniframe.com/notion/1x952cft293"
                width="100%"
                height="400px"
              ></iframe>
            </RowCtnr> */}
            <RedirectBtnCtnr>설비별 가동량 (잠김) - 별도 문의</RedirectBtnCtnr>
            {/* <RowCtnr>
              <iframe
                title="facilityA"
                src="https://graph.xn--ob0b391ca.com:3000/d-solo/sUKO9QaVz/7IOY7ZSMIOyghOugpSDrjbDsnbTthLA?orgId=1&refresh=5s&from=1682312055876&to=1682333655876&theme=light&panelId=8"
                width="33.3%"
                height="200px"
              ></iframe>
              <iframe
                title="facilityB"
                src="https://graph.xn--ob0b391ca.com:3000/d-solo/sUKO9QaVz/7IOY7ZSMIOyghOugpSDrjbDsnbTthLA?orgId=1&refresh=5s&from=1682312425864&to=1682334025864&theme=light&panelId=10"
                width="33.3%"
                height="200px"
              ></iframe>
              <iframe
                title="facilityC"
                src="https://graph.xn--ob0b391ca.com:3000/d-solo/sUKO9QaVz/7IOY7ZSMIOyghOugpSDrjbDsnbTthLA?orgId=1&refresh=5s&from=1682312529062&to=1682334129062&theme=light&panelId=12"
                width="33.3%"
                height="200px"
              ></iframe>
            </RowCtnr> */}
          </ColFlex>
        </HorizontalPaddingCtnr>
      </ColFlex>
    </>
  );
};

const RowCtnr = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  opacity: 1;
`;

export const ArrowIcon = styled.img`
  width: 2.5%;
`;

const HorizontalPaddingCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlack};
  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const FactoryTitleCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-size: 40px;
  font-weight: 700;
  color: ${factoryColor.textPrimary};
  box-sizing: border-box;
  border-bottom: 1px solid ${factoryColor.border_secondary};
`;

const DaySelectCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
`;

const DaySelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 250px;
  padding: 5px;

  font-size: 14px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

const MonthSelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 400px;
  padding: 10px;

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

const MoveMonth = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 20%;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }

  font-weight: 500;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
  border: 0px solid ${factoryColor.border_secondary};
`;

const CurrentMonth = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 52%;

  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border-left: 1px solid ${factoryColor.border_secondary};
  border-right: 1px solid ${factoryColor.border_secondary};
  padding-left: 5%;
  padding-right: 5%;
`;

const FactorySelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.fromStart};
  text-align: ${textAlign.start};
  width: 200px;
  padding: 12px;

  font-size: 18px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

const TitleCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.fromStart};
  align-self: ${alignItemType.fromStart};
  width: 100%;
  padding: 15px;

  text-align: ${textAlign.center};
  overflow: hidden;

  font-size: 18px;
  font-weight: 600;

  color: ${factoryColor.textPrimary};
  background-color: ${factoryColor.bg_primary};
  border-bottom: 1px solid ${factoryColor.border_secondary};

  box-sizing: border-box;
`;

interface mobileView {
  isMobileView?: boolean;
}

const CustomerInfoCtnr = styled.div<mobileView>`
  display: flex;
  flex-direction: ${({ isMobileView }) => (isMobileView ? "column" : "row")};
  width: 100%;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  background-color: white;
`;

const RentalFeeTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  font-size: 20px;
  border-bottom: 1px solid ${factoryColor.border_secondary};
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1.4;
`;

const RentalFeeBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  font-size: 15px;
  padding: 12px;
`;

const RentalFeeRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.center};
`;

const DetailBtnCtnr = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  background-color: #121d2e;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  color: white;
  border-radius: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const ElecSettingBtnCtnr = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #2ea566;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 30px;
  padding-block: 9px;
  padding-inline: 23px;
  width: 85px;

  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const RedirectBtnCtnr = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  fiex-direction: row;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  font-size: 15px;
  background-color: ${factoryColor.halfMainGreen};
  color: ${factoryColor.mainGreen};
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

interface circleOption {
  color: string;
}
const CircleCtnr = styled.div`
  display: flex;
  width: 15px;
  height: 15px;
  flex-direction: row;
  background-color: #c7e5d5;
  background-color: ${({ color }) => color};
  border-radius: 100px;
  margin-right: 15px;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: white;
  font-size: 15px;
  font-weight: 700;
`;

interface tableOption {
  width: string;
}

export const TableContainer = styled.div`
  margin-top: 15px;
  text-align: center;
  width: 100%;
  font-size: 15px;
`;

export const InfoTable = styled.table<tableOption>`
  width: ${({ width }) => width};
  border-collapse: collapse;
  position: relative;
`;

export const Thead = styled.thead`
  font-weight: 500;
  text-align: left;
`;
export const Tr = styled.tr`
  font-weight: 500;
  text-align: left;
  background-color: #2ea566;
  color: white;
  font-size: 15px;
`;
export const Th = styled.th`
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: 500;
  text-align: center;
`;

export interface tbodyProps {
  backgroundColor?: string;
}

export const AbTbodyTr = styled.tr<tbodyProps>`
  background-color: ${(props) => props.backgroundColor};

  &:hover {
    background-color: #e3e3e3;
  }

  @media (max-width: 500px) {
    font-size: 4.5vw;
  }
`;

export interface tdProp {
  width?: string;
  mobileWidth?: string;
}

export const AbTbodyTd = styled.td<tdProp>`
  width: ${(props) => props.width};
  padding: 8px;
  overflow-wrap: break-word;
  padding-top: 1%;
  padding-bottom: 1%;
  @media (max-width: 500px) {
    width: ${(props) => props.mobileWidth};
  }
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const FactoryOption = styled.select<optionProp>`
  background-color: white;
  display: flex;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
  }
`;

const RefreshIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 25px;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.3)",
};
