import React, { CSSProperties } from "react";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  GlobalLoadingState,
  TenantContractModalState,
} from "../../store/ModalStores";
import closeIcon from "../../asset/close-circle.png";
import { factoryColor } from "../../constants/Colors";
import { JustifyType } from "../../constants/Styles";
import loadingGif from "../../asset/ring-loading.gif";

export type GlobalLoadingModalProps = {};

export const GlobalLoadingModal = () => {
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  if (loadingModal.isLoading)
    return (
      <>
        <div style={backgroudStyle} />
        <img
          style={{
            backgroundColor: "transparent",
            position: "fixed",
            width: "50px",
            height: "50px",
            zIndex: "501",
            left: "50%",
            top: "50%",
          }}
          src={loadingGif}
          alt="loadingGif"
        />
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 500,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};