import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  ConsultingRequestModalState,
  GlobalLoadingState,
  SendKakaoModalState,
  SendNoticeModalState,
} from "../../store/ModalStores";
import closeIcon from "../../asset/close-circle.png";
import {
  alignItemType,
  CommonType,
  GreenSpan,
  JustifyType,
} from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { MainLogoImg } from "../landingPage/RealizableHeader";
import { _client } from "../../apis/client";
import logoGreen from "../../asset/logo_green.png";

// template_code_button_name = {'C000000515_00002': '보고서 확인하기', 'C000000515_00030': '청구서 확인하기'}
var templateCodeSendType: { [key: string]: string } = {
  C000000515_00002: "임대보고서",
  C000000515_00030: "관리비 청구서",
};

export const SendKakaoNoticeModal = () => {
  const [thisModal, setThisModal] = useRecoilState(SendKakaoModalState);
  const resetThisModal = useResetRecoilState(SendKakaoModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const [phoneNumber, setPhoneNumber] = useState(
    thisModal.sendTargetPhoneNumber.toString()
  );
  const [targetName, setTargetName] = useState(
    thisModal.sendTargetName.toString()
  );
  const [factoryName, setFactoryName] = useState(
    thisModal.sendFactoryName.toString()
  );
  const [templateCode, setTemplateCode] = useState(
    thisModal.templateCode.toString()
  );
  const [year, setYear] = useState(thisModal.year.toString());
  const [month, setMonth] = useState(thisModal.month.toString());
  const [sendPurposeType, setSendPurposeType] = useState(
    thisModal.sendPurposeType
  );
  const [sendMsg, setSendMsg] = useState(thisModal.defaultText);
  const [buttonUrl, setButtonUrl] = useState(thisModal.sendFileUrl);

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  //_temp_msg = "안녕하세요:)\n공장관리사무소 공장장입니다.\n리얼라이저블공장_김철수 대표님,\n\'23년도 4월 임대보고서를 송부드립니다.\n\n문의가 있으시면 언제든 연락하여 주세요 :)"
  useEffect(() => {
    setPhoneNumber(thisModal.sendTargetPhoneNumber);
    setTargetName(thisModal.sendTargetName);
    setSendPurposeType(thisModal.sendPurposeType);
    setFactoryName(thisModal.sendFactoryName);
    setYear(thisModal.year);
    setMonth(thisModal.month);
    setButtonUrl(thisModal.sendFileUrl);
    setTemplateCode(thisModal.templateCode);
    setSendMsg(
      "안녕하세요:)\n공장관리사무소 공장장입니다.\n" +
        thisModal.sendFactoryName +
        "_" +
        thisModal.sendTargetName +
        " 대표님,\n'" +
        thisModal.year +
        "년도 " +
        thisModal.month +
        "월 " +
        thisModal.sendPurposeType +
        "를 송부드립니다.\n\n문의가 있으시면 언제든 연락하여 주세요 :)"
    );
  }, [thisModal]);

  const sendRequest = (send_type: string) => {
    let regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    if (phoneNumber?.length < 10) {
      alert("휴대폰 번호를 확인해주세요. (숫자만 입력)");
    } else {
      if (regExp.test(phoneNumber)) {
        setLoadingModal({
          isLoading: true,
          message: "내용을 전송중입니다.",
        });
        _client
          .postApi("/request/send_kakao_at", {
            contact: phoneNumber,
            contents: sendMsg,
            template_code: templateCode,
            button_url: buttonUrl,
          })
          .then((response) => {
            resetLoadingModal();
            alert(response.msg);
          })
          .catch((err) => {
            resetLoadingModal();
            alert(err);
          })
          .finally(() => {
            resetThisModal();
          });
      } else {
        alert("휴대폰 번호를 확인해주세요. (숫자만 입력)");
      }
    }
  };

  const inputPhoneNumber = (e: string) => {
    setPhoneNumber(e);
  };

  useEffect(() => {
    setTargetName(targetName);
    setSendMsg(
      "안녕하세요:)\n공장관리사무소 공장장입니다.\n" +
        thisModal.sendFactoryName +
        "_" +
        targetName +
        " 대표님,\n'" +
        thisModal.year +
        "년도 " +
        thisModal.month +
        "월 " +
        thisModal.sendPurposeType +
        "를 송부드립니다.\n\n문의가 있으시면 언제든 연락하여 주세요 :)"
    );
  }, [targetName]);
  // "안녕하세요:)\n공장관리사무소 공장장입니다.\n#{공장이름}_#{공장주이름} #{직위명}님,\n'#{당해년도}년도 #{당월}월 #{청구서유형}를 송부드립니다.\n\n문의가 있으시면 언제든 연락하여 주세요 :)"

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ModalHeader>
                <MainLogoImg src={logoGreen}></MainLogoImg>
              </ModalHeader>
              <UserVerification>
                <UserVerifyDesc>전송 대상 이름</UserVerifyDesc>
                <UserVerifyInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={targetName}
                    onChange={(e) => setTargetName(e.target.value)}
                    disabled={false}
                    placeholder="전송 받을 대상의 이름을 입력하세요."
                  />
                </UserVerifyInputCtnr>
                <UserVerifyDesc>전송할 연락처</UserVerifyDesc>
                <UserVerifyInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={phoneNumber}
                    onChange={(e) => inputPhoneNumber(e.target.value)}
                    disabled={false}
                    placeholder="휴대폰 번호를 입력하세요 (숫자만)"
                  />
                </UserVerifyInputCtnr>
              </UserVerification>
              <ConsultingRequest>
                <ContentDesc>
                  전송할 내용을 확인해주세요. (수정불가)
                </ContentDesc>
                <ContentInputCtnr>
                  {/* <ConsultingOption
                    fontSize={"100%"}
                    mobileFontSize={"3vw"}
                    fontWeight={"500"}
                    value={consultingOption}
                    onChange={(event) => {
                      setConsultingOption(event.target.value);
                    }}
                  >
                    {consultingComments.map((element) => {
                      return <option key={element}>{element}</option>;
                    })}
                  </ConsultingOption> */}
                  {/* <LocalInput
                    type="text"
                    value={contents}
                    onChange={(e) => inputContents(e)}
                    placeholder="문의 내용 입력"
                  /> */}
                  <textarea
                    value={sendMsg}
                    rows={20}
                    cols={100}
                    name="send_message"
                    // onChange={(e) => inputContents(e.target.value)}
                  >
                    {" "}
                    {sendMsg}{" "}
                  </textarea>
                  {/* <MultilineInput
                    placeholder="문의 내용을 입락해주세요."
                    value={contents}
                    id="input001"
                    onChange={(e: any) => inputContents(e)}
                  /> */}
                </ContentInputCtnr>
              </ConsultingRequest>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => sendRequest("kakao")}>
                  카톡 내보내기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "300px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
`;

const ModalHeader = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 70px;
`;
const ModalDescription = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 100px;
  background-color: ${factoryColor.bg_primary};
`;

const UserVerification = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const UserVerifyDesc = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  margin-top: 10px;
`;

const UserVerifyInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const ContentDesc = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  font-size: 15px;
  font-weight: 500;
`;

const ContentInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  font-size: 15px;
  height: auto;
  width: 100%;
  margin-top: 12px;
`;

const ConsultingRequest = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  padding: 20px;
`;
const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const ConsultingOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  height: 100%;
  width: 100%;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;
