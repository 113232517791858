import styled from "styled-components";
import { factoryColor } from "./Colors";
import { Border, Percent, Pixel } from "./Pixel";
import { myFont } from "./Fonts";

export interface flexOption {
  justifyContent?: string;
  alignItems?: string;
  isMobile?: boolean;
  width?: string;
  height?: string;
  paddingVertical?: string;
  paddingHorizontal?: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  backgroundColor?: string;
  etc?: string;
  mobileEtc?: string;
}

export interface flexOptionV2 {
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
  isMobile?: boolean;
  width?: string;
  height?: string;
  paddingVertical?: string;
  paddingHorizontal?: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  gap?: string;
  etc?: string;
  mobileEtc?: string;
}

export const RowFlex = styled.div<flexOption>`
  display: flex;
  flex-direction: row;
  font-family: ${myFont.regular};
  font-stretch: normal;
  font-style: normal;

  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  box-sizing: border-box;
  flex-wrap: wrap;
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  color: ${({ color }) => (color ? color : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "")};
  padding-block: ${({ paddingVertical }) =>
    paddingVertical ? paddingVertical : ""};
  padding-inline: ${({ paddingHorizontal }) =>
    paddingHorizontal ? paddingHorizontal : ""};
  ${({ etc }) => (etc ? etc : "")}

  @media (max-width: ${Pixel.mobileWidth}) {
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }
`;

export const ColFlex = styled.div<flexOption>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  color: ${({ color }) => (color ? color : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "")};
  padding-block: ${({ paddingVertical }) =>
    paddingVertical ? paddingVertical : ""};
  padding-inline: ${({ paddingHorizontal }) =>
    paddingHorizontal ? paddingHorizontal : ""};

  ${({ etc }) => (etc ? etc : "")}

  @media (max-width: ${Pixel.mobileWidth}) {
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }
`;

export interface option {
  flex?: string;
  justifyContent?: string;
  alignItems?: string;
  isMobile?: boolean;
  width?: string;
  height?: string;
  paddingVertical?: string;
  paddingHorizontal?: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  backgroundColor?: string;
  etc?: string;
  mobileEtc?: string;
}
export const Flex = styled.div<option>`
  display: flex;
  flex: 1;
  flex-direction: ${({ flex }) => (flex ? flex : "row")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  color: ${({ color }) => (color ? color : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "")};
  padding-block: ${({ paddingVertical }) =>
    paddingVertical ? paddingVertical : ""};
  padding-inline: ${({ paddingHorizontal }) =>
    paddingHorizontal ? paddingHorizontal : ""};

  ${({ etc }) => (etc ? etc : "")}

  @media (max-width: ${Pixel.mobileWidth}) {
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }
`;

export interface selectFlexOption {
  flexDirection: string;
  justifyContent?: string;
  alignItems?: string;
  isMobile?: boolean;
  width?: string;
  height?: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  backgroundColor?: string;
  etc?: string;
  mobileEtc?: string;
}

export const SelectFlex = styled.div<selectFlexOption>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  box-sizing: border-box;
  ${({ etc }) => (etc ? etc : "")}
  flex-wrap: wrap;
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  color: ${({ color }) => (color ? color : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "")};

  @media (max-width: ${Pixel.mobileWidth}) {
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }
`;

interface ImgProp {
  width?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  desktopEtc?: string;
  tablitEtc?: string;
  mobileEtc?: string;
}

export const FlexImg = styled.img<ImgProp>`
  display: flex;
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;

  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};

  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "")};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "")};

  @media (max-width: ${Pixel.desktopWidth}) {
    ${({ tablitEtc }) => (tablitEtc ? tablitEtc : "")}
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }
`;

export const FlexV2 = styled.div<flexOptionV2>`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "column")};

  border: ${({ border }) => (border ? border : "")};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "")};

  font-family: ${myFont.regular};
  font-stretch: normal;
  font-style: normal;
  gap: ${({ gap }) => (gap ? gap : "")};

  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  box-sizing: border-box;
  flex-wrap: wrap;
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  color: ${({ color }) => (color ? color : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "")};
  padding-block: ${({ paddingVertical }) =>
    paddingVertical ? paddingVertical : ""};
  padding-inline: ${({ paddingHorizontal }) =>
    paddingHorizontal ? paddingHorizontal : ""};
  ${({ etc }) => (etc ? etc : "")}

  @media (max-width: ${Pixel.mobileWidth}) {
    ${({ mobileEtc }) => (mobileEtc ? mobileEtc : "")}
  }
`;
