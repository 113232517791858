import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import { BillModalState, LoginModalState } from "../../store/ModalStores";
import closeIcon from "../../asset/close-circle.png";
import {
  alignItemType,
  CommonType,
  GreenSpan,
  JustifyType,
} from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { MainLogoImg } from "../landingPage/RealizableHeader";
import logoGreen from "../../asset/logo_green.png";

export type ConsultingRequestModalProps = {};
let monthlyReportData: Array<string> = [
  "2023_01_01_AM09_임대차리포트_v1.pdf",
  "2023_01_02_AM09_임대차리포트_v2.pdf",
];
let monthlyTenantBillData: Array<string> = [
  "2023_01_01_AM09_A공장_임차료청구서_v1.pdf",
  "2023_01_02_AM09_B공장_임차료청구서_v1.pdf",
];
let monthlyManageBillData: Array<string> = [
  "2023_01_01_AM09_A공장_관리비청구서_v1.pdf",
  "2023_01_01_AM09_B공장_관리비청구서_v1.pdf",
];
export const BillModal = () => {
  const [billModal, setBillModal] = useRecoilState(BillModalState);
  const resetModal = useResetRecoilState(BillModalState);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loginMsg, setLoginMsg] = useState<string | undefined>();
  const [loginFailMsg, setLoginFailMsg] = useState<string | undefined>();
  const [monthyReports, setMonthyReport] = useState<string>("");
  const [monthyTenantBills, setMonthyTenantBills] = useState<string>("");
  const [monthyManageBills, setMonthyManageBill] = useState<string>("");

  useEffect(() => {
    setLoginMsg(loginFailMsg);
  }, [loginFailMsg]);

  useEffect(() => {
    if (userId && password) setButtonDisable(false);
  }, [userId, password]);

  const focustInputText = () => {
    setLoginMsg(undefined);
  };

  const handleClick = () => {
    billModal.monthyTenantBillData.forEach((value) => {
      if (value.file_name === monthyTenantBills) {
        window.open(value.url);
      }
    });
  };

  const clickRegister = () => {
    console.log("navigate to /register");
  };

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  if (billModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="test"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ModalHeader>
                2023년 {billModal.currentMonth}월 청구서
              </ModalHeader>
              <OptionCtnr>
                <OptionTitle>임대차 리포트</OptionTitle>
                <BillOptions
                  fontSize={"1vw"}
                  mobileFontSize={"3vw"}
                  fontWeight={"500"}
                  value={monthlyReportData}
                  onChange={(event) => {
                    setMonthyReport(event.target.value);
                  }}
                >
                  {billModal.monthyReportData.map((element) => {
                    return (
                      <option key={element.id}>{element.file_name}</option>
                    );
                  })}
                </BillOptions>
              </OptionCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn
                  bgColor={factoryColor.mainGreen}
                  textColor="white"
                  onClick={handleClick}
                >
                  임대차 리포트 확인하기
                </SubmitBtn>
              </SubmitBtnCtnr>
              <OptionCtnr>
                <OptionTitle>임차인 임차료 청구서</OptionTitle>
                <BillOptions
                  fontSize={"1vw"}
                  mobileFontSize={"3vw"}
                  fontWeight={"500"}
                  value={monthyTenantBills}
                  onChange={(event) => {
                    setMonthyTenantBills(event.target.value);
                  }}
                >
                  {billModal.monthyTenantBillData.map((element) => {
                    return (
                      <option key={element.id}>{element.file_name}</option>
                    );
                  })}
                </BillOptions>
              </OptionCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn
                  textColor={factoryColor.mainGreen}
                  bgColor="white"
                  onClick={handleClick}
                  border={"1px solid " + factoryColor.mainGreen}
                >
                  임차인 임차료 청구서 확인하기
                </SubmitBtn>
              </SubmitBtnCtnr>
              <OptionCtnr>
                <OptionTitle>임차인 관리비 청구서</OptionTitle>
                <BillOptions
                  fontSize={"1vw"}
                  mobileFontSize={"3vw"}
                  fontWeight={"500"}
                  value={monthlyManageBillData}
                  onChange={(event) => {
                    setMonthyManageBill(event.target.value);
                  }}
                >
                  {billModal.monthyManageBillData.map((element) => {
                    return (
                      <option key={element.id}>{element.file_name}</option>
                    );
                  })}
                </BillOptions>
              </OptionCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn
                  bgColor={factoryColor.gray}
                  textColor="black"
                  onClick={handleClick}
                >
                  임차인 관리비 청구서 확인하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -50,
  display: "block",
  width: "40px",
  height: "40px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "41%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
`;

const ModalHeader = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 70px;
  border-bottom: 2px solid ${factoryColor.bg_secondary};
`;
const ModalDescription = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 100px;
  background-color: ${factoryColor.bg_primary};
`;

const OptionCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 15px 20px 5px 20px;
  width: 100%;
`;

const OptionTitle = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
`;
const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;

interface btnProp {
  textColor: string;
  bgColor: string;
  border?: string;
}

const SubmitBtn = styled.button<btnProp>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  color: ${({ textColor }) => textColor};
  background-repeat: no-repeat;
  border: ${({ border }) => (border ? border : "none")};
  opacity: 1;
  :hover {
    opacity: 0.5;
  }
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const BillOptions = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  height: 100%;
  width: 100%;

  border-radius: 5px;
  border: solid 1px ${factoryColor.gray};
  background-color: #fff;
  padding: 5px;
  margin-top: 10px;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;
