export const factoryColor = {
    gray: 'rgba(215, 219, 226, 1)',
    red: '#F03E3E',
    yellow: '#F9BB00',
    yellowLight: '#FFF9DB',
    teal: '#12B886',
    white: '#ffffff',
    primaryLight: '#EBFBEE',
    textPrimary: '#121D2E',
    descriptionPrimary: '#354153',
    mainGreen: '#2EA566',
    kakaoYellow: '#F7E600',
    kakaoBrown: '#3A1D1D',
    halfMainGreen: '#E0F3E9',
    descriptionSecondary: '#7F8A9B',
    bg_secondary: '#F2F4F6',
    bg_primary: '#F9FAFC',
    border_secondary: '#D7DBE2',
    card_title: '#c0c7d2'
  };
  