import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  ConsultingRequestModalState,
  ContractDetailModalState,
  GlobalLoadingState,
  ModifyContractDetailModalState,
  SendNoticeModalState,
} from "../../store/ModalStores";
import closeIcon from "../../asset/close-circle.png";
import {
  alignItemType,
  CommonType,
  GreenSpan,
  JustifyType,
} from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { MainLogoImg } from "../landingPage/RealizableHeader";
import { _client } from "../../apis/client";
import callIcon from "../../asset/phone-call.png";
import mailIcon from "../../asset/mail.png";
import { BtnCtnr, FlexCtnr } from "../../screen/FacilityScreen";
import { contractData } from "../../constants/MockData";

export const ContractDetailModal = () => {
  const [thisModal, setThisModal] = useRecoilState(ContractDetailModalState);
  const resetThisModal = useResetRecoilState(ContractDetailModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);
  const [modifyContractModal, setModifyContractModal] = useRecoilState(
    ModifyContractDetailModalState
  );
  const [contents, setContents] = useState("");
  const [consultingOption, setConsultingOption] = useState<string>("공장주");

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const isManager =
    localStorage.getItem("realizableUserType") === "관리인" ? true : false;

  function numberWithCommas(x: string) {
    try {
      return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch {
      return "";
    }
  }

  function dateToKorean(x: string) {
    try {
      if (x.length === 8) {
        return (
          "`" + x[2] + x[3] + "년 " + x[4] + x[5] + "월 " + x[6] + x[7] + "일"
        );
      } else {
        return x;
      }
    } catch {
      return x;
    }
  }

  const openWindow = () => {
    if (thisModal.contract_paper_url === null) {
      alert("'계약 상세 수정하기'에서 계약서 URL을 먼저 등록해주세요.");
    } else {
      window.open(thisModal.contract_paper_url);
    }
  };

  const openContractModify = () => {
    setModifyContractModal({
      show: true,
      factory_id: thisModal.factory_id,
      lookup_month: thisModal.lookup_month,
      lookup_year: thisModal.lookup_year,
      tenant_name: thisModal.tenant_name,
      tenant_company_name: thisModal.tenant_company_name,
      rental_type: thisModal.rental_type,
      rental_deposit: thisModal.rental_deposit,
      rental_fee_due_date: thisModal.rental_fee_due_date,
      tenant_rental_fee: thisModal.tenant_rental_fee,
      tenant_manage_fee: thisModal.tenant_manage_fee,
      contract_start_date: thisModal.contract_start_date,
      contract_end_date: thisModal.contract_end_date,
      contract_paper_url: thisModal.contract_paper_url,
      tenant_phone_number: thisModal.tenant_phone_number,
      agent_phone_number: thisModal.agent_phone_number,
    });
    resetThisModal();
  };

  const formatPhoneNumber = (input: string) => {
    try {
      const cleanInput = input.replaceAll(/[^0-9]/g, "");
      let result = "";
      const length = cleanInput.length;
      if (length === 8) {
        result = cleanInput.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else if (
        cleanInput.startsWith("02") &&
        (length === 9 || length === 10)
      ) {
        result = cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, "$1-$2-$3");
      } else if (
        !cleanInput.startsWith("02") &&
        (length === 10 || length === 11)
      ) {
        result = cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3");
      } else {
        result = "";
      }
      return result;
    } catch {
      return "";
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ModalHeader>계약 상세</ModalHeader>
              <FlexCtnr row={true} etc={"margin: 20px;"}>
                {thisModal.tenant_name} | {thisModal.tenant_company_name}
              </FlexCtnr>
              {/* <FlexCtnr row={true} etc={"margin: 10px;gap: 20px;"}>
                <BtnCtnr
                  row={true}
                  etc={
                    "width: 120px; gap: 10px;color: #2ea566; background-color: #e9f9f0; border: 1px solid #c7e5d5; border-radius: 30px;padding: 15px;"
                  }
                >
                  <Icon src={callIcon} width="15px" />
                  통화
                </BtnCtnr>
                <BtnCtnr
                  row={true}
                  etc={
                    "width: 120px; gap: 10px;color: #2ea566; background-color: #e9f9f0; border: 1px solid #c7e5d5; border-radius: 30px;padding: 15px;"
                  }
                >
                  <Icon src={mailIcon} width="15px" />
                  문자
                </BtnCtnr>
              </FlexCtnr> */}
              <FlexCtnr
                row={false}
                etc={
                  "background-color: #f2f4f6;margin-left: 20px;margin-right: 20px;padding: 15px;border-radius: 20px;gap: 12px;"
                }
              >
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={true}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    임대 유형
                  </FlexCtnr>
                  <FlexCtnr row={true} etc="color:#121d2e">
                    {thisModal.rental_type}
                  </FlexCtnr>
                </FlexCtnr>
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={true}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    보증금
                  </FlexCtnr>
                  <FlexCtnr row={true} etc="color:#121d2e">
                    {numberWithCommas(thisModal.rental_deposit)} (원)
                  </FlexCtnr>
                </FlexCtnr>
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={true}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    납부일
                  </FlexCtnr>
                  <FlexCtnr row={true} etc="color:#121d2e">
                    {thisModal.rental_fee_due_date}
                  </FlexCtnr>
                </FlexCtnr>
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={false}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    월 임대료
                  </FlexCtnr>
                  <FlexCtnr row={false} etc="color:#121d2e">
                    {numberWithCommas(thisModal.tenant_rental_fee)} (원)
                    <div style={{ color: "#7f8a9b", fontSize: "15px" }}>
                      (VAT 제외)
                    </div>
                  </FlexCtnr>
                </FlexCtnr>
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={false}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    월 일반관리비
                  </FlexCtnr>
                  <FlexCtnr row={false} etc="color:#121d2e">
                    {numberWithCommas(thisModal.tenant_manage_fee)} (원)
                    <span style={{ color: "#7f8a9b", fontSize: "15px" }}>
                      (그 외 비용 실비청구)
                    </span>
                  </FlexCtnr>
                </FlexCtnr>
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={true}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    계약 시작일
                  </FlexCtnr>
                  <FlexCtnr row={true} etc="color:#121d2e">
                    {dateToKorean(thisModal.contract_start_date)}
                  </FlexCtnr>
                </FlexCtnr>
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={true}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    계약 종료일
                  </FlexCtnr>
                  <FlexCtnr row={true} etc="color:#121d2e">
                    {dateToKorean(thisModal.contract_end_date)}
                  </FlexCtnr>
                </FlexCtnr>
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={true}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    임차인 연락처
                  </FlexCtnr>
                  <FlexCtnr row={true} etc="color:#121d2e">
                    {formatPhoneNumber(thisModal.tenant_phone_number)}
                    {/* {thisModal.tenant_phone_number[0]}
                    {thisModal.tenant_phone_number[1]}
                    {thisModal.tenant_phone_number[2]}-
                    {thisModal.tenant_phone_number[3]}
                    {thisModal.tenant_phone_number[4]}
                    {thisModal.tenant_phone_number[5]}
                    {thisModal.tenant_phone_number[6]}-
                    {thisModal.tenant_phone_number[7]}
                    {thisModal.tenant_phone_number[8]}
                    {thisModal.tenant_phone_number[9]}
                    {thisModal.tenant_phone_number[10]} */}
                  </FlexCtnr>
                </FlexCtnr>
                <FlexCtnr row={true} justifyContent="flex-start">
                  <FlexCtnr
                    row={true}
                    justifyContent="flex-start"
                    etc="color:#7f8a9b;width: 130px;"
                  >
                    {" "}
                    관리인 연락처
                  </FlexCtnr>
                  <FlexCtnr row={true} etc="color:#121d2e">
                    {formatPhoneNumber(thisModal.agent_phone_number)}
                  </FlexCtnr>
                </FlexCtnr>
              </FlexCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => openWindow()}>
                  계약서 원본 보기
                </SubmitBtn>
              </SubmitBtnCtnr>
              {isManager && (
                <SubmitBtnCtnr>
                  <ModifyBtn onClick={() => openContractModify()}>
                    계약 상세 수정하기
                  </ModifyBtn>
                </SubmitBtnCtnr>
              )}
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "350px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "45%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 16px;
  font-weight: 500;
`;

const ModalHeader = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  color: black;
  font-weight: 700;
  font-size: 20px;
  border-bottom: 2px solid #e6e9ee;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  margin-bottom: 10px;
  :hover {
    opacity: 0.8;
  }
`;

const ModifyBtn = styled.button`
  background-color: white;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  color: ${factoryColor.mainGreen};
  border: 1px solid ${factoryColor.mainGreen};
  background-repeat: no-repeat;
  opacity: 1;
  margin-bottom: 10px;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

export interface iconOption {
  width: string;
}
const Icon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: ${({ width }) => width};
`;
