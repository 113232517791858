import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  LoginModalState,
  UserLoginStatusType,
  UserStatus,
} from "../../store/ModalStores";
import closeIcon from "../../asset/close-circle.png";
import {
  alignItemType,
  CommonType,
  GreenSpan,
  JustifyType,
} from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { MainLogoImg } from "../landingPage/RealizableHeader";
import logoGreen from "../../asset/logo_green.png";
import { useNavigate } from "react-router-dom";
import { Path, _client, PhoneAuthType } from "../../apis/client";

export type ConsultingRequestModalProps = {};

export default interface ApiResponse<T> {
  data: T[] | T;
  msg: string;
  error: string;
}
export interface UserResponse extends ApiResponse<UserData> {}

export interface UserData {
  phone: string;
  email: string;
  full_name: string;
  user_type: string;
  retry: number;
  point: number;
}

export const LoginModal = () => {
  const [loginModal, setLoginModal] = useRecoilState(LoginModalState);
  const resetModal = useResetRecoilState(LoginModalState);

  const [userStatus, setUserStatus] = useRecoilState(UserStatus);
  const resetUserStatus = useResetRecoilState(UserStatus);

  const [buttonDisable, setButtonDisable] = useState(true);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loginMsg, setLoginMsg] = useState<string>("로그인");
  let navigate = useNavigate();

  useEffect(() => {
    if (userId && password) setButtonDisable(false);
  }, [userId, password]);

  const focustInputText = () => {
    setLoginMsg("로그인");
  };

  const handleClick = () => {
    if (userId && password) {
      console.log("로그인 시도중");
      setLoginMsg("로그인 시도중");
      localStorage.setItem(
        "realizableLoginStatus",
        UserLoginStatusType.LOADING
      );
      localStorage.setItem("realizableLoginStatusMsg", "로그인 시도중");
      localStorage.setItem("realizableUserEmail", "");
      localStorage.setItem("realizableUserPhoneNumber", "");
      localStorage.setItem("realizableUserName", "");
      localStorage.setItem("realizableUserType", "");
      setUserStatus({
        loginStatusType: UserLoginStatusType.LOADING,
        loginStatusMsg: "로그인 시도중",
        userEmail: "",
        userPhoneNumber: "",
        userName: "",
        userType: "",
      });
      _client
        .postApi(Path.login("web"), { phone: userId, pw: password })
        .then((response) => {
          console.log("토큰발급 성공");
          setLoginMsg("로그인");
          console.log(response.data[0].access_token);
          const token = response.data[0].access_token;
          localStorage.setItem("accessToken", token);
          _client
            .fetchApiWithToken(Path.me)
            .then((user_response) => {
              console.log("로그인 성공");
              console.log(user_response.data);
              localStorage.setItem(
                "realizableLoginStatus",
                UserLoginStatusType.LOGGEDIN
              );
              localStorage.setItem(
                "realizableUserLoginStatusMsg",
                "로그인 성공"
              );
              localStorage.setItem(
                "realizableUserEmail",
                user_response.data.email
              );
              localStorage.setItem(
                "realizableUserPhoneNumber",
                user_response.data.phone_number
              );
              localStorage.setItem(
                "realizableUserName",
                user_response.data.user_name
              );
              localStorage.setItem(
                "realizableUserType",
                user_response.data.user_type
              );
              setUserStatus({
                loginStatusType: UserLoginStatusType.LOGGEDIN,
                loginStatusMsg: "로그인 성공",
                userEmail: user_response.data.email,
                userPhoneNumber: user_response.data.phone_number,
                userName: user_response.data.user_name,
                userType: user_response.data.user_type,
              });
              resetModal();
            })
            .catch((err) => {
              setLoginMsg("로그인");
              alert(err);
              localStorage.setItem(
                "realizableLoginState",
                UserLoginStatusType.LOGGEDOUT
              );
              localStorage.setItem("realizableLoginStatusMsg", "로그아웃");
              localStorage.setItem("realizableUserEmail", "");
              localStorage.setItem("realizableUserPhoneNumber", "");
              localStorage.setItem("realizableUserName", "");
              localStorage.setItem("realizableUserType", "");
              resetUserStatus();
            });
        })
        .catch((err) => {
          alert(err);
          setLoginMsg("로그인");
          localStorage.setItem(
            "realizableLoginState",
            UserLoginStatusType.LOGGEDOUT
          );
          localStorage.setItem("realizableLoginStatusMsg", "로그아웃");
          localStorage.setItem("realizableUserEmail", "");
          localStorage.setItem("realizableUserPhoneNumber", "");
          localStorage.setItem("realizableUserName", "");
          localStorage.setItem("realizableUserType", "");
          resetUserStatus();
          console.log(err);
        })
        .finally(() => {
          navigate("/");
        });
    }
  };

  const clickRegister = () => {
    resetModal();
    navigate("/register");
  };

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  if (loginModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="test"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ModalHeader>
                <MainLogoImg src={logoGreen}></MainLogoImg>
              </ModalHeader>
              <ModalDescription>
                관리가 필요 없는 <br />
                <LocalGreenSpan>공장 관리의 시작</LocalGreenSpan>
              </ModalDescription>
              <UserVerification>
                <UserVerifyDesc>아이디 (휴대폰 번호)</UserVerifyDesc>
                <UserVerifyInputCtnr>
                  <InputText
                    color={userId ? "#48484a" : "#d1d1d6"}
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="휴대폰 번호를 입력해주세요"
                    onFocus={() => focustInputText()}
                  />
                </UserVerifyInputCtnr>
              </UserVerification>
              <PasswordCtnr>
                <ContentDesc>비밀번호</ContentDesc>
                <ContentInputCtnr>
                  <InputText
                    color={password ? "#48484a" : "#d1d1d6"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="비밀번호를 입력해주세요"
                    onFocus={() => focustInputText()}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleClick();
                      }
                    }}
                  />
                </ContentInputCtnr>
              </PasswordCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={handleClick}>{loginMsg}</SubmitBtn>
              </SubmitBtnCtnr>
              <SubmitBtnCtnr>
                <RegisterBtn onClick={clickRegister}>회원가입</RegisterBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "300px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -50,
  display: "block",
  width: "40px",
  height: "40px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "41%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
`;

const ModalHeader = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 70px;
`;
const ModalDescription = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 100px;
  background-color: ${factoryColor.bg_primary};
`;

const UserVerification = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 15px 20px 5px 20px;
`;

const UserVerifyDesc = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
`;

const UserVerifyInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
`;

const ContentDesc = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  font-size: 15px;
  font-weight: 500;
`;

const ContentInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 12px;
  font-size: 15px;
`;

const PasswordCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  padding: 20px;
`;
const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;

const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

const RegisterBtn = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.textPrimary};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

export const InputText = styled.input`
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  outline: none;
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;

  color: ${(props) => props.color};
  &:focus {
    border: 2px solid #008128;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
