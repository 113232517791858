import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useRecoilState, useResetRecoilState } from "recoil";
import { factoryColor } from "../constants/Colors";
import {
  WhiteSpan,
  BlackSpan,
  GreenSpan,
  GreenSpanUnderline,
  CommonType,
} from "../constants/Styles";
import mainBgr from "../asset/landing-bgr.png";
import mainGraphic from "../asset/main-graphic.png";
// import mainBgr from "../asset/landing_bg.png";
import { Apeal } from "./subLandingScreens/Apeal";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { RentalBook } from "./subLandingScreens/RentalBook";
import { ReviewComment } from "./subLandingScreens/ReviewCard";
import { ReviewCarousel } from "./subLandingScreens/ReviewCarousel";
import { RoundButton } from "../components/Buttons/RoundButton";
import { Facility } from "./subLandingScreens/Facility";
import { Community } from "./subLandingScreens/Community";
import { BottomPad } from "./subLandingScreens/BottomPad";
import {
  ConsultingRequestModalState,
  LoginModalState,
  UserLoginStatusType,
  UserStatus,
} from "../store/ModalStores";
import { factory } from "typescript";
import { FreeRequest } from "./subLandingScreens/FreeRequest";
import { OneWeekProcess } from "./subLandingScreens/OneWeekProcess";
export const LandingScreen = () => {
  const openLoginModal = () => {
    setLoginModal({
      show: true,
      onPress: () => {},
    });
  };

  const openQuestionModal = () => {
    setConsultingModal({
      show: true,
      onPress: () => {},
    });
  };

  const [consultingModal, setConsultingModal] = useRecoilState(
    ConsultingRequestModalState
  );

  const [loginModal, setLoginModal] = useRecoilState(LoginModalState);
  const [userStatus, setUserStatus] = useRecoilState(UserStatus);
  const isMobile = useMediaQuery({
    query: "(max-width:500px)",
  });

  return (
    <LandingWrapper>
      <FirstBgCtnr>
        <FirstMainWrapper>
          <FirstMainCtnr>
            <Title>손쉽게 처리되는</Title>
            <BoldTitle>공장 관리의 모든 것</BoldTitle>
            <SubTitle>
              {isMobile ? <br /> : ""}
              중/소규모 제조 공장들이 선택한 1등 서비스
              {/* 중/소규모 제조 공장들이 선택한{isMobile ? <br /> : " "} 1등 서비스 */}
            </SubTitle>
            {isMobile ? <br /> : ""}
            <MainBtnCtnr>
              {userStatus.loginStatusType !==
                UserLoginStatusType.LOGGEDIN && (
                <RoundButton
                  isActive={true}
                  onClick={openLoginModal}
                  bgrColor={factoryColor.white}
                  fontColor={factoryColor.textPrimary}
                >
                  시작하기
                </RoundButton>
              )}
              <RoundButton
                isActive={true}
                onClick={openQuestionModal}
                bgrColor={factoryColor.textPrimary}
                fontColor={factoryColor.white}
              >
                도입 상담
              </RoundButton>
            </MainBtnCtnr>
          </FirstMainCtnr>
          <FirstMainImage />
        </FirstMainWrapper>
      </FirstBgCtnr>
      <Apeal />
      <ReviewComment />
      <ReviewCarousel />
      <OneWeekProcess />
      <RentalBook />
      <Facility />
      <Community />
      <FreeRequest />
      <BottomPad />
    </LandingWrapper>
  );
};
const LandingWrapper = styled.div`
  flex: 1;
`;

const FirstBgCtnr = styled.div`
  display: flex;
  width: 100vw;
  background-color: ${factoryColor.mainGreen};
`;

const FirstMainWrapper = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentCenter}
  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};

  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    ${CommonType.displayColumnFlex}
    ${CommonType.justifyContentCenter}
    ${CommonType.alignItemsCenter}
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    ${CommonType.displayColumnFlex}
    ${CommonType.justifyContentCenter}
    ${CommonType.alignItemsCenter}
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

//linear-gradient(299.51deg, #40CA80 0%, #2EA565 50%, #10865C 100%)
const FirstMainCtnr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${CommonType.alignItemsStart};
  width: 50%;

  box-sizing: border-box;

  padding-top: 140px;
  padding-bottom: 140px;
  @media (max-width: ${Pixel.desktopWidth}) {
    width: 100%;
    ${CommonType.alignItemsCenter}
    padding-top: 80px;
    padding-bottom: 80px;
  }
  background-color: transparent;
  @media (max-width: ${Pixel.mobileWidth}) {
    padding-top: 0px;
    padding-bottom: 30px;
  }
`;
const FirstMainImage = styled.div`
  border: ${Border.zeroPxRed};
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 50%;

  box-sizing: border-box;

  padding-top: 140px;
  padding-bottom: 140px;

  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    url(${mainGraphic});
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 50px;

  @media (max-width: ${Pixel.desktopWidth}) {
    justify-content: center;
    width: 80%;
    height: auto;
    margin-top: 0px;
    background-size: contain;
    background-position: center;
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    margin-top: 10px;
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
  }
`;

const Title = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 500;
  color: white;

  box-sizing: border-box;
  border: ${Border.zeroPxRed};

  @media (max-width: ${Pixel.mobileWidth}) {
    padding-top: 50px;
    font-size: 8.5vw;
    line-height: 2;
    font-weight: 500;
  }
`;

const BoldTitle = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 50px;
  font-weight: 700;
  color: white;

  box-sizing: border-box;
  border: ${Border.zeroPxRed};
  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 35px;
    font-weight: 750;
  }
`;

const SubTitle = styled.div`
  font-family: "Pretandard-Regular";
  font-size: 20px;
  font-weight: 500;
  color: white;
  line-height: 3;

  box-sizing: border-box;
  border: ${Border.zeroPxRed};

  @media (max-width: ${Pixel.mobileWidth}) {
    font-size: 5vw;
    line-height: 1.1;
    font-weight: 500;
  }
`;

const MainBtnCtnr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  overflow: hidden;
  gap: 50px;

  box-sizing: border-box;

  @media (max-width: ${Pixel.mobileWidth}) {
    justify-content: center;
    flex-direction: column;
    ${CommonType.alignItemsCenter}
    gap: 4vw;
    width: 90%;
  }
`;

interface BtnProp {
  bgrColor: string;
  fontColor: string;
}

export const MainBtn = styled.button<BtnProp>`
  font-family: "Pretandard-Regular";
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 150px;
  height: 50px;

  background-color: ${({ bgrColor }) => bgrColor};
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
  color: ${({ fontColor }) => fontColor};
  opacity: 1;
  margin-right: 50px;

  &:hover {
    opacity: 0.65;
  }
  border-radius: 30px;

  @media (max-width: 1440px) {
    height: 50px;
    width: 150px;
    font-size: 18px;
    border-radius: 30px;
  }

  @media (max-width: 500px) {
    width: 60%;
    height: auto;
    padding-top: 2.5vw;
    margin-right: 4vw;
    padding-bottom: 2.5vw;
    border-radius: 4.5vw;
  }
`;
