import styled from "styled-components";
import { factoryColor } from "../constants/Colors";
import leftArrow from "../asset/left-arrow.png";
import rightArrow from "../asset/right-arrow.png";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Border, Percent, Pixel } from "../constants/Pixel";
import { myFont } from "../constants/Fonts";
import { JustifyType, alignItemType, textAlign } from "../constants/Styles";
import { RentalBookSelector } from "../components/Dropdown/RentalBookDropdown";
import { RentalBookAtMonth } from "../components/RentalBook/RentalBookAtMonth";
import { _client } from "../apis/client";
import useSWR from "swr";
import { infoManageData, tenantData } from "./InfoManageTab";
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  AddNewTenantModalState,
  BillDetailModalState,
  ContractDetailModalState,
  FactoryMetaInfoModalState,
  GlobalLoadingState,
  GoogleSheetMappingModalState,
  ModifyChungGuModalState,
  ModifyTenantModalState,
  SendKakaoModalState,
  SendNoticeModalState,
  SendTenantKakaoModalState,
  UserLoginStatusType,
} from "../store/ModalStores";
import { useMediaQuery } from "react-responsive";
import { report } from "process";
import { Flex, RowFlex } from "../constants/Flex";

export interface rentalBookTenantData {
  rental_book_id: number;
  tenant_id: number;
  tenant_company_name: string;
  tenant_name: string;
  account_info: string;
  requested_date: string;
  deadline_date: string;
  current_total_fee: number;
  current_rental_fee: number;
  current_management_fee: number;
  paid_total_fee: number;
  paid_rental_fee: number;
  paid_management_fee: number;
  requested_paper_url: string;
  current_contract_paper_url: string;
  phone_number: string;
  is_overdue: boolean;
}

export interface rentalBookData {
  factory_id: number;
  factory_name: string;
  factory_owner_name: string;
  factory_owner_phone_number: string;
  factory_address: string;
  total_fee: number;
  total_requested_rental_fee: number;
  total_requested_management_fee: number;
  total_requested_report_url: string;
  requested_rental_fee_to_owner_url: string;
  total_overdue_rental_fee: number;
  total_overdue_management_fee: number;
  all_tenants: rentalBookTenantData[];
}

export const RentalBookManaberScreen = () => {
  const today = new Date();
  const [currentYear, setCurrentYear] = useState<number>(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(
    today.getMonth() + 1
  );
  const [prevMonth, setPrevMonth] = useState<number>(today.getMonth());
  const [nextMonth, setNextMonth] = useState<number>(today.getMonth() + 2);
  const [currentFactoryName, setCurrentFactoryName] = useState<string>("");
  const [lastFactoryName, setLastFactoryName] = useState<string>("");

  const [sendNoticeModal, setSendNoticeModal] =
    useRecoilState(SendNoticeModalState);

  // 임차인 전송용 모달
  const [sendTenantKakaoModal, setSendTenantKakaoModal] = useRecoilState(
    SendTenantKakaoModalState
  );

  const [factoryMetaInfoModal, setFactoryMetaInfoModal] = useRecoilState(
    FactoryMetaInfoModalState
  );

  // 임대인 전송용 모달
  const [sendKakaoModal, setKakaoModal] = useRecoilState(SendKakaoModalState);
  const [googleSheetModal, setGoogleSheetModal] = useRecoilState(
    GoogleSheetMappingModalState
  );

  const [modifyChungGuModal, setModifyChungGuModal] = useRecoilState(
    ModifyChungGuModalState
  );

  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:501px) and (max-width: 1439px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width:500px)",
  });

  const openChungGuModal = (
    factoryID: number,
    factoryName: string,
    totalFee: number,
    rentalFee: number,
    managementFee: number,
    reportUrl: string,
    requestUrl: string
  ) => {
    setModifyChungGuModal({
      show: true,
      factory_id: factoryID,
      factory_name: factoryName,
      lookup_month: currentMonth,
      lookup_year: currentYear,
      total_fee: rentalFee,
      rental_fee: rentalFee,
      management_fee: managementFee,
      report_url: reportUrl,
      request_url: requestUrl,
    });
  };

  const openSendSmsModal = (
    targetPhoneNumber: string,
    targetName: string,
    targetType: string,
    targetUrl: string,
    defaultText: string
  ) => {
    setSendNoticeModal({
      show: true,
      sendTargetName: targetName,
      sendTargetPhoneNumber: targetPhoneNumber,
      sendTargetType: targetType,
      sendFileUrl: targetUrl,
      defaultText: defaultText,
      onPress: () => {},
    });
  };

  // 임대인 카톡 발송 모달
  const openSendKakaoModal = (
    sendTargetName: string,
    sendFactoryName: string,
    sendTargetPhoneNumber: string,
    sendPurposeType: string,
    sendFileUrl: string,
    year: number,
    month: number,
    templateCode: string
  ) => {
    setKakaoModal({
      show: true,
      sendTargetName: sendTargetName,
      sendFactoryName: sendFactoryName,
      sendTargetPhoneNumber: sendTargetPhoneNumber,
      sendPurposeType: sendPurposeType,
      sendFileUrl: sendFileUrl,
      year: year.toString(),
      month: month.toString(),
      templateCode: templateCode,
      defaultText: "",
      onPress: () => {},
    });
  };

  // 임차인 카톡 발송 모달
  const openSendTenantKakaoModal = (
    tenantCompanyName: string,
    year: string,
    month: string,
    tenantName: string,
    totalFee: string,
    rentalFee: string,
    mgmtFee: string,
    requestedDate: string,
    deadlineDate: string,
    accountInfo: string,
    url: string,
    templateCode: string,
    phoneNumber: string
  ) => {
    setSendTenantKakaoModal({
      show: true,
      tenantCompanyName: tenantCompanyName,
      year: year,
      month: month,
      tenantName: tenantName,
      totalFee: totalFee,
      rentalFee: rentalFee,
      mgmtFee: mgmtFee,
      requestedDate: requestedDate,
      deadlineDate: deadlineDate,
      accountInfo: accountInfo,
      url: url,
      templateCode: templateCode,
      contentText: "",
      phoneNumber: phoneNumber,
    });
  };

  useEffect(() => {
    myMutate();
    // 12월에서 다음 버튼 클릭시 (12 -> 13)
    if (currentMonth > 12) {
      setCurrentYear(currentYear + 1); // +1년
      setCurrentMonth(1); // 현재 월은 1월로 변경
      setPrevMonth(12); // 전달은 12월로 표시
      setNextMonth(2); // 다음달은 2월로 표시
    }
    // 1월에서 이전 버튼 클릭시 (1 -> 0)
    else if (currentMonth < 1) {
      setCurrentYear(currentYear - 1); // -1년
      setCurrentMonth(12); // 현재 월은 1월로 변경
      setPrevMonth(11); // 전달은 11월로 표시
      setNextMonth(1); // 다음달은 1월로 표시
      // 11 -> 12월 될때
    } else if (currentMonth == 12) {
      setPrevMonth(currentMonth - 1);
      setNextMonth(1);
      // 2월 -> 1월 될때
    } else if (currentMonth == 1) {
      setPrevMonth(12);
      setNextMonth(currentMonth + 1);
    } else {
      setPrevMonth(currentMonth - 1);
      setNextMonth(currentMonth + 1);
    }
  }, [currentMonth]);

  const {
    data: myRentalBook,
    error: myErr,
    isLoading: myLoad,
    mutate: myMutate,
  } = useSWR("/rental_book_for_manager/all", async (url) => {
    return await _client.postApiWithToken(url, {
      lookup_year: currentYear,
      lookup_month: currentMonth,
    });
  });

  useEffect(() => {
    if (myRentalBook?.data?.length > 0) {
      if (lastFactoryName.length < 1) {
        setCurrentFactoryName(myRentalBook.data[0].factory_name);
        setLastFactoryName(myRentalBook.data[0].factory_name);
      } else {
        setCurrentFactoryName(lastFactoryName);
      }
    }
  }, [myRentalBook]);

  useEffect(() => {
    setLastFactoryName(currentFactoryName);
  }, [currentFactoryName]);

  const openWindow = (url: string) => {
    window.open(url);
  };

  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);
  const [newTenantModal, setNewTenantModal] = useRecoilState(
    AddNewTenantModalState
  );

  const [modifyTenantModal, setModifyTenantModal] = useRecoilState(
    ModifyTenantModalState
  );

  const [contractDetailModal, setContractDetailModal] = useRecoilState(
    ContractDetailModalState
  );

  const [billDetailModal, setBillDetailModal] =
    useRecoilState(BillDetailModalState);

  const openNewTenantModal = (fac_id: number, year: number, month: number) => {
    setNewTenantModal({
      show: true,
      factory_id: fac_id,
      lookup_year: year,
      lookup_month: month,
      tenant_name: "",
      tenant_company_name: "",
      tenant_phone_number: "",
    });
  };

  const modifyMetaInfo = (
    factory_id: number,
    factory_name: string,
    year: number,
    month: number
  ) => {
    setLoadingModal({ isLoading: true, message: "" });
    _client
      .postApiWithToken("/manager/factory_stats_info/get", {
        factory_id: factory_id,
        year: currentYear,
        month: currentMonth,
      })
      .then((response) => {
        if (response.error !== null) {
          alert(response.error);
        } else {
          setFactoryMetaInfoModal({
            show: true,
            factory_name: factory_name,
            factory_id: factory_id,
            year: year,
            month: month,
            mgmt_fee_per_pyeong: response.data.mgmt_fee_per_pyeong,
            rental_fee_per_pyeong: response.data.rental_fee_per_pyeong,
            deposit_per_pyeong: response.data.deposit_per_pyeong,
            total_land_area: response.data.total_land_area,
            rent_area: response.data.rent_area,
            owner_area: response.data.owner_area,
            empty_area: response.data.empty_area,
          });
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        resetLoadingModal();
      });
  };

  const openCurrentGoogleSheet = (factory_id: number) => {
    setLoadingModal({ isLoading: true, message: "" });
    _client
      .postApiWithToken("/rental_book_for_manager/get_google_sheet", {
        factory_id: factory_id,
        year: currentYear,
        month: currentMonth,
      })
      .then((response) => {
        if (response.data?.length < 1) {
          alert(response.msg);
        } else {
          window.open(response.data.factory_url);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        resetLoadingModal();
      });
  };

  const openModifyGoogleSheetModal = (
    factory_id: number,
    factory_name: string
  ) => {
    setLoadingModal({ isLoading: true, message: "" });
    _client
      .postApiWithToken("/rental_book_for_manager/get_google_sheet", {
        factory_id: factory_id,
        year: currentYear,
        month: currentMonth,
      })
      .then((response) => {
        setGoogleSheetModal({
          show: true,
          factory_id: factory_id,
          factory_name: factory_name,
          factory_url: response.data.factory_url,
          year: currentYear,
          month: currentMonth,
        });
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        resetLoadingModal();
      });
  };

  const numberFormat = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberToKorean = (nb: number) => {
    var inputNumber: number = nb < 0 ? 0 : nb;
    var unitWords = ["", "만 ", "억 ", "조 ", "경"];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = " 원";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    return resultString;
  };

  const copyTenantFromLastMonth = (
    fac_id: number,
    year: number,
    month: number
  ) => {
    setLoadingModal({ isLoading: true, message: "" });
    _client
      .postApiWithToken(
        "/rental_book_for_manager/load_tenant_from_last_month",
        {
          factory_id: fac_id,
          lookup_year: year,
          lookup_month: month,
        }
      )
      .then((response) => {
        myMutate();
        alert(response.msg);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        resetLoadingModal();
      });
  };

  const openBillDetailModal = (
    rental_book_id: number,
    tenant_name: string,
    year: number,
    month: number
  ) => {
    console.log("Here is rental book ID: "+ rental_book_id.toString())
    setLoadingModal({ isLoading: true, message: "" });
    _client
      .postApiWithToken("/manager/rental_book_bill_detail/get", {
        rental_book_id: rental_book_id,
      })
      .then((r) => {
        setBillDetailModal({
          show: true,
          rental_book_id: rental_book_id,
          tenant_name: tenant_name,
          year: year,
          month: month,
          rental_fee: r.data.rental_fee,
          total_elec_fee: r.data.total_elec_fee,
          fire_safety_fee: r.data.fire_safety_fee,
          elec_safety_fee: r.data.elec_safety_fee,
          manage_fee: r.data.manage_fee,
          base_elec_fee: r.data.base_elec_fee,
          used_elec_fee: r.data.used_elec_fee,
          elec_used_amount: r.data.elec_used_amount,
          elec_prev_month_used_amount: r.data.elec_prev_month_used_amount,
          elec_fund: r.data.elec_fund,
          water_sewage_fee: r.data.water_sewage_fee,
          unpaid_fee: r.data.unpaid_fee,
          late_fee: r.data.late_fee
        });
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        resetLoadingModal();
      });
  };

  const openContractDetailModal = (
    factory_id: number,
    tenant_name: string,
    tenant_company_name: string,
    year: number,
    month: number,
    tenant_phone_number: string
  ) => {
    setLoadingModal({ isLoading: true, message: "" });
    let _url;
    let _tenant_phone_number = "";
    if (
      localStorage.getItem("realizableLoginStatus") ===
      UserLoginStatusType.LOGGEDIN
    ) {
      _url = "/rental_book_for_manager/tenant_contract_info";
      _tenant_phone_number = tenant_phone_number;
    } else {
      _url = "/rental_book_for_manager/tenant_contract_info_sample";
      _tenant_phone_number = "01099999999";
    }
    _client
      .postApi(_url, {
        factory_id: factory_id,
        lookup_year: year,
        lookup_month: month,
        tenant_phone_number: tenant_phone_number,
      })
      .then((response) => {
        setContractDetailModal({
          show: true,
          factory_id: factory_id,
          lookup_month: month,
          lookup_year: year,
          tenant_name: tenant_name,
          tenant_company_name: tenant_company_name,
          rental_type: response.data.rental_type,
          rental_deposit: response.data.rental_deposit,
          rental_fee_due_date: response.data.rental_fee_due_date,
          tenant_rental_fee: response.data.tenant_rental_fee,
          tenant_manage_fee: response.data.tenant_manage_fee,
          contract_start_date: response.data.contract_start_date,
          contract_end_date: response.data.contract_end_date,
          contract_paper_url: response.data.contract_paper_url,
          tenant_phone_number: _tenant_phone_number,
          agent_phone_number: response.data.agent_phone_number,
        });
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        resetLoadingModal();
      });
  };

  const openModifyTenantModal = (
    fac_id: number,
    year: number,
    month: number,
    tenant_name: string,
    tenant_company_name: string,
    tenant_phone_number: string,
    is_overdue: boolean,
    requested_total_fee: number,
    requested_rental_fee: number,
    requested_manage_fee: number,
    paid_total_fee: number,
    paid_rental_fee: number,
    paid_manage_fee: number,
    rental_report_url: string,
    contract_paper_url: string,
    account_info: string,
    requested_date: string,
    deadline_date: string
  ) => {
    setModifyTenantModal({
      show: true,
      factory_id: fac_id,
      lookup_year: year,
      lookup_month: month,
      tenant_name: tenant_name,
      tenant_company_name: tenant_company_name,
      tenant_phone_number: tenant_phone_number,
      is_overdue: is_overdue,
      requested_total_fee: requested_total_fee,
      requested_rental_fee: requested_rental_fee,
      requested_manage_fee: requested_manage_fee,
      paid_total_fee: paid_total_fee,
      paid_rental_fee: paid_rental_fee,
      paid_manage_fee: paid_manage_fee,
      rental_report_url: rental_report_url,
      contract_paper_url: contract_paper_url,
      account_info: account_info,
      requested_date: requested_date,
      deadline_date: deadline_date,
    });
  };

  const deleteTenant = (
    fac_id: number,
    year: number,
    month: number,
    tenant_name: string,
    tenant_company_name: string,
    tenant_phone_number: string
  ) => {
    confirmAlert({
      title: "아래 임차인을 삭제하시겠습니까?",
      message: "임차인 이름: " + tenant_name + " (" + tenant_company_name + ")",
      buttons: [
        {
          label: "예",
          onClick: () => {
            setLoadingModal({ isLoading: true, message: "" });
            _client
              .postApiWithToken("/rental_book_for_manager/tenant/del", {
                factory_id: fac_id,
                lookup_year: year,
                lookup_month: month,
                tenant_name: tenant_name,
                tenant_company_name: tenant_company_name,
                tenant_phone_number: tenant_phone_number,
              })
              .then((response) => {
                alert(response.msg);
              })
              .catch((err) => {
                alert(err);
              })
              .finally(() => {
                myMutate();
                resetLoadingModal();
              });
          },
        },
        {
          label: "아니오",
        },
      ],
    });
  };

  // 각 Modal의 상태 변경시 List update 필요
  useEffect(() => {
    myMutate();
  }, [
    modifyChungGuModal,
    modifyTenantModal,
    newTenantModal,
    contractDetailModal,
  ]);

  return (
    <RentalBookScreenCtnr>
      <HorizontalPaddingCtnr>
        <FactoryTitleCtnr>임대장부 (관리자 전용)</FactoryTitleCtnr>
        <MonthSelectCtnr>
          <MonthSelector>
            <ArrowIcon src={leftArrow} />
            <MoveMonth onClick={() => setCurrentMonth(currentMonth - 1)}>
              {prevMonth}월
            </MoveMonth>
            <CurrentMonth>
              {currentYear.toString()}년 {currentMonth.toString()}월
            </CurrentMonth>
            <MoveMonth onClick={() => setCurrentMonth(currentMonth + 1)}>
              {nextMonth}월{" "}
            </MoveMonth>
            <ArrowIcon src={rightArrow} />
          </MonthSelector>
          <FactorySelector>
            <FactoryOption
              fontSize={"15px"}
              mobileFontSize={"3vw"}
              fontWeight={"500"}
              value={currentFactoryName}
              onChange={(event) => {
                setCurrentFactoryName(event.target.value);
              }}
            >
              {myRentalBook?.data?.map((element: rentalBookData) => {
                return (
                  <option key={element.factory_id}>
                    {element.factory_name}
                  </option>
                );
              })}
            </FactoryOption>
          </FactorySelector>
        </MonthSelectCtnr>
        {myErr ? (
          <div>{myErr}</div>
        ) : (
          <>
            {myRentalBook?.data?.length < 1 && (
              <GenBtnCtnr
                width="170px"
                height="50px"
                border="1px solid gray"
                color="gray"
                backgroundColor="white"
                etc="margin-top: 20px;border-radius: 10px;"
              >
                {currentMonth}월 장부 추가하기 +
              </GenBtnCtnr>
            )}
            {myRentalBook?.data?.map((r: rentalBookData) => {
              if (currentFactoryName === r.factory_name) {
                return (
                  <RentalBookCtnr key={r.factory_id}>
                    <TitleCtnr>
                      {r.factory_name} | {r.factory_owner_name}{" "}
                      <RowFlex>
                        <Btn
                          onClick={() =>
                            modifyMetaInfo(
                              r.factory_id,
                              r.factory_name,
                              currentYear,
                              currentMonth
                            )
                          }
                        >
                          관리 현황 수정하기
                        </Btn>
                        <Btn
                          onClick={() => openCurrentGoogleSheet(r.factory_id)}
                        >
                          관리로직 바로가기
                        </Btn>
                        <Btn
                          onClick={() =>
                            openModifyGoogleSheetModal(
                              r.factory_id,
                              r.factory_name
                            )
                          }
                        >
                          관리로직 링크 설정
                        </Btn>
                      </RowFlex>
                    </TitleCtnr>
                    <RentalFeeBoxCtnr
                      flexDirection={isPc ? "row" : "column"}
                      width="100%"
                    >
                      <RentalFeeEachBox
                        flexDirection="column"
                        width={isPc ? "50%" : "100%"}
                      >
                        <RentalFeeTitle>
                          청구 내역{" "}
                          <div
                            style={{
                              cursor: "pointer",
                              backgroundColor: "white",
                              border: "1px solid #2EA566",
                              color: "#2EA566",
                              padding: "10px",
                              borderRadius: "15px",
                            }}
                            onClick={() =>
                              openChungGuModal(
                                r.factory_id,
                                r.factory_name,
                                r.total_fee,
                                r.total_requested_rental_fee,
                                r.total_requested_management_fee,
                                r.total_requested_report_url,
                                r.requested_rental_fee_to_owner_url
                              )
                            }
                          >
                            수정하기
                          </div>
                        </RentalFeeTitle>
                        <RentalFeeBody>
                          <RentalFeeRow>
                            <RentalFeeRowTitle>
                              총 금액 (VAT 포함)
                            </RentalFeeRowTitle>
                            <RentalFeeRowContents>
                              {numberFormat(r.total_fee)}
                              {" 원 ("}
                              {numberToKorean(r.total_fee)}
                              {")"}
                            </RentalFeeRowContents>
                          </RentalFeeRow>
                          <RentalFeeRow>
                            <RentalFeeRowTitle>
                              임대료 (VAT 제외)
                            </RentalFeeRowTitle>
                            <RentalFeeRowContents>
                              {numberFormat(r.total_requested_rental_fee)}
                              {" 원 ("}
                              {numberToKorean(r.total_requested_rental_fee)}
                              {")"}
                            </RentalFeeRowContents>
                          </RentalFeeRow>
                          <RentalFeeRow>
                            <RentalFeeRowTitle>
                              관리비 (VAT 포함)
                            </RentalFeeRowTitle>
                            <RentalFeeRowContents>
                              {numberFormat(r.total_requested_management_fee)}
                              {" 원 ("}
                              {numberToKorean(r.total_requested_management_fee)}
                              {")"}
                            </RentalFeeRowContents>
                          </RentalFeeRow>
                          <RentalReportBtnCtnr
                            onClick={() =>
                              openWindow(r.total_requested_report_url)
                            }
                          >
                            [관리 보고서] 원본 보기 {">"}
                          </RentalReportBtnCtnr>
                          <SendBtnCtnr
                            onClick={() =>
                              openSendSmsModal(
                                r.factory_owner_phone_number,
                                r.factory_owner_name,
                                "임대인",
                                r.total_requested_report_url,
                                "안녕하세요, 공장관리 사무소 공장장 입니다.\n관리 보고서 송부드립니다.\n"
                              )
                            }
                          >
                            [관리 보고서] 문자 전송하기 {">"}
                          </SendBtnCtnr>
                          <SendBtnKakaoCtnr
                            onClick={() =>
                              openSendKakaoModal(
                                r.factory_owner_name,
                                r.factory_name,
                                r.factory_owner_phone_number,
                                "임대 보고서",
                                r.total_requested_report_url,
                                currentYear,
                                currentMonth,
                                "C000000515_00002" //C000000515_00030
                              )
                            }
                          >
                            [관리 보고서] 카톡 전송하기 {">"}
                          </SendBtnKakaoCtnr>
                          <div
                            style={{
                              marginTop: "15px",
                              marginBottom: "5px",
                              border: " 1px solid gray",
                              width: "90%",
                              opacity: "0.5",
                            }}
                          />
                          <RentalFeeBtnCtnr
                            onClick={() =>
                              openWindow(r.requested_rental_fee_to_owner_url)
                            }
                          >
                            [관리비 청구서] 원본 보기 {">"}
                          </RentalFeeBtnCtnr>
                          <SendBtnCtnr
                            onClick={() =>
                              openSendSmsModal(
                                r.factory_owner_phone_number,
                                r.factory_owner_name,
                                "임대인",
                                r.requested_rental_fee_to_owner_url,
                                "안녕하세요, 공장관리 사무소입니다.\n관리비 청구서 송부드립니다.\n"
                              )
                            }
                          >
                            [관리비 청구서] 문자 전송하기 {">"}
                          </SendBtnCtnr>
                          <SendBtnKakaoCtnr
                            onClick={() =>
                              openSendKakaoModal(
                                r.factory_owner_name,
                                r.factory_name,
                                r.factory_owner_phone_number,
                                "관리비 청구서",
                                r.requested_rental_fee_to_owner_url,
                                currentYear,
                                currentMonth,
                                "C000000515_00030"
                              )
                            }
                          >
                            [관리비 청구서] 카톡 전송하기 {">"}
                          </SendBtnKakaoCtnr>
                        </RentalFeeBody>
                      </RentalFeeEachBox>
                      <RentalFeeEachBox
                        flexDirection="column"
                        width={isPc ? "50%" : "100%"}
                      >
                        <RentalFeeTitle>연체 내역</RentalFeeTitle>
                        <RentalFeeBody>
                          <RentalFeeRow>
                            <RentalFeeRowTitle>임대료</RentalFeeRowTitle>
                            <RentalFeeRowContents>
                              {numberFormat(r.total_overdue_rental_fee)}
                            </RentalFeeRowContents>
                          </RentalFeeRow>
                          <RentalFeeRow>
                            <RentalFeeRowTitle>관리비</RentalFeeRowTitle>
                            <RentalFeeRowContents>
                              {numberFormat(r.total_overdue_management_fee)}
                            </RentalFeeRowContents>
                          </RentalFeeRow>
                          <RentalFeeBtnCtnr>
                            [연체 보고서] 보기 {">"}
                          </RentalFeeBtnCtnr>
                          <SendBtnCtnr>
                            [연체 보고서] 전송하기 {">"}
                          </SendBtnCtnr>
                        </RentalFeeBody>
                      </RentalFeeEachBox>
                    </RentalFeeBoxCtnr>
                    <TenantsListTitleCtnr>임차인 목록</TenantsListTitleCtnr>
                    {r.all_tenants.map((t: rentalBookTenantData) => (
                      <TenantsItemCtnr key={t.tenant_id}>
                        <TenantsItemLogoCtnr>
                          {t.tenant_company_name[0]}
                        </TenantsItemLogoCtnr>
                        <TenantsItemCompanyName>
                          {t.tenant_name}, {t.tenant_company_name}
                          <ModifyLogoCtnr
                            onClick={() =>
                              openModifyTenantModal(
                                r.factory_id,
                                currentYear,
                                currentMonth,
                                t.tenant_name,
                                t.tenant_company_name,
                                t.phone_number,
                                t.is_overdue,
                                t.current_total_fee,
                                t.current_rental_fee,
                                t.current_management_fee,
                                t.paid_total_fee,
                                t.paid_rental_fee,
                                t.paid_management_fee,
                                t.requested_paper_url,
                                t.current_contract_paper_url,
                                t.account_info,
                                t.requested_date,
                                t.deadline_date
                              )
                            }
                          >
                            수정
                          </ModifyLogoCtnr>
                          <ModifyLogoCtnr
                            onClick={() =>
                              deleteTenant(
                                r.factory_id,
                                currentYear,
                                currentMonth,
                                t.tenant_name,
                                t.tenant_company_name,
                                t.phone_number
                              )
                            }
                          >
                            제거
                          </ModifyLogoCtnr>
                          {t.is_overdue ? (
                            <OverDueCricle isOverDue={true}>미</OverDueCricle>
                          ) : (
                            <OverDueCricle isOverDue={false}>완</OverDueCricle>
                          )}
                        </TenantsItemCompanyName>
                        <TenantsItemFeeCtnr>
                          <TenantsFeeTitle>임대료</TenantsFeeTitle>
                          <TenantsFeeContents>
                            {numberFormat(t.current_rental_fee)} 원
                          </TenantsFeeContents>
                        </TenantsItemFeeCtnr>
                        <TenantsItemFeeCtnr>
                          <TenantsFeeTitle>관리비</TenantsFeeTitle>
                          <TenantsFeeContents>
                            {numberFormat(t.current_management_fee)} 원
                          </TenantsFeeContents>
                        </TenantsItemFeeCtnr>
                        <TenantsContractBtnCtnr
                          onClick={() =>
                            openBillDetailModal(
                              t.rental_book_id,
                              t.tenant_name,
                              currentYear,
                              currentMonth
                            )
                          }
                        >
                          청구서 설정
                        </TenantsContractBtnCtnr>
                        <TenantsContractBtnCtnr
                          onClick={() => openWindow(t.requested_paper_url)}
                        >
                          청구서 원본
                        </TenantsContractBtnCtnr>
                        <TenantsContractBtnCtnr
                          onClick={() =>
                            openContractDetailModal(
                              r.factory_id,
                              t.tenant_name,
                              t.tenant_company_name,
                              currentYear,
                              currentMonth,
                              t.phone_number
                            )
                          }
                        >
                          계약 상세
                        </TenantsContractBtnCtnr>
                        <TenantsSendBtnCtnr
                          onClick={() =>
                            openSendSmsModal(
                              t.phone_number,
                              t.tenant_name,
                              "임차인",
                              t.requested_paper_url,
                              "안녕하세요, 공장관리 사무소입니다.\n" +
                                currentMonth.toString() +
                                "월 청구서 송부드립니다.\n"
                            )
                          }
                        >
                          문자 발송
                        </TenantsSendBtnCtnr>
                        <TenantsKakaoSendBtnCtnr
                          onClick={() =>
                            openSendTenantKakaoModal(
                              t.tenant_company_name,
                              currentYear.toString(),
                              currentMonth.toString(),
                              t.tenant_name,
                              t.current_total_fee.toString(),
                              t.current_rental_fee.toString(),
                              t.current_management_fee.toString(),
                              t.requested_date,
                              t.deadline_date,
                              t.account_info,
                              t.requested_paper_url,
                              "C000000515_00031",
                              t.phone_number
                            )
                          }
                        >
                          카카오 발송
                        </TenantsKakaoSendBtnCtnr>
                      </TenantsItemCtnr>
                    ))}
                    {r.all_tenants.length < 1 && (
                      <GenBtnCtnr
                        width="300px"
                        height="50px"
                        color="#2ea566"
                        border="1px solid #2ea566"
                        backgroundColor="white"
                        etc="font-weight: 600;margin-top: 20px;margin-bottom: 20px;border-radius: 10px;"
                        onClick={() =>
                          copyTenantFromLastMonth(
                            r.factory_id,
                            currentYear,
                            currentMonth
                          )
                        }
                      >
                        임차인 불러오기 (전월)
                      </GenBtnCtnr>
                    )}
                    <GenBtnCtnr
                      width="300px"
                      height="50px"
                      color="white"
                      backgroundColor="#2ea566"
                      etc="font-weight: 600;margin-top: 20px;margin-bottom: 20px;border-radius: 10px;"
                      // Add modal first
                      onClick={() =>
                        openNewTenantModal(
                          r.factory_id,
                          currentYear,
                          currentMonth
                        )
                      }
                    >
                      신규 임차인 추가하기 +
                    </GenBtnCtnr>
                  </RentalBookCtnr>
                );
              } else {
                return null;
              }
            })}{" "}
          </>
        )}
      </HorizontalPaddingCtnr>
    </RentalBookScreenCtnr>
  );
};

const RentalBookScreenCtnr = styled.div`
  display: flex;
  flex-direction: column;

  align-items: ${alignItemType.center};
  justify-content: ${JustifyType.center};

  box-sizing: border-box;
  border: ${Border.zeroPxBlue};
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #ffffff;
`;

export const ArrowIcon = styled.img`
  width: 2.5%;
`;

const HorizontalPaddingCtnr = styled.div`
  box-sizing: border-box;
  border: ${Border.zeroPxBlack};
  width: ${Pixel.desktopWidth};
  padding-left: ${Pixel.headerPadding};
  padding-right: ${Pixel.headerPadding};
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${Pixel.desktopWidth}) {
    width: ${Percent.tabletHeaderWidth};
    padding-right: ${Percent.tabletHeaderPadding};
    padding-left: ${Percent.tabletHeaderPadding};
  }

  @media (max-width: ${Pixel.mobileWidth}) {
    width: ${Percent.mobileHeaderWidth};
    justify-content: flex-start;
    padding-right: ${Percent.mobileHeaderPadding};
    padding-leftt: ${Percent.mobileHeaderPadding};
  }
`;

const FactoryTitleCtnr = styled.div`
  font-family: ${myFont.regular};

  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 40px;
  font-weight: 700;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border-bottom: 1px solid ${factoryColor.border_secondary};
`;

const MonthSelectCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 30px;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  height: 50px;
  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 0px solid ${factoryColor.border_secondary};
`;

const MonthSelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.spaceBewteen};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 400px;
  padding: 10px;

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

const MoveMonth = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 20%;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.descriptionSecondary};

  box-sizing: border-box;
  border: 0px solid ${factoryColor.border_secondary};
`;

const CurrentMonth = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: ${alignItemType.center};
  text-align: ${textAlign.center};
  width: 52%;

  font-size: 22px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border-left: 1px solid ${factoryColor.border_secondary};
  border-right: 1px solid ${factoryColor.border_secondary};
  padding-left: 5%;
  padding-right: 5%;
`;

const FactorySelector = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.fromStart};
  text-align: ${textAlign.start};
  width: 200px;
  padding: 12px;

  font-size: 18px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
  border-radius: 10px;
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const FactoryOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  width: 100%;

  border-radius: 5px;
  border: solid 3px #fff;

  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: black;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const RentalBookCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 30px;
  justify-content: ${JustifyType.center};
  align-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  font-size: 20px;
  font-weight: 500;
  color: ${factoryColor.textPrimary};

  box-sizing: border-box;
  border: 1px solid ${factoryColor.border_secondary};
`;

const TitleCtnr = styled.div`
  font-family: ${myFont.regular};
  display: flex;
  flex-direction: row;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.fromStart};
  align-self: ${alignItemType.fromStart};
  width: 100%;
  padding: 20px;

  text-align: ${textAlign.start};
  overflow: hidden;

  font-size: 20px;
  font-weight: 700;

  color: ${factoryColor.textPrimary};
  background-color: ${factoryColor.bg_primary};
  border-bottom: 1px solid ${factoryColor.border_secondary};

  box-sizing: border-box;
`;

interface alignOption {
  flexDirection: string;
  width: string;
}

const RentalFeeBoxCtnr = styled.div<alignOption>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: ${({ width }) => width};
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  background-color: white;
`;

const RentalFeeEachBox = styled.div<alignOption>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: ${({ width }) => width};
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  background-color: ${factoryColor.bg_primary};
  border: 1px solid ${factoryColor.border_secondary};
  margin: 15px;
`;

const RentalFeeTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  font-size: 15px;
  border-bottom: 1px solid ${factoryColor.border_secondary};
  padding-top: 12px;
  padding-bottom: 12px;
  gap: 10px;
`;

const RentalFeeBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  font-size: 15px;
  padding: 12px;
`;

const RentalFeeRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.center};
`;

const RentalFeeRowTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  padding-left: 20px;
  padding-bottom: 15px;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.center};
  color: ${factoryColor.descriptionSecondary};
`;

const RentalFeeRowContents = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  padding-right: 20px;
  padding-bottom: 15px;
  justify-content: ${JustifyType.fromEnd};
  align-items: ${alignItemType.center};
  color: ${factoryColor.textPrimary};
`;

const RentalReportBtnCtnr = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  background-color: ${factoryColor.textPrimary};
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const RentalFeeBtnCtnr = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  background-color: white;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  border: 1px solid ${factoryColor.textPrimary};
  color: ${factoryColor.textPrimary};
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const SendBtnCtnr = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  background-color: white;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  border: 1px solid ${factoryColor.mainGreen};
  color: ${factoryColor.mainGreen};
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;
const SendBtnKakaoCtnr = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  background-color: ${factoryColor.kakaoYellow};
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  border: 1px solid ${factoryColor.kakaoYellow};
  color: ${factoryColor.kakaoBrown};
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const TenantsListTitleCtnr = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: ${factoryColor.bg_primary};
  padding: 20px;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: ${factoryColor.textPrimary};
  font-size: 18px;
  font-weight: 500;
  border-top: 1px solid ${factoryColor.border_secondary};
  border-bottom: 1px solid ${factoryColor.border_secondary};
`;

const TenantsItemCtnr = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: white;
  padding: 20px;
  justify-content: ${JustifyType.spaceBewteen};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: ${factoryColor.textPrimary};
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid ${factoryColor.border_secondary};
`;

const TenantsItemLogoCtnr = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  flex-direction: row;
  background-color: #e14840;
  border-radius: 100px;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: white;
  font-size: 15px;
  font-weight: 700;
`;

const ModifyLogoCtnr = styled.div`
  display: flex;
  width: 50px;
  height: 25px;
  margin-left: 10px;
  flex-direction: row;
  background-color: gray;
  border-radius: 100px;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: white;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;
const TenantsItemCompanyName = styled.div`
  display: flex;
  width: 25%;
  margin-left: 1%;
  flex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  color: ${factoryColor.textPrimary};
  font-weight: 700;
`;

const TenantsItemFeeCtnr = styled.div`
  display: flex;
  width: 15%;
  fiex-direction: row;
  justify-content: ${JustifyType.fromStart};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
`;

const TenantsFeeTitle = styled.div`
  color: ${factoryColor.descriptionSecondary};
  margin-right: 5%;
`;

const TenantsFeeContents = styled.div`
  color: ${factoryColor.textPrimary};
`;

const TenantsContractBtnCtnr = styled.div`
  display: flex;
  width: 110px;
  padding: 10px;
  margin-right: 10px;
  fiex-direction: row;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  font-size: 15px;
  background-color: ${factoryColor.halfMainGreen};
  color: ${factoryColor.mainGreen};
  border-radius: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const TenantsSendBtnCtnr = styled.div`
  display: flex;
  width: 95px;
  padding: 10px;
  margin-right: 10px;
  fiex-direction: row;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  font-size: 15px;
  background-color: white;
  color: ${factoryColor.mainGreen};
  border-radius: 15px;
  border: 1px solid ${factoryColor.mainGreen};
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

// kakaoYellow: '#F7E600',
//     kakaoBrown: '#3A1D1D',
const TenantsKakaoSendBtnCtnr = styled.div`
  display: flex;
  width: 95px;
  padding: 10px;
  margin-right: 10px;
  fiex-direction: row;
  justify-content: ${JustifyType.center};
  align-items: ${alignItemType.center};
  box-sizing: border-box;
  font-size: 15px;
  background-color: white;
  color: #3a1d1d;
  border-radius: 15px;
  border: 1px solid #f7e600;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

interface flexOption {
  width?: string;
  height?: string;
  border?: string;
  backgroundColor?: string;
  color?: string;
  justifyContent?: string;
  alignItems?: string;
  etc?: string;
}

const GenBtnCtnr = styled.div<flexOption>`
  display: flex;
  flex-direction: row;
  width: ${({ width }) => (width ? width : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  color: ${({ color }) => (color ? color : "")};
  border: ${({ border }) => (border ? border : "")};
  height: ${({ height }) => (height ? height : "")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};

  box-sizing: border-box;
  flex-wrap: wrap;
  ${({ etc }) => (etc ? etc : "")}

  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;
interface overDue {
  isOverDue: boolean;
}

const OverDueCricle = styled.div<overDue>`
  display: flex;
  box-sizing: border-box;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  background-color: ${({ isOverDue }) => (isOverDue ? "#ffefef" : "#d7dbe2")};
  color: ${({ isOverDue }) => (isOverDue ? "#f03e3e" : "#354153")};
  font-size: 15px;
`;

const Btn = styled.div`
  display: flex;
  color: #2ea566;
  box-sizing: border-box;
  background-color: transparent;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
  font-size: 15px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid #2ea566;
  &:hover {
    opacity: 0.65;
  }
`;
