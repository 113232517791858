import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  ConsultingRequestModalState,
  ElecInfoSettingModalState,
  GlobalLoadingState,
} from "../../store/ModalStores";
import closeIcon from "../../asset/close-circle.png";
import {
  alignItemType,
  CommonType,
  GreenSpan,
  JustifyType,
} from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import logoGreen from "../../asset/logo_green.png";
import { useNavigate } from "react-router-dom";
import { Pixel } from "../../constants/Pixel";

let customerType: Array<string> = ["개인사업자", "법인사업자"];

export const ElecInfoSettingModal = () => {
  const [thisModal, setThisModal] = useRecoilState(ElecInfoSettingModalState);
  const resetThisModal = useResetRecoilState(ElecInfoSettingModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);
  const [phoneNumber, setPhoneNumber] = useState(thisModal.phone_number);
  const [elecUniqueNumber, setElecUniqueNumber] = useState(
    thisModal.elec_register_number
  );
  const [birthDayNumber, setBirthDayNumber] = useState(
    thisModal.birthday_number
  );
  const [companyNumberHead, setCompanyNumberHead] = useState(
    thisModal.company_register_number_head
  );
  const [companyNumberTail, setCompanyNumberTail] = useState(
    thisModal.company_register_number_tail
  );
  const [customerOption, setCustomerOption] = useState<string>(
    thisModal.customer_type
  );
  const [agreement, setAgreement] = useState<boolean>(thisModal.agreement);
  const [editablePhoneNumber, setEditablePhoneNumber] = useState<boolean>(
    thisModal.is_phone_number_editable
  );
  const [fromCustomer, setFromCustomer] = useState<boolean>(
    thisModal.from_customer
  );

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const sendRequest = () => {
    let regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    if (!agreement) {
      alert("이용 동의 후 접수 가능합니다.");
    } else if (phoneNumber?.length < 10) {
      alert("휴대폰 번호를 확인해주세요. (숫자만 입력)");
    } else if (elecUniqueNumber?.length < 5) {
      alert("한전 고객 번호를 확인해주세요. (숫자만 입력)");
    } else {
      if (regExp.test(phoneNumber)) {
        setLoadingModal({
          isLoading: true,
          message: "한전 설정 중입니다.",
        });

        _client
          .postApiWithToken(
            fromCustomer
              ? "/elec_info/create_from_customer"
              : "/elec_info/create_from_manager",
            {
              phone_number: phoneNumber,
              elec_register_number: elecUniqueNumber,
              customer_type: customerOption,
              birthday_number: birthDayNumber,
              company_register_number: companyNumberHead + companyNumberTail,
            }
          )
          .then((response) => {
            resetLoadingModal();
            alert(response.msg);
          })
          .catch((err) => {
            resetLoadingModal();
            alert(err);
          })
          .finally(() => {
            resetThisModal();
          });
      } else {
        alert("휴대폰 번호가 아닌 경우 등록이 불가합니다.");
      }
    }
  };

  const inputPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.currentTarget.value);
  };

  const inputElecUniqueNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setElecUniqueNumber(e.currentTarget.value);
  };

  const inputBirthDayNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirthDayNumber(e.currentTarget.value);
  };

  const inputCompanyNumberHead = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyNumberHead(e.currentTarget.value);
  };

  const inputCompanyNumberTail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyNumberTail(e.currentTarget.value);
  };

  useEffect(() => {
    setPhoneNumber(thisModal.phone_number);
    setElecUniqueNumber(thisModal.elec_register_number);
    setBirthDayNumber(thisModal.birthday_number);
    setCompanyNumberHead(thisModal.company_register_number_head);
    setCompanyNumberTail(thisModal.company_register_number_tail);
    setCustomerOption(thisModal.customer_type);
    setAgreement(thisModal.agreement);
    setEditablePhoneNumber(thisModal.is_phone_number_editable);
    setFromCustomer(thisModal.from_customer);
  }, [thisModal]);

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ModalHeader>
                <MainLogoImg src={logoGreen}></MainLogoImg>
              </ModalHeader>
              <ModalDescription>
                <LocalGreenSpan>한전 연동 설정</LocalGreenSpan>
              </ModalDescription>
              <ConsultingRequest>
                <ContentDesc>고객 유형을 선택해주세요.</ContentDesc>
                <ContentInputCtnr>
                  <ConsultingOption
                    fontSize={"100%"}
                    mobileFontSize={"3vw"}
                    fontWeight={"500"}
                    value={customerOption}
                    onChange={(event) => {
                      setCustomerOption(event.target.value);
                    }}
                  >
                    {customerType.map((element) => {
                      return <option key={element}>{element}</option>;
                    })}
                  </ConsultingOption>
                </ContentInputCtnr>
              </ConsultingRequest>
              <UserVerification>
                <UserVerifyDesc>고객 휴대폰 번호 (본인)</UserVerifyDesc>
                <UserVerifyInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    onChange={(e) => inputPhoneNumber(e)}
                    disabled={!editablePhoneNumber}
                    placeholder="휴대폰번호 입력 (숫자만)"
                    value={phoneNumber}
                  />
                </UserVerifyInputCtnr>
              </UserVerification>
              <UserVerification>
                <UserVerifyDesc>한전 고객 번호 (청구서 확인)</UserVerifyDesc>
                <UserVerifyInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    onChange={(e) => inputElecUniqueNumber(e)}
                    disabled={false}
                    placeholder="한전 고객번호를 입력하세요. (숫자만)"
                  />
                </UserVerifyInputCtnr>
              </UserVerification>
              {customerOption === "법인사업자" && (
                <UserVerification>
                  <UserVerifyDesc>법인등록번호 입력</UserVerifyDesc>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: 600,
                    }}
                  >
                    <UserVerifyInputCtnr>
                      <LocalInput
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        onChange={(e) => inputCompanyNumberHead(e)}
                        disabled={false}
                        placeholder="앞 6자리"
                      />
                    </UserVerifyInputCtnr>
                    -
                    <UserVerifyInputCtnr>
                      <LocalInput
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        onChange={(e) => inputCompanyNumberTail(e)}
                        disabled={false}
                        placeholder="뒷자리"
                      />
                    </UserVerifyInputCtnr>
                  </div>
                </UserVerification>
              )}
              {customerOption === "개인사업자" && (
                <UserVerification>
                  <UserVerifyDesc>생년월일 앞 6자리 입력 (개인)</UserVerifyDesc>
                  <UserVerifyInputCtnr>
                    <LocalInput
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      onChange={(e) => inputBirthDayNumber(e)}
                      disabled={false}
                      placeholder="생년월일 앞 6자리를 입력하세요. (숫자만)"
                    />
                  </UserVerifyInputCtnr>
                </UserVerification>
              )}
              <AgreementCtnr>
                <CheckBox
                  type="checkbox"
                  checked={agreement}
                  onChange={({ target: { checked } }) => setAgreement(checked)}
                />
                제3자 정보 수집 및 이용에 동의합니다.
              </AgreementCtnr>
              <AgreementBox>
                <Article>
                  <ArticleText>
                    <h4>개인정보 수집 및 제3자 정보제공에 대한 동의</h4>
                    한국전력공사 및 제공받는 자의 개인정보 이용목적
                    (서비스사업자 자료)
                    <br />
                    ㅇ 전력데이터 서비스 마켓에 필요한 개인정보 수집 및 이용,
                    개별 서비스사업자에 대한 제공
                    <br />
                    <br />
                    개인정보를 제공받는 자 (서비스사업자)
                    <br />
                    ㅇ 상호(법인)명 : 리얼라이저블 주식회사
                    <br />
                    <br />
                    수집ㆍ이용하는 개인정보 항목
                    <br />
                    ㅇ 성명, ID, Password, 전화번호, E-mail, 주소 등
                    한국전력공사가 전력데이터 서비스 마켓 운영에 필요한 최소한의
                    통보
                    <br />
                    - 고객 전기사용 정보
                    <br />
                    <br />
                    * 제3자에게 제공하는 개인정보 항목(수집 및 제공 자료)
                    <br />
                    [고객정보]
                    <br />
                    고객번호, 모자구분, 계약종별, 선택요금, 정기검침일,
                    계기배수, 계기역률, 계약전력, 가구수, TV대수,저고압 구분 ,
                    계기유형, AMI구분, 명의변경일, 송전일자, 계기 번호
                    <br />
                    [고객번호, 계기번호]
                    <br />
                    고객번호, 계기배수, 계기 번호
                    <br />
                    [청구정보]
                    <br />
                    고객번호, 지상역률, 청구년월, 정기검침일, 요금적용전력,
                    이사정산일, 기본요금, 전력량요금, 할인/공제계, 전기요금계,
                    경부하사용량, 중부하사용량, 최대부하사용량, 경부하당월지침,
                    중부하당월지침, 최대부하당월지침, 진상역 률, 청구요금
                    <br />
                    [고객번호]
                    <br />
                    고객번호, 고객명, 저고압 구분, 계약종별, 계약전력, 업종명
                    <br />
                    [15분단위 전력소비 데이터]
                    <br />
                    계기번호, 조회일자, 다계기여부, 유효전력, 조회시분, 15분단위
                    전력소비 데이터(1건), 고객번호 [일단위 전력소비 데이터]
                    <br />
                    계기번호, 조회일자, 유효전력, 15분단위 전력소비
                    데이터(96건), 고객번호, 다계기여부
                    <br />
                    [특정고객의 특정시간 전력소비 데이터]
                    <br />
                    조회일자, 고객번호, 전력소비데이터, 계기 번호, 조회시분
                    <br />
                    [전체고객의 전력소비 데이터]
                    <br />
                    조회일자, 고객번호, 전력소비데이터, 계기 번호, 조회시분
                    <br />
                    [고객 가입여부 확인]
                    <br />
                    제3자 정보제공 동의일(최초), 제3자 정보제공 동의만료일,
                    동의여부 확인, 고객정보 확인, 가입여부 확인
                    <br />
                    [한국전력공사 및 제공받는 자의 보유ㆍ이용기간]
                    <br /> - 동의 시점일로 부터 1년간이며, 종료일까지
                    정보제공동의 연장신청을 하지 않으면 정보제공동의가 자동
                    종료됩니다. <br />- 고객에 의한 정보 제공 동의 해지 시/고객
                    명의 변경 시/고객 전기 사용 만료 시 자동 중지 <br />
                    [개인정보 수집 및 개인정보 제3자 제공 동의 거부의 권리]{" "}
                    <br />- 고객은 본 동의를 언제든지 거부할 권리가 있으며, 다만
                    동의하지 않은 경우 서비스 이용에 제한이 있을 수 있습니다.
                  </ArticleText>
                </Article>
              </AgreementBox>
              <SubmitBtnCtnr>
                <SubmitBtn active={agreement} onClick={() => sendRequest()}>
                  설정하기
                </SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "350px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
`;

const ModalHeader = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 35px;
`;
const ModalDescription = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  background-color: ${factoryColor.bg_primary};
`;

const UserVerification = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const UserVerifyDesc = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
`;

const UserVerifyInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const ContentDesc = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  font-size: 15px;
  font-weight: 500;
`;

const ContentInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  font-size: 15px;
  height: auto;
  width: 100%;
  margin-top: 12px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const ConsultingRequest = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  padding: 20px;
`;

const AgreementCtnr = styled.div`
  display: flex;
  flex-direction: row;
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  width: auto;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;

interface activeProp {
  active: boolean;
}

const SubmitBtn = styled.button<activeProp>`
  background-color: ${({ active }) => (active ? "#2EA566" : "white")};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: ${({ active }) => (active ? "pointer" : "")};
  outline: none;
  font-weight: 700;
  font-size: 15px;
  color: ${({ active }) => (active ? "white" : "gray")};
  background-repeat: no-repeat;
  border: ${({ active }) => (active ? "none" : "1px solid gray")};
  opacity: 1;
  :hover {
    opacity: ${({ active }) => (active ? "0.8" : "")};
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const ConsultingOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  height: 100%;
  width: 100%;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const CheckBox = styled.input`
  display: flex;
  width: 25px;
  margin-right: 10px;
  outline: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  color: gray;
`;

const MainLogoImg = styled.img`
  width: 36.5px;
  height: 20px;

  @media (max-width: ${Pixel.mobileWidth}) {
    width: 49.15px;
    height: 26.67px;
  }
`;

const AgreementBox = styled.div`
  position: relative;
  overflow: auto;
  height: 90px;
  padding-left: 20px;
  padding-right: 10px;
  border: 1p solid #fff;
  background: #eee;
  box-sizing: border-box;
`;
const Article = styled.div`
  color: #48484a;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  text-align: left;
`;
const ArticleText = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;
