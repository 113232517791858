import React, { CSSProperties } from "react";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import { TenantContractModalState } from "../../store/ModalStores";
import closeIcon from "../../asset/close-circle.png";
import { factoryColor } from "../../constants/Colors";
import { JustifyType } from "../../constants/Styles";

export type TenantContractModalProps = {};

export const TenantContractModal = () => {
  const [tenantInfoModal, setTenantInfoModal] = useRecoilState(
    TenantContractModalState
  );
  const resetTenantInfoModal = useResetRecoilState(TenantContractModalState);

    let mainStyle = defatulMainStyle;
  
    if (tenantInfoModal.show)
      return (
        <>
          <div style={backgroudStyle} onClick={resetTenantInfoModal} />
          <div style={mainStyle}>
            <button
              style={defaultCloseBtnStyle}
              onClick={resetTenantInfoModal}
            >
              <img
                style={{
                  backgroundColor: "transparent",
                }}
                src={closeIcon}
                alt="test"
              />
            </button>
            {tenantInfoModal.tenant_info?.tenant_name}
          </div>
        </>
      );
      else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
    zIndex: 210,
    position: "fixed",
    background: "#fff",
    borderRadius: "10px",
    width: "200px",
    height: "300px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    fontSize: "20px",
    textAlign: "center",
    color: `${factoryColor.mainGreen}`
  };
  
  const mobileMainStyle: CSSProperties = {
    position: "fixed",
    background: "#fff",
    borderRadius: "5px",
    width: "300px",
    height: "auto",
    top: "45%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: 210,
  };
  
  const defaultCloseBtnStyle: CSSProperties = {
    position: "absolute",
    top: 0,
    right: -45,
    display: "block",
    width: "20px",
    height: "20px",
    transition: "transform 0.1s",
    backgroundColor: "transparent",
    cursor: "pointer",
    zIndex: 210,
    border: 0
  };
  
  const mobileCloseBtnStyle: CSSProperties = {
    position: "absolute",
    display: "block",
    bottom: "-40px",
    left: "40%",
    width: "40px",
    height: "27px",
    transition: "transform 0.1s",
    backgroundColor: "transparent",
    border: 0,
    cursor: "pointer",
    objectFit: "cover",
    backgroundSize: "contain",
    zIndex: 210,
  };
