import styled from "styled-components";
import { factoryColor } from "../constants/Colors";
import refreshIcon from "../asset/refresh-icon.png";
import loadingGif from "../asset/ring-loading.gif";
import { Border, Percent, Pixel } from "../constants/Pixel";
import { myFont } from "../constants/Fonts";
import {
  JustifyType,
  alignItemType,
  textAlign,
  CommonType,
  alignContentType,
} from "../constants/Styles";
import { RentalBookSelector } from "../components/Dropdown/RentalBookDropdown";
import { RentalBookAtMonth } from "../components/RentalBook/RentalBookAtMonth";
import { useRecoilState } from "recoil";
import { ManagerTabState } from "../store/ManagerPageStores";
import { CSSProperties, useState } from "react";
import useSWR from "swr";
import { BillModalState, SendKakaoWelcomeModalState } from "../store/ModalStores";
import { _client } from "../apis/client";
import { factoryData } from "./ManagerScreen";

export interface tenantData {
  tenant_id: number;
  tenant_company_name: string;
  tenant_name: string;
  current_rental_fee: string;
  current_management_fee: string;
  current_contract_paper_url: string;
  is_overdue: boolean;
}
export interface infoManageData {
  factory_id: number;
  factory_name: string;
  factory_owner_name: string;
  factory_address: string;
  all_tenants: tenantData[];
}

let yearOptions: Array<string> = ["2023"];
let monthOptions: Array<string> = ["전체", "1월", "2월", "3월"];
export const InfoManage = () => {
  const [billModal, setBillModal] = useRecoilState(BillModalState);

  const [year, setYear] = useState<string>("2023");
  const [month, setMonth] = useState<string>("전체");
  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    data: myFac,
    error: myErr,
    isLoading: myLoad,
    mutate: myMutate,
  } = useSWR("/manager/tenants_all", async (url) => {
    return await _client.postApiWithToken(url, {});
  });

  const createRentalBook = (
    factory_id: number,
    target_month: number,
    tenant_id: number
  ) => {
    setLoading(true);
    _client
      .postApiWithToken("/rental_book/tenant/create_rental_fee", {
        factory_id: factory_id,
        target_month: target_month,
        target_tenant_id: tenant_id,
      })
      .then((response) => {
        alert(response.msg);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRentalBook = (
    factory_id: number,
    target_month: number,
    tenant_id: number
  ) => {
    setLoading(true);
    _client
      .postApiWithToken("/manager/tenant/get_rental_book", {
        factory_id: factory_id,
        target_month: target_month,
        target_tenant_id: tenant_id,
      })
      .then((response) => {
        setBillModal({
          show: true,
          currentMonth: target_month,
          monthyReportData: [],
          monthyTenantBillData: response.data.tenant_rental_books,
          monthyManageBillData: []
        });
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isLoading && <div style={backgroudStyle} />}
      {isLoading && (
        <img
          style={{
            backgroundColor: "transparent",
            position: "fixed",
            width: "50px",
            height: "50px",
            left: "50%",
            top: "50%",
          }}
          src={loadingGif}
          alt="loadingGif"
        />
      )}
      <ManageTabWrapper>
        <AboveCardRowCtnr>
          <SelectorCtnr>
            <YearSelectorCtnr>
              <PeriodOption
                fontSize={"1vw"}
                mobileFontSize={"3vw"}
                fontWeight={"500"}
                value={year}
                onChange={(event) => {
                  setYear(event.target.value);
                }}
              >
                {yearOptions.map((element) => {
                  return <option key={element}>{element}</option>;
                })}
              </PeriodOption>
            </YearSelectorCtnr>
            <MonthSelectorCtnr>
              <PeriodOption
                fontSize={"1vw"}
                mobileFontSize={"3vw"}
                fontWeight={"500"}
                value={month}
                onChange={(event) => {
                  setMonth(event.target.value);
                }}
              >
                {monthOptions.map((element) => {
                  return <option key={element}>{element}</option>;
                })}
              </PeriodOption>
            </MonthSelectorCtnr>
          </SelectorCtnr>
          {/* <SyncBtnCtnr>데이터 동기화 일괄 수행</SyncBtnCtnr> */}
        </AboveCardRowCtnr>
        {myFac?.data?.map((item: infoManageData) => (
          <EachFactoryCtnr key={item.factory_id}>
            <EachFactoryTitleCtnr>
              <EFTitle>{item.factory_name}</EFTitle>
              <EFRightSide>
                {/* <EFSyncBtnCtnr>데이터 동기화 수행</EFSyncBtnCtnr> */}
              </EFRightSide>
            </EachFactoryTitleCtnr>
            <EachFactoryDescriptionCtnr>
              <DescRowCtnr>
                <GrayColorP>임대인 | </GrayColorP>
                <div> {item.factory_owner_name} </div>
              </DescRowCtnr>
              <DescRowCtnr>
                <GrayColorP>공장 소재지 | </GrayColorP>
                <div> {item.factory_address} </div>
              </DescRowCtnr>
              {/* <DescRowCtnr>
              <GrayColorP>현 관리자 | </GrayColorP>
              <div> {item.all_managers_name} </div>
            </DescRowCtnr> */}
            </EachFactoryDescriptionCtnr>
            {item.all_tenants.map((tenant: tenantData) => (
              <>
                <EachFactoryBillTitleCtnr>
                  {tenant.tenant_company_name}
                </EachFactoryBillTitleCtnr>
                <EachFactoryBillCardCtnr>
                  <BillCard>
                    <BillTitle>1월</BillTitle>
                    <BillBtnCtnr
                      onClick={() =>
                        getRentalBook(item.factory_id, 1, tenant.tenant_id)
                      }
                    >
                      1월 청구서 보기
                    </BillBtnCtnr>
                    <BillRefreshBtn
                      onClick={() =>
                        createRentalBook(item.factory_id, 1, tenant.tenant_id)
                      }
                    >
                      1월 청구서 PDF 생성
                      <RefreshIcon src={refreshIcon} />
                    </BillRefreshBtn>
                  </BillCard>
                  <BillCard>
                    <BillTitle>2월</BillTitle>
                    <BillBtnCtnr
                      onClick={() =>
                        getRentalBook(item.factory_id, 2, tenant.tenant_id)
                      }
                    >
                      2월 청구서 보기
                    </BillBtnCtnr>
                    <BillRefreshBtn
                      onClick={() =>
                        createRentalBook(item.factory_id, 2, tenant.tenant_id)
                      }
                    >
                      2월 청구서 PDF 생성
                      <RefreshIcon src={refreshIcon} />
                    </BillRefreshBtn>
                  </BillCard>
                </EachFactoryBillCardCtnr>
              </>
            ))}
          </EachFactoryCtnr>
        ))}
      </ManageTabWrapper>
    </>
  );
};

const ManageTabWrapper = styled.div`
  ${CommonType.displayColumnFlex}
  padding-top: 15px;
  padding-bottom: 15px;
`;

const AboveCardRowCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentSpaceBetween}
`;
const SelectorCtnr = styled.div`
  ${CommonType.displayRowFlex}
  gap: 10px;
`;
const YearSelectorCtnr = styled.div``;

const MonthSelectorCtnr = styled.div``;

const SyncBtnCtnr = styled.div`
  ${CommonType.displayRowFlex}
  background-color: ${factoryColor.mainGreen};
  ${CommonType.displayColumnFlex}
  color: white;
`;

const EachFactoryCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  margin-top: 20px;
  border-left: solid 1px #d7dbe2;
  border-right: solid 1px #d7dbe2;
  border-top: solid 3px #d7dbe2;
  border-bottom: solid 3px #d7dbe2;
`;

const EachFactoryTitleCtnr = styled.div`
  background-color: #f9fafc;
  padding: 20px;
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentSpaceBetween}
`;

const EFTitle = styled.div`
  font-size: 30px;
  ${CommonType.fontWeight700}
`;

const EFRightSide = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
`;

const EFSyncBtnCtnr = styled.div`
  font-size: 20px;
  color: #2ea566;
  background-color: #e9f9f0;
`;

const EachFactoryDescriptionCtnr = styled.div`
  background-color: white;
  padding: 20px;
  ${CommonType.displayColumnFlex}
  gap: 15px;
`;
const DescRowCtnr = styled.div`
  ${CommonType.displayRowFlex}
  gap: 10px;
`;

const GrayColorP = styled.div`
  display: flex;
  color: ${factoryColor.card_title};
`;

const EachFactoryBillTitleCtnr = styled.div`
  background-color: #f9fafc;
  padding: 20px;
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentSpaceBetween}
  font-size: 20px;
`;

const EachFactoryBillCardCtnr = styled.div`
  padding: 20px;
  ${CommonType.displayRowFlex}
  flex-wrap: wrap;
  gap: 15px;
`;

const BillCard = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.justifyContentCenter}
  ${CommonType.alignItemsCenter}
  width: 268px;
  height: 173px;
  padding: 15px;
  border-radius: 20px;
  background-color: #f2f4f6;
  gap: 15px;
`;

const BillTitle = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.fontWeight700}
  font-size: 20px;
`;

const BillBtnCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  background-color: #121d2e;
  width: 100%;
  color: white;
  cursor: pointer;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

const BillRefreshBtn = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.justifyContentCenter}
  ${CommonType.alignItemsCenter}
  color: black;
  gap: 15px;
  cursor: pointer;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

const RefreshIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 25px;
  padding-left: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

const PeriodOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  height: 100%;
  width: 100%;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.3)",
};
