import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import { LandingScreen } from "./LandingScreen";
import { Footer } from "../components/landingPage/Footer";
import { RealizableHeader } from "../components/landingPage/RealizableHeader";
import { RentalBookScreen } from "./RentalBookScreen";
import { ManagerScreen } from "./ManagerScreen";
import { TenantContractModal } from "../components/Modals/TenantContractModal";
import { ConsultingRequestModal } from "../components/Modals/ConsultingRequestModal";
import { LoginModal } from "../components/Modals/LoginModal";
import { BillModal } from "../components/Modals/BillModal";
import { StartRegisterScreen } from "./registerScreens/StartRegister";
import { RegisterCompletePage } from "./registerScreens/RegisterCompletePage";
import { SideMenuBar } from "../components/SideMenuBar";
import { GlobalLoadingModal } from "../components/Modals/GlobalLoadingModal";
import { ElecInfoScreen } from "./ElecInfoScreen";
import { RentalBookManaberScreen } from "./RentalBookManagerScreen";
import { SendSmsNoticeModal } from "../components/Modals/SendSmsNoticeModal";
import { SendKakaoNoticeModal } from "../components/Modals/SendKakaoNoticeModal";
import { FacilityScreen } from "./FacilityScreen";
import { ContractDetailModal } from "../components/Modals/ContractDetailModal";
import { ModelingRequestModal } from "../components/Modals/ModelingRequestModal";
import { AddFactoryModal } from "../components/Modals/AddFactoryModal";
import { ModifyFactoryStatsModal } from "../components/Modals/ModifyFactoryStatsModal";
import { ModifyFactoryOwnerReportModal } from "../components/Modals/ModifyFactoryOwnerReportModal";
import { AddNewTenantModal } from "../components/Modals/AddNewTenantModal";
import { ModifyTenantModal } from "../components/Modals/ModifyTenantModal";
import { TenantBillDetailModal } from "../components/Modals/TenantBillDetailModal";
import { ModifyContractInfoModal } from "../components/Modals/ModifyContractInfoModal";
import { FacilityDetailModal } from "../components/Modals/FacilityDetailModal";
import { SubscribeInfoScreen } from "./SubscribeInfo";
import { IndependentInquiryScreen } from "./IndependentInquiryScreen";
import { ConsultingRequestSuccessPage } from "./ConsultingRequestSuccessScreen";
import { SendTenantKakaoModal } from "../components/Modals/SendTenantKakaoModal";
import { ElecInfoSettingModal } from "../components/Modals/ElecInfoSettingModal";
import { SendKakaoWelcomeModal } from "../components/Modals/SendKakaoWelcomeModal";
import { MyScreen } from "./MyScreen";
import { GoogleSheetMappingModal } from "../components/Modals/GoogleSheetMappingModal";
import { LawRequestModal } from "../components/Modals/LawRequestModal";
import { ModifyFacilityModal } from "../components/Modals/ModifyFacilityModal";

const MainScreen: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <SideMenuBar />
          <RealizableHeader />
          <GlobalLoadingModal />
          <Routes>
            <Route path="/" element={<LandingScreen />} />
            <Route path="/subscribe_info" element={<SubscribeInfoScreen />} />
            <Route
              path="/marketing_page"
              element={<IndependentInquiryScreen />}
            />
            <Route path="/rental_book" element={<RentalBookScreen />} />
            <Route
              path="/rental_book_manager"
              element={<RentalBookManaberScreen />}
            />
            <Route path="/facility" element={<FacilityScreen />} />
            <Route path="/manager" element={<ManagerScreen />} />
            <Route path="/mypage" element={<MyScreen />} />
            <Route path="/register" element={<StartRegisterScreen />} />
            <Route
              path="/register_complete"
              element={<RegisterCompletePage />}
            />
            <Route path="/elec_info" element={<ElecInfoScreen />} />
            <Route
              path="/consulting_request_success"
              element={<ConsultingRequestSuccessPage />}
            />
          </Routes>
          <Footer />
        </Wrapper>
        <TenantContractModal />
        <ContractDetailModal />
        <ModifyContractInfoModal />
        <ModelingRequestModal />
        <ModifyFactoryStatsModal />
        <ModifyFactoryOwnerReportModal />
        <TenantBillDetailModal />
        <GoogleSheetMappingModal />
        <LawRequestModal />
        <ModifyTenantModal />
        <AddNewTenantModal />
        <ConsultingRequestModal />
        <ElecInfoSettingModal />
        <SendSmsNoticeModal />
        <SendKakaoNoticeModal />
        <SendKakaoWelcomeModal />
        <SendTenantKakaoModal />
        <AddFactoryModal />
        <LoginModal />
        <FacilityDetailModal />
        <ModifyFacilityModal />
        <BillModal />
        {/* <LoginModal />
          <AgentSearchModal />
          <FloorSelectModal />
          <PriceLookupModal />
          <PayModal /> */}
      </BrowserRouter>
    </>
  );
};

export const Wrapper = styled.div`
  flex: 1;
  background-color: "white";
`;

export default MainScreen;
