export type TenantType = {
  factory_id: number;
  factory_owner_id: number;
  tenant_user_id: number;
  tenant_name: string;
  tenant_phone_number: string;
  tenant_company: string;
  tenant_rental_fee: string;
  tenant_manage_fee: string;
  rental_type: string;
  rental_super_tax_fee: string;
  rental_deposit: string;
  rental_total_fee: string;
  rental_fee_due_date: string;
  rental_report_url: string;
  rental_manage_fee_url: string;
  overdue_rental_fee: string;
  overdue_manage_fee: string;
  additional_info: string;
  contract_paper_url: string;
  contract_start_date: string;
  contract_end_date: string;
};

export const defaultTenantType: TenantType = {
  factory_id: 0,
  factory_owner_id: 0,
  tenant_user_id: 0,
  tenant_name: "알수없음",
  tenant_phone_number: "",
  tenant_company: "",
  tenant_rental_fee: "",
  tenant_manage_fee: "",
  rental_type: "",
  rental_super_tax_fee: "",
  rental_deposit: "",
  rental_total_fee: "",
  rental_fee_due_date: "",
  rental_report_url: "",
  rental_manage_fee_url: "",
  overdue_rental_fee: "",
  overdue_manage_fee: "",
  additional_info: "",
  contract_paper_url: "",
  contract_start_date: "",
  contract_end_date: "",
};
