import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MultilineInput } from "react-input-multiline";
import styled from "styled-components";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  AddFactoryModalState,
  AddNewTenantModalState,
  GlobalLoadingState,
  ModifyChungGuModalState,
  ModifyTenantModalState,
} from "../../store/ModalStores";
import { alignItemType, CommonType, JustifyType } from "../../constants/Styles";
import { myFont } from "../../constants/Fonts";
import { factoryColor } from "../../constants/Colors";
import { _client } from "../../apis/client";
import closeIcon from "../../asset/close-circle.png";

export type NoticeModalProps = {};

export const ModifyTenantModal = () => {
  const [thisModal, setThisModal] = useRecoilState(ModifyTenantModalState);
  const resetThisModal = useResetRecoilState(ModifyTenantModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(GlobalLoadingState);
  const resetLoadingModal = useResetRecoilState(GlobalLoadingState);

  const [factoryID, setFactoryID] = useState(thisModal.factory_id);
  const [lookupMonth, setLookupMonth] = useState(thisModal.lookup_month);
  const [lookupYear, setLookupYear] = useState(thisModal.lookup_year);
  const [tenantName, setTenantName] = useState(thisModal.tenant_name);
  const [tenantCompanyName, setTenantCompanyName] = useState(
    thisModal.tenant_company_name
  );
  const [tenantPhoneNumber, setTenantPhoneNumber] = useState(
    thisModal.tenant_phone_number
  );

  const [requestedTotalFee, setRequestedTotalFee] = useState(
    thisModal.requested_total_fee
  );

  const [requestedRentalFee, setRequestedRentalFee] = useState(
    thisModal.requested_rental_fee
  );
  const [requestedManageFee, setRequestedManageFee] = useState(
    thisModal.requested_manage_fee
  );

  const [paidTotalFee, setPaidTotalFee] = useState(thisModal.paid_total_fee);

  const [paidRentalFee, setPaidRentalFee] = useState(thisModal.paid_rental_fee);
  const [paidManageFee, setPaidManageFee] = useState(thisModal.paid_manage_fee);

  const [rentalReportUrl, setRentalReportUrl] = useState(
    thisModal.rental_report_url
  );
  const [contractPaperUrl, setContractPaperUrl] = useState(
    thisModal.contract_paper_url
  );

  const [isOverdueTenant, setIsOverdueTenant] = useState(thisModal.is_overdue);
  const [accountInfo, setAccountInfo] = useState(thisModal.account_info);
  const [requestedDate, setRequestedDate] = useState(thisModal.requested_date);
  const [deadlineDate, setDeadlineDate] = useState(thisModal.deadline_date);

  let mainStyle = defatulMainStyle;
  const isPc = useMediaQuery({
    query: "(min-width:1440px)",
  });

  const numberFormat = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const dateFormat = (str: any) => {
    var y = str.substr(0, 4),
      d = str.substr(6, 2),
      m = str.substr(4, 2) - 1;
    // var D = new Date(y, m, d);
    // var d = x % 100;
    // var m = Math.floor((x % 10000) / 100);
    // var y = Math.floor(x / 10000);
    console.log(y + "y/" + (m + 1) + "m/" + d + "d/");
    var D = new Date(y, m, d);
    console.log(D.getFullYear());
    console.log(D.getMonth());
    console.log(D.getDate());
    return D.getFullYear() == y && D.getMonth() == m && D.getDate() == d
      ? D.getFullYear().toString() +
          "년 " +
          (D.getMonth() + 1).toString() +
          "월 " +
          D.getDate() +
          "일"
      : "유효하지 않은 날짜 형식";
  };

  const numberToKorean = (nb: number) => {
    var inputNumber: number = nb < 0 ? 0 : nb;
    var unitWords = ["", "만", "억", "조", "경"];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = "";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    return resultString;
  };

  useEffect(() => {
    setFactoryID(thisModal.factory_id);
    setLookupMonth(thisModal.lookup_month);
    setLookupYear(thisModal.lookup_year);
    setTenantName(thisModal.tenant_name);
    setTenantCompanyName(thisModal.tenant_company_name);
    setTenantPhoneNumber(thisModal.tenant_phone_number);
    setIsOverdueTenant(thisModal.is_overdue);
    setRequestedTotalFee(thisModal.requested_total_fee);
    setRequestedRentalFee(thisModal.requested_rental_fee);
    setRequestedManageFee(thisModal.requested_manage_fee);
    setRentalReportUrl(thisModal.rental_report_url);
    setContractPaperUrl(thisModal.contract_paper_url);
    setAccountInfo(thisModal.account_info);
    setRequestedDate(thisModal.requested_date);
    setDeadlineDate(thisModal.deadline_date);
  }, [thisModal]);

  const convertTotalFee = (totalFee: string) => {
    try {
      var y: number = +totalFee;
      setRequestedTotalFee(y);
    } catch {
      setRequestedTotalFee(0);
    }
  };
  const convertRentalFee = (rentalFee: string) => {
    try {
      var y: number = +rentalFee;
      setRequestedRentalFee(y);
    } catch {
      setRequestedRentalFee(0);
    }
  };
  const convertMgmtFee = (rentalFee: string) => {
    try {
      var y: number = +rentalFee;
      setRequestedManageFee(y);
    } catch {
      setRequestedManageFee(0);
    }
  };

  const sendRequest = () => {
    if (factoryID < 0) {
      alert("관리 공장을 찾을 수 없습니다.");
    } else if (lookupMonth < 0) {
      alert("유효하지 않은 월 입니다.");
    } else if (lookupYear < 0) {
      alert("유효하지 않은 연도 입니다.");
    } else if (tenantName.length < 0) {
      alert("사용자 이름을 1자 이상 입력해주세요.");
    } else if (tenantCompanyName.length < 0) {
      alert("사용자 회사명을 1자 이상 입력해주세요.");
    } else if (tenantPhoneNumber.length < 0) {
      alert("사용자 전화번호를 1자 이상 입력해주세요.");
    } else {
      setLoadingModal({
        isLoading: true,
        message: "임차인 정보 수정중입니다.",
      });
      _client
        .postApiWithToken("/rental_book_for_manager/tenant/modify", {
          factory_id: factoryID,
          lookup_month: lookupMonth,
          lookup_year: lookupYear,
          tenant_name: tenantName,
          tenant_company_name: tenantCompanyName,
          tenant_phone_number: tenantPhoneNumber,
          is_overdue: isOverdueTenant,
          requested_total_fee: requestedTotalFee,
          requested_rental_fee: requestedRentalFee,
          requested_manage_fee: requestedManageFee,
          paid_total_fee: paidTotalFee,
          paid_rental_fee: paidRentalFee,
          paid_manage_fee: paidManageFee,
          rental_report_url: rentalReportUrl,
          contract_paper_url: contractPaperUrl,
          account_info: accountInfo,
          requested_date: requestedDate,
          deadline_date: deadlineDate,
        })
        .then((response) => {
          resetLoadingModal();
          alert(response.msg);
          resetThisModal();
        })
        .catch((err) => {
          resetLoadingModal();
          alert(err);
        });
    }
  };

  const autoFill = () => {
    setPaidTotalFee(requestedTotalFee);
    setPaidRentalFee(requestedRentalFee);
    setPaidManageFee(requestedManageFee);
  };

  const calcMgmtFee = (mgmtFee: string) => {
    try {
      var y: number = +mgmtFee;
      if (requestedTotalFee && requestedRentalFee) {
        let calcResult = requestedTotalFee - requestedRentalFee;
        setRequestedManageFee(calcResult);
      } else {
        setRequestedManageFee(y);
      }
    } catch {
      setRequestedManageFee(0);
    }
  };

  if (thisModal.show)
    return (
      <>
        <div style={backgroudStyle} onClick={resetThisModal} />
        <div style={mainStyle}>
          <button
            style={isPc ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={resetThisModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
                width: isPc ? "40px" : "30px",
                height: isPc ? "40px" : "30px",
              }}
              src={closeIcon}
              alt="close"
            />
          </button>
          <div style={{ flex: "1" }}>
            <OuterDiv>
              <ItemCtnr>
                <div
                  style={{
                    marginTop: "15px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  [임대 장부 수정 ({lookupYear}/{lookupMonth})]
                </div>
                <ItemTitle>
                  {" "}
                  {tenantName} ({tenantPhoneNumber}), {tenantCompanyName}{" "}
                </ItemTitle>
                <ItemTitle>
                  <AgreementCtnr>
                    <CheckBox
                      type="checkbox"
                      checked={isOverdueTenant}
                      onChange={({ target: { checked } }) =>
                        setIsOverdueTenant(checked)
                      }
                    />
                    미납 여부
                  </AgreementCtnr>
                </ItemTitle>
                <ItemTitle>
                  총 청구요금 (입력값: {numberToKorean(requestedTotalFee)} 원,
                  VAT 포함)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={requestedTotalFee}
                    onChange={(e) => convertTotalFee(e.target.value)}
                    disabled={false}
                    placeholder="총 청구요금"
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  임대료 (입력값: {numberToKorean(requestedRentalFee)} 원, VAT
                  포함)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={requestedRentalFee}
                    onChange={(e) => convertRentalFee(e.target.value)}
                    disabled={false}
                    placeholder="사용자 임대료"
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  관리비 (입력값: {numberToKorean(requestedManageFee)} 원, VAT
                  포함)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={requestedManageFee}
                    onChange={(e) => convertMgmtFee(e.target.value)}
                    disabled={false}
                    placeholder="사용자 관리비"
                  />
                  <CalcDiv
                    onClick={() => {
                      calcMgmtFee(Number(requestedManageFee).toString());
                    }}
                  >
                    계산하기
                  </CalcDiv>
                </ItemInputCtnr>
                <ItemTitle>
                  총 납부요금 (입력값: {numberToKorean(paidTotalFee)} 원, VAT
                  포함)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={paidTotalFee}
                    onChange={(e) =>
                      setPaidTotalFee(Number(e.target.value) || 0)
                    }
                    disabled={false}
                    placeholder="총 납부요금"
                  />
                  <CalcDiv
                    onClick={() => {
                      autoFill();
                    }}
                  >
                    완납
                  </CalcDiv>
                </ItemInputCtnr>
                <ItemTitle>
                  납부한 임대료 (입력값: {numberToKorean(paidRentalFee)} 원, VAT
                  포함)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={paidRentalFee}
                    onChange={(e) =>
                      setPaidRentalFee(Number(e.target.value) || 0)
                    }
                    disabled={false}
                    placeholder="납부한 임대료"
                  />
                </ItemInputCtnr>
                <ItemTitle>
                  납부한 관리비 (입력값: {numberToKorean(paidManageFee)} 원, VAT
                  포함)
                </ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={paidManageFee}
                    onChange={(e) =>
                      setPaidManageFee(Number(e.target.value) || 0)
                    }
                    disabled={false}
                    placeholder="납부한 관리비"
                  />
                </ItemInputCtnr>

                <ItemTitle>청구서 URL</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={rentalReportUrl}
                    onChange={(e) => setRentalReportUrl(e.target.value)}
                    disabled={false}
                    placeholder="청구서 URL"
                  />
                </ItemInputCtnr>
                <ItemTitle>계약서 URL</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={contractPaperUrl}
                    onChange={(e) => setContractPaperUrl(e.target.value)}
                    disabled={false}
                    placeholder="계약서 URL"
                  />
                </ItemInputCtnr>
                <ItemTitle>청구 일자 ({dateFormat(requestedDate)})</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={requestedDate}
                    onChange={(e) => setRequestedDate(e.target.value)}
                    disabled={false}
                    placeholder="청구일자"
                  />
                </ItemInputCtnr>
                <ItemTitle>납부기한 ({dateFormat(deadlineDate)})</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={deadlineDate}
                    onChange={(e) => setDeadlineDate(e.target.value)}
                    disabled={false}
                    placeholder="납부기한"
                  />
                </ItemInputCtnr>
                <ItemTitle>입금 계좌</ItemTitle>
                <ItemInputCtnr>
                  <LocalInput
                    type="text"
                    inputMode="text"
                    value={accountInfo}
                    onChange={(e) => setAccountInfo(e.target.value)}
                    disabled={false}
                    placeholder="입금 계좌 정보"
                  />
                </ItemInputCtnr>
              </ItemCtnr>
              <SubmitBtnCtnr>
                <SubmitBtn onClick={() => sendRequest()}>수정하기</SubmitBtn>
              </SubmitBtnCtnr>
            </OuterDiv>
          </div>
        </div>
      </>
    );
  else return null;
};

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "20px",
  width: "340px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0,
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-50px",
  left: "40%",
  width: "50px",
  height: "30px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const OuterDiv = styled.div`
  ${CommonType.displayColumnFlex}
  font-family: ${myFont.regular};
  font-size: 20px;
  font-weight: 500;
  height: 90vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ItemCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
`;

const ItemTitle = styled.div`
  ${CommonType.displayRowFlex}
  align-items: ${alignItemType.fromStart};
  justify-content: ${JustifyType.fromStart};
  width: 100%;
  margin-top: 20px;
`;

const ItemInputCtnr = styled.div`
  ${CommonType.displayRowFlex}
  ${CommonType.alignItemsCenter}
  justify-content: ${JustifyType.fromStart};
  border-radius: 5px;
  border: 1px solid #d7dbe2;
  color: ${factoryColor.descriptionSecondary};
  font-weight: 400;
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
`;

const SubmitBtnCtnr = styled.div`
  ${CommonType.displayColumnFlex}
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  height: 50px;
  padding: 20px;
  margin-bottom: 5px;
`;
const SubmitBtn = styled.button`
  background-color: ${factoryColor.mainGreen};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  background-color: ${factoryColor.mainGreen};
  color: white;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const LocalGreenSpan = styled.span`
  color: ${factoryColor.mainGreen};
  font-weight: 700;
  margin-top: 10px;
`;

const LocalInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

const AgreementCtnr = styled.div`
  display: flex;
  flex-direction: row;
  ${CommonType.alignItemsCenter}
  ${CommonType.justifyContentCenter}
  width: auto;
  height: 35px;
  font-size: 14px;
  font-weight: 500;
`;
export const CheckBox = styled.input`
  display: flex;
  width: 25px;
  margin-right: 10px;
  outline: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  color: gray;
`;

const CalcDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 30%;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;
