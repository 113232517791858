import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { userJoin } from "./registerScreens/StartRegister";
import completeImg from "../asset/coplete.png";
import { factoryColor } from "../constants/Colors";
import { useRecoilState } from "recoil";
import { LoginModalState } from "../store/ModalStores";
import * as DOMPurify from "dompurify";

export const ConsultingRequestSuccessPage = () => {
  let navigate = useNavigate();
  const [loginModal, setLoginModal] = useRecoilState(LoginModalState);

  const goMainPage = () => {
    // To to main and Login
    navigate("/");
    setLoginModal({
      show: true,
      onPress: () => {},
    });

    // try {
    //   open_login_modal();
    // } catch (e) {
    //   console.error(`로그인 모달 열기 실패, 원인: ${e}`);
    // }
  };
  const naverAdCommonHTML1 = `<script type="text/javascript" src="//wcs.naver.net/wcslog.js"> </script>`;
  const naverAdCommonHTML2 = `<script type="text/javascript">if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_2742ead514dd";if (!_nasa) var _nasa={};if(window.wcs){wcs.inflow();wcs_do(_nasa);}</script>`;
  const naverAdCommonBody = `if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_2742ead514dd";if (!_nasa) var _nasa={};if(window.wcs){wcs.inflow();wcs_do(_nasa);}`;
  const naverAdConvertHTML1 = `<script type="text/javascript" src="//wcs.naver.net/wcslog.js"></script>`;
  const naverAdConvertHTML2 =
    '<script type="text/javascript">if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_2742ead514dd";var _nasa={};if (window.wcs) {_nasa["cnv"] = wcs.cnv("5","0");wcs_do(_nasa);}</script>';
  const naverAdConvertHTML2_v2 = `<script type="text/javascript" src="//wcs.naver.net/wcslog.js"></script><script type="text/javascript"> var _nasa={}; if(window.wcs) _nasa["cnv"] = wcs.cnv("5","0"); </script>`;
  const naverAdConvertHTMLBody = `if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_2742ead514dd";var _nasa={};if (window.wcs) {_nasa["cnv"] = wcs.cnv("5","0");wcs_do(_nasa);}`;

  //   if (!wcs_add) var wcs_add={};
  // wcs_add["wa"] = "s_2742ead514dd";
  // var _nasa={};
  // if (window.wcs) {
  // _nasa["cnv"] = wcs.cnv("5","0");
  // wcs_do(_nasa);
  // }
  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = "//wcs.naver.net/wcslog.js";
    // script.async = true;
    // document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.textContent = naverAdConvertHTMLBody;
    script2.async = true;
    document.body.appendChild(script2);

    // const script3 = document.createElement("script");
    // script3.textContent = naverAdCommonBody;
    // script3.async = true;
    // document.body.appendChild(script3);


    return () => {
      // document.body.removeChild(script);
      document.body.removeChild(script2);
    };
  }, []);

  // const receivedHtml = `<script>if (!wcs_add) var wcs_add={};
  // wcs_add["wa"] = "s_2742ead514dd";
  // var _nasa={};
  // if (window.wcs) {
  // _nasa["cnv"] = wcs.cnv("5","0");
  // wcs_do(_nasa);
  // }</script>`;

  // const htmlDecode = (content: any): string => {
  //   let e = document.createElement("div");
  //   e.innerHTML = content;
  //   if (e.childNodes.length !== 0) {
  //     console.log(e.childNodes);
  //     if (e.childNodes[0].nodeValue === null) {
  //       return e.childNodes;
  //     } else {
  //       return e.childNodes[0].nodeValue;
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  // useEffect(() => {
  //   if (externalElement !== undefined) {
  //     if (externalElement.current !== undefined) {
  //         externalElement.current.innerHTML = "Your html and js will be executed"
  //   }
  //   }
  // }, [])

  return (
    <RegisterBackground>
      {/* <div
        className="NaverAdConvertHtml1"
        dangerouslySetInnerHTML={{
          // __html: htmlDecode(naverAdConvertHTML1),
          // __html: naverAdConvertHTML1
          __html: DOMPurify.sanitize(naverAdConvertHTML1, {
            FORCE_BODY: true,
            ADD_TAGS: ["script"],
          }),
        }}
      ></div> */}
      {/* <div
        className="NaverAdConvertHtml2"
        dangerouslySetInnerHTML={{
          // __html: htmlDecode(naverAdConvertHTML2),
          __html: DOMPurify.sanitize(naverAdConvertHTML2_v2, {
            // __html: DOMPurify.sanitize(naverAdConvertHTML2, {
            FORCE_BODY: true,
            ADD_TAGS: ["script"],
          }),
          // __html: naverAdConvertHTML2
        }}
      /> */}
      {/* <div
        className="ReceicedScript"
        dangerouslySetInnerHTML={{
          // __html: htmlDecode(naverAdConvertHTML2),
          __html: DOMPurify.sanitize(receivedHtml, {
            FORCE_BODY: true,
            ADD_TAGS: ["script"],
          }),
          // __html: naverAdConvertHTML2
        }}
      /> */}
      <RegisterInner>
        <CompleteItem>
          <CompleteIcon src={completeImg} />
          <TitleDiv>문의 접수가 완료되었습니다!</TitleDiv>
        </CompleteItem>
        <CompleteBtnContainer>
          <CompleteBtn onClick={() => goMainPage()}>
            메인 페이지로 이동
          </CompleteBtn>
        </CompleteBtnContainer>
      </RegisterInner>
      {/* <div
        className="NaverCommonHtml1"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(naverAdCommonHTML1, {
            FORCE_BODY: true,
            ADD_TAGS: ["script"],
          }),
          // __html: naverAdCommonHTML1,
        }}
      />
      <div
        className="NaverCommonHtml2"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(naverAdCommonHTML2, {
            FORCE_BODY: true,
            ADD_TAGS: ["script"],
          }),
        }}
      /> */}
    </RegisterBackground>
  );
};

export const RegisterBackground = styled.div`
  position: relative;

  padding: 0;
  margin: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  top: 4px;

  z-index: 100;
  display: flex;
  font-family: NotoSansKR;
  justify-content: center;
  box-sizing: border-box;
  background-color: #eee;
  @media (max-width: 500px) {
    top: 2px;
  }

  @media (max-height: 800px) {
    height: 900px;
  }
`;

export const RegisterInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 195px;
  width: 502px;
  height: 170px;
  border-radius: 14px;
  align-items: center;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;

  border-radius: 14px;
  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;

  @media (max-width: 700px) {
    margin-top: 100px;
    width: 60%;
    height: 150px;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  font-family: NotoSansKR;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.84px;
  text-align: center;
  color: #48484a;
`;

interface prop {
  active?: boolean;
}

const ProgressItem = styled.div<prop>`
  display: flex;
  flex-direction: column;
  width: 190px;
  align-items: center;
  justify-content: center;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  text-align: center;
  color: #48484a;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const CompleteItem = styled.div<prop>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  color: #48484a;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const CompleteIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 15%;
`;

const CompleteBtnContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 37px;
  width: 100%;
  border-radius: 5px;
  border: solid 3px ${factoryColor.mainGreen};
  background-color: ${factoryColor.mainGreen};
  text-align: center;
  align-items: center;
  justify-content: center;
  :hover {
    border: 3px solid rgba(0, 162, 51, 0.8);
    opacity: 0.8;
  }

  margin-top: 0%;

  @media (max-width: 700px) {
    margin-top: 0%;
  }

  @media (max-width: 500px) {
    margin-top: 14px;
  }
`;

const CompleteBtn = styled.button`
  width: 100%;
  height: 25px;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;
